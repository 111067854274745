var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"mb-1"},[_c('div',{staticClass:"element-title"},[_vm._v(" "+_vm._s(_vm.$t(_vm.title))+" ")]),(_vm.description)?_c('div',{staticClass:"element-description"},[_vm._v(" "+_vm._s(_vm.$t(_vm.description))+" ")]):_vm._e()]),_c('div',{class:[
      'd-flex align-items-center mt-1  gap-2',
      _vm.isEmpty ? '' : 'align-items-end',
      _vm.layout === 'vertical' && _vm.isEmpty ? 'flex-column' : 'flex-row',
    ]},[_c('div',{class:[
        'img-placeholder-wrapper',
        { 'img-placeholder-wrapper-empty': _vm.isEmpty },
      ],on:{"click":function($event){_vm.modals.img.display = true},"mouseover":function($event){_vm.hover = true},"mouseleave":function($event){_vm.hover = false}}},[_c('el-tooltip',{attrs:{"content":_vm.$t('public-knowledge.appearances.items.brand.tooltip-download'),"disabled":_vm.isEmpty}},[_c('img',{staticClass:"img-placeholder",attrs:{"src":_vm.value}})])],1),_c('div',{class:[
        'd-flex flex-column justify-content-between',
        { 'download-group': _vm.isEmpty },
      ]},[(_vm.isEmpty)?_c('base-button',{attrs:{"text":_vm.$t('public-knowledge.appearances.items.brand.button-download'),"variant":"primary","size":"sm"},on:{"click":function($event){_vm.modals.img.display = true}}}):_c('el-popconfirm',{attrs:{"confirm-button-text":_vm.$t('public-knowledge.appearances.items.brand.confirm-delete-true'),"cancel-button-text":_vm.$t('public-knowledge.appearances.items.brand.confirm-delete-false'),"icon":"el-icon-info","icon-color":"red","title":_vm.$t('public-knowledge.appearances.items.brand.confirm-delete')},on:{"confirm":function($event){return _vm.$emit('delete')}}},[_c('el-tooltip',{attrs:{"slot":"reference","content":_vm.$t('public-knowledge.appearances.items.brand.tooltip-delete')},slot:"reference"},[_c('div',[_c('div',{staticClass:"delete-icon-wrapper"},[_c('font-awesome-icon',{staticClass:"delete-icon",attrs:{"icon":['fas', 'trash']}})],1)])])],1),(_vm.isEmpty && !_vm.hideDescription)?_c('div',{staticClass:"img-input-advise"},[_vm._v(" "+_vm._s(_vm.$t('public-knowledge.appearances.items.brand.download-advice', { width: _vm.width, height: _vm.height, }))+" ")]):_vm._e()],1),(_vm.modals.img.display)?_c('IconEditionModal',{attrs:{"title":_vm.assetTitle,"description":_vm.assetDescription,"display":_vm.modals.img.display,"editionOptions":_vm.modals.img.editionOptions,"aspect-ratio":_vm.modals.img.aspectRatio},on:{"close":function($event){_vm.modals.img.display = false},"choice":_vm.newValue}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }