<template>
  <div class="attribute-table">
    <div class="table-responsive">
      <base-table thead-classes="thead-light" :data="companyAttributes">
        <template slot="columns">
          <div class="row no-margin">
            <th class="col-3 text-center">MODEL</th>
            <th class="col-6 text-center">Nom</th>
            <th class="col-3 text-center px-0">Actions</th>
          </div>
        </template>

        <template slot-scope="{ row }">
          <div class="row no-margin hoverable data-row">
            <td
              class="col-3 my-auto text-center attribute-name m-0 px-0"
              id="center-element"
            >
              <badge rounded type="primary">{{ row.model }}</badge>
            </td>
            <td
              class="col-6 my-auto text-center attribute-name m-0 px-0 label"
              id="center-element"
            >
              {{ row.label }}
            </td>
            <td class="col-3 my-auto text-center" id="center-element">
              <el-popover
                title="Liste d'actions"
                placement="right"
                class="my-auto"
              >
                <div>
                  <div
                    class="dropdown-item px-0"
                    style="display: inline-block"
                    @click="removeAttribute(row.id)"
                  >
                    <h5 class="text-overflow m-0">
                      <img
                        class="ml-1 mr-2"
                        src="~assets/delete_red.png"
                        width="20px"
                      />
                      <span>Supprimer l'attribut</span>
                    </h5>
                  </div>
                </div>
                <div
                  class="col-12 side-option"
                  slot="reference"
                  id="add-group-popover"
                >
                  <el-tooltip
                    content="Actions sur cet attribut"
                    placement="right"
                    class="group-tooltip"
                  >
                    <img
                      class="options_selection m-0"
                      src="~assets/options_selection.png"
                      width="20px"
                    />
                  </el-tooltip>
                </div>
              </el-popover>
            </td>
          </div>
        </template>
      </base-table>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';

export default {
  name: 'attributes-table',
  computed: {
    ...mapState(['companyAttributes']),
  },
  methods: {
    removeAttribute(id) {
      this.$emit('remove-attribute', { id });
    },
  },
};
</script>
<style scoped>
.no-margin {
  margin-right: 0px;
  margin-left: 0px;
}

.attribute-name {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.attribute-table {
  max-height: calc(100vh - 250px);
  overflow: auto;
}

.label {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
