<template>
  <div class="flex items-center justify-content-end">
    <BaseDropdownOptionsWrapper
      :options="options"
      :is-horizontal="true"
      @select="handleDropdownSelect"
    ></BaseDropdownOptionsWrapper>
  </div>
</template>
<script>
export default {
  name: 'user-groups-actions',
  props: {
    row: Object,
  },
  data() {
    return {
      localDisplay: false,
    };
  },
  computed: {
    canDelete() {
      return !this.row.usersCount && !this.row.hasPendingUsers;
    },
    options() {
      return [
        {
          key: 1,
          label: this.$t('settings.permissions.user-groups-actions.edit'),
          icon: ['fal', 'pen'],
        },
        {
          key: 2,
          label: this.$t('settings.permissions.user-groups-actions.delete'),
          icon: ['fal', 'trash'],
          type: 'danger',
          disabled: !this.canDelete,
          divider: true,
        },
      ];
    },
  },
  methods: {
    handleDropdownSelect(e) {
      const optionId = e.detail[0];
      if (!optionId) return;
      switch (optionId) {
        case 1:
          return this.updateUserGroup();
        case 2:
          return this.triggerDeleteModal();
      }
    },
    updateUserGroup() {
      this.localDisplay = false;
      this.$emit('update-user-group', { group: this.row });
    },
    triggerDeleteModal() {
      this.localDisplay = false;
      this.$emit('trigger-delete-modal', this.row.id);
    },
  },
};
</script>
<style lang="scss" scoped></style>
