<template>
  <div class="d-flex">
    <div class="color-picker">
      <div class="p-2 d-inline-flex items-center">
        <div class="pr-2" v-for="color in colors" :key="color.value">
          <div
            class="m-0 rounded-circle"
            :class="color.value === focusedColor ? 'focused' : 'unfocused'"
          >
            <el-tooltip
              :content="color.name"
              placement="bottom"
              class="group-tooltip"
            >
              <div
                class="color rounded-circle"
                :style="{ backgroundColor: color.value }"
                @click.prevent="$emit('focus-color', color.value)"
              ></div>
            </el-tooltip>
          </div>
        </div>
        <div
          class="m-0 rounded-circle"
          :class="!focusedColor ? 'focused' : 'unfocused'"
        >
          <el-tooltip
            content="Réinitialiser la couleur par défaut"
            placement="bottom"
            class="group-tooltip"
          >
            <div
              class="no-color rounded-circle"
              @click.prevent="$emit('focus-color', null)"
            >
              <div class="no-color__bar"></div>
            </div>
          </el-tooltip>
        </div>
        <ColorPicker
          size="small"
          class="pl-2"
          v-model="focusedColor"
          @change="$emit('focus-color', focusedColor)"
        ></ColorPicker>
      </div>
    </div>
  </div>
</template>

<script>
import { ColorPicker } from 'element-ui';

export default {
  name: 'color-picker',
  components: {
    ColorPicker,
  },
  props: {
    focusedColor: {
      type: String,
      default: '#d5dae3',
    },
  },
  data() {
    return {
      colors: [
        {
          value: '#F8286C',
          name: 'Rose Paradis',
        },
        {
          value: '#F53529',
          name: 'Magenta',
        },
        {
          value: '#FE7E57',
          name: 'Corail',
        },
        {
          value: '#FEBB25',
          name: 'Jaune solaire',
        },
        {
          value: '#1DCFCE',
          name: "Vert d'eau",
        },
        {
          value: '#1DA0D7',
          name: 'Bleu Caroline',
        },
        {
          value: '#195F94',
          name: 'Lapis Lazuli',
        },
        {
          value: '#8700F8',
          name: 'Violet Byzantin',
        },
        {
          value: '#D63DA6',
          name: 'Violet Mulberry',
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.color-picker {
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 2px;
}
.color {
  width: 14px;
  height: 14px;
  cursor: pointer;
}
.focused {
  padding: 2px;
  border: 0.5px solid #8cc1b3;
}
.unfocused {
  padding: 2px;
  border: 1px solid transparent;
}
.no-color {
  width: 14px;
  height: 14px;
  cursor: pointer;
  background-color: #f2f5f9;
  border: 0.5px solid #7d89a0;
  position: relative;
  &__bar {
    position: absolute;
    right: 0px;
    bottom: 4.5px;
    width: 12px;
    border: 0.1px solid #7d89a0;
    transform: rotate(48.01deg);
  }
}
</style>
