var render = function render(){var _vm=this,_c=_vm._self._c;return _c('modal',{staticClass:"import-article-modal",attrs:{"body-classes":"p-0 m-0","show":_vm.importArticle,"closeIcon":true},on:{"update:show":function($event){_vm.importArticle=$event}}},[_c('div',{staticClass:"row sticky-top modal-search-header m-0"},[_c('el-select',{staticClass:"col-4 change-plateform pr-0",attrs:{"nullaceholder":"Select"},on:{"change":function($event){return _vm.modifyPlateform()}},model:{value:(_vm.selectedPlateform),callback:function ($$v) {_vm.selectedPlateform=$$v},expression:"selectedPlateform"}},[_c('template',{slot:"prefix"},[(_vm.selectedPlateform === 'Notion')?_c('img',{staticClass:"prefix selected-logo",attrs:{"src":require("assets/notion-round.svg")}}):_vm._e(),(_vm.selectedPlateform === 'Zendesk')?_c('img',{staticClass:"prefix selected-logo",attrs:{"src":require("assets/zendesk-round.svg")}}):_vm._e()]),_vm._l((_vm.getImportIntegrations),function(name,idx){return _c('el-option',{key:idx,attrs:{"label":name,"value":name}},[(name === 'Notion')?_c('img',{staticClass:"mr-2 imported-logo",attrs:{"src":require("assets/notion-round.svg")}}):_vm._e(),(name === 'Zendesk')?_c('img',{staticClass:"mr-2 imported-logo",attrs:{"src":require("assets/zendesk-round.svg")}}):_vm._e(),_vm._v(" "+_vm._s(_vm.integrations[name].label)+" ")])})],2),_c('el-input',{staticClass:"col px-0 search-article-input",attrs:{"placeholder":_vm.$t(
          'knowledge.article-builder.side-bar.imported-articles.modal.search',
        )},on:{"input":function($event){return _vm.onInput()}},model:{value:(_vm.toSearch),callback:function ($$v) {_vm.toSearch=$$v},expression:"toSearch"}},[_c('i',{staticClass:"el-input__icon el-icon-close",attrs:{"slot":"suffix"},on:{"click":function($event){_vm.importArticle = false}},slot:"suffix"}),_c('i',{staticClass:"el-input__icon el-icon-search",attrs:{"slot":"prefix"},slot:"prefix"})])],1),(_vm.selectedPlateform === 'Notion')?_c('div',{staticClass:"d-flex"},[_c('el-input',{staticClass:"px-0 d-flex justify-content-center",attrs:{"placeholder":_vm.$t(
          'knowledge.article-builder.side-bar.imported-articles.modal.via-url',
        )},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.importArticleFromUrl.apply(null, arguments)}},model:{value:(_vm.articleUrl),callback:function ($$v) {_vm.articleUrl=$$v},expression:"articleUrl"}}),_c('base-button-old',{staticClass:"import-url",attrs:{"outline":"","size":"sm","type":"primary"},on:{"click":_vm.importArticleFromUrl}},[_vm._v(_vm._s(_vm.$t( 'knowledge.article-builder.side-bar.imported-articles.modal.url-button', )))])],1):_vm._e(),(this.selectedPlateformArticles.articles.length !== 0)?_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loadingArticle),expression:"loadingArticle"}]},[_vm._l((_vm.selectedPlateformArticles.articles),function(article,idx){return _c('div',{key:idx,attrs:{"label":article.title,"value":article.id}},[_c('div',{staticClass:"container row m-0 p-0"},[_c('div',{staticClass:"my-card col p-0"},[_c('div',{staticClass:"card card-body",staticStyle:{"padding":"16px !important"}},[_c('div',{staticClass:"container row p-0 mx-auto"},[_c('img',{staticClass:"col-2 img-fluid",staticStyle:{"max-width":"10%"},attrs:{"src":require("assets/article-import-color.svg")}}),_c('small',{staticClass:"col-9 text-left ml-0 my-auto ellipsis-text",class:{ 'draft-article': article.draft }},[_vm._v(_vm._s(article.title))]),(article.draft)?_c('el-tooltip',{staticClass:"group-tooltip pr-0",attrs:{"content":_vm.$t(
                    'knowledge.article-builder.side-bar.imported-articles.modal.warning-tooltip',
                  ),"placement":"right"}},[_c('font-awesome-icon',{staticClass:"import-article-icon my-auto col",attrs:{"icon":['fad', 'exclamation-triangle'],"size":"sm"}})],1):_c('el-tooltip',{staticClass:"group-tooltip pr-0",attrs:{"content":_vm.$t(
                    'knowledge.article-builder.side-bar.imported-articles.modal.download-tooltip',
                  ),"placement":"right"}},[_c('font-awesome-icon',{staticClass:"import-article-icon my-auto col",attrs:{"icon":['fad', 'download'],"size":"sm"},on:{"click":function($event){return _vm.importArticleMayday(article.id)}}})],1)],1)])])])])}),_c('div',{staticClass:"text-center pt-2 pb-2"},[_c('el-pagination',{attrs:{"background":"","layout":"prev, pager, next","page-size":10,"total":_vm.countArticle},on:{"current-change":_vm.selectNewPage}})],1)],2):_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loadingArticle),expression:"loadingArticle"}],staticClass:"container row"},[_c('p',{staticClass:"col mx-auto my-auto text-center pt-3 pb-3"},[_c('small',[_vm._v(_vm._s(_vm.$t( 'knowledge.article-builder.side-bar.imported-articles.modal.not-found', )))])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }