<template>
  <div class="flex w-full justify-content-center align-items-center">
    <BaseDropdownOptionsWrapper
      :options="options"
      :is-horizontal="true"
      @select="handleDropdownSelect"
    ></BaseDropdownOptionsWrapper>
  </div>
</template>

<script>
export default {
  name: 'role-table-row-options',
  props: {
    canDelete: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    handleDropdownSelect(e) {
      const optionId = e.detail[0];
      if (!optionId) return;
      switch (optionId) {
        case 1:
          return this.$emit('edit-role');
        case 2:
          return this.$emit('delete-role');
      }
    },
  },
  computed: {
    options() {
      return [
        {
          key: 1,
          label: this.$t('settings.permissions.role-options.edit'),
          icon: ['fal', 'pen'],
        },
        {
          key: 2,
          label: this.$t('settings.permissions.role-options.delete'),
          icon: ['fal', 'trash'],
          disabled: !this.canDelete,
          type: 'danger',
          divider: true,
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped></style>
