<template>
  <div
    class="diag-next-steps-edit-options d-flex align-items-center justify-items-end"
    :class="{ visible: display.redirection }"
  >
    <font-awesome-icon
      :icon="['fad', 'pen']"
      class="modify-step ml-2"
      @click="$emit('edit-label')"
    />
    <el-popover
      v-if="options.relocate"
      placement="top"
      popper-class="custom-popover"
      width="400"
      height="400"
      trigger="click"
      v-model="stepMoverPopoverOpen"
    >
      <el-tooltip
        slot="reference"
        :content="$t('knowledge.actions.move-to-inner-diag')"
        placement="top"
        class="group-tooltip"
      >
        <font-awesome-icon
          :icon="['fad', 'location-circle']"
          class="modify-step ml-2"
        />
      </el-tooltip>
      <div>
        <PopoverStepMover
          v-if="stepMoverPopoverOpen"
          :rootId="rootId"
          :forbidden-ids="getForbiddenIds(child.id)"
          :parent="parent"
          @move-to-diag="$emit('relocate-child', $event)"
          @close="stepMoverPopoverOpen = false"
        />
      </div>
    </el-popover>
    <el-popover
      v-if="canBeRedirection && options.redirection"
      placement="top"
      popper-class="custom-popover"
      width="400"
      height="400"
      trigger="click"
      v-model="redirectPopoverOpen"
    >
      <el-tooltip
        slot="reference"
        :content="$t('knowledge.child.redirect.popover.tooltip')"
        placement="top"
        class="group-tooltip"
      >
        <font-awesome-icon
          :icon="['fad', 'external-link-square']"
          class="modify-step ml-2"
        />
      </el-tooltip>
      <PopoverRedirect
        v-if="redirectPopoverOpen"
        :rootId="rootId"
        :forbidden-ids="getForbiddenIds(child.id)"
        :parent="parent"
        :child="child"
        @close="redirectPopoverOpen = false"
        @update-redirection="handleRedirection"
      ></PopoverRedirect>
    </el-popover>
    <font-awesome-icon
      v-if="options.delete"
      :icon="['fad', 'trash']"
      class="modify-step delete ml-2"
      @click="deleteChild"
    />
    <ModalEvents
      modal-name="DeleteContentModal"
      :uid="deleteModalUniqueKey"
      @choice="deleteChildren"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import PopoverStepMover from 'components/StepMover/PopoverStepMover';
import PopoverRedirect from 'components/Redirect/PopoverRedirect';
import ModalEvents from '@/components/Modals/ModalEvents';

export default {
  name: 'child-button-options',
  components: {
    PopoverStepMover,
    PopoverRedirect,
    ModalEvents,
  },
  props: {
    child: Object,
    rootId: String,
    parent: {
      type: Object,
      default: () => {},
    },
    options: {
      type: Object,
      default: () => ({
        redirection: true,
        relocate: true,
        delete: true,
      }),
    },
  },
  data() {
    return {
      display: {
        popover: false,
        redirection: false,
      },
      stepMoverPopoverOpen: false,
      redirectPopoverOpen: false,
      diagnostic: null,
      root: {},
      descendents: [],
    };
  },
  computed: {
    canBeRedirection() {
      return this.hasEmptyBody && (!this.child.path || !this.child.path.length);
    },
    hasEmptyBody() {
      return (
        !this.child.body ||
        this.child.body === '<p></p>' ||
        this.child.body.match(/^<p data-uid="[^"]+"><\/p>$/)
      );
    },
    deleteModalUniqueKey() {
      return `${this.$options.name}-${this.child.id}`;
    },
    ...mapGetters('knowledgeModule', ['focusKnowledgeId']),
  },
  methods: {
    getForbiddenIds(childId) {
      const redirectionSteps = this.descendents
        .filter((child) => child.redirection)
        .map((child) => child.id);
      return {
        childId,
        redirection: { ...redirectionSteps },
        parent: this.parent.id,
      };
    },
    nodesTransform(node) {
      const { path, children } = node;
      if (children) {
        node.children = path.map((sortedId) =>
          children.find(({ id }) => id === sortedId),
        );
        node.children.forEach((child) => {
          this.nodesTransform(child);
        });
      }
    },
    deleteChildren(choice) {
      if (choice) this.$emit('delete-content', this.child.id);
    },
    handleRedirection(e) {
      this.display.redirection = false;
      this.$emit('update-content', e);
    },
    async deleteChild() {
      const contentId = this.child.id;
      const relatedCount = await this.countRelatedContentReferences({
        contentId,
        knowledgeId: this.focusKnowledgeId,
      });
      const backlinks = await this.getContentBacklinks(contentId);
      const payload = {
        component: 'DeleteContentModal',
        uid: this.deleteModalUniqueKey,
        props: { display: true, id: contentId, backlinks, relatedCount },
      };
      this.openModal(payload);
    },
    ...mapActions('modalsModule', ['openModal']),
    ...mapActions('knowledgeModule', [
      'countRelatedContentReferences',
      'getContentBacklinks',
    ]),
  },
};
</script>

<style lang="scss" scoped>
.modify-step {
  max-width: 20px;
  cursor: pointer;
  padding: 2px;
  color: $grey-5-mayday;
  &:hover {
    color: $blue-mayday;
  }
}

.delete {
  &:hover {
    color: $red-mayday;
  }
}

.modify-step:hover {
  border-radius: 3px;
  background-color: #f2f5f9;
}

.visible {
  display: flex;
  visibility: visible;
}
</style>
