<template>
  <modal :show.sync="importArticle" :closeIcon="true">
    <!-- <h4 slot="header" class="modal-title" id="modal-title-default"></h4> -->
    <img src="~assets/modal-upload-header.svg" class="modal-upload-header" />
    <div class="d-flex flex-column upload-zone">
      <div class="col-8 mx-auto text-center mb-4">
        <h2>
          {{
            $t(
              'knowledge.article-builder.side-bar.imported-docx.modal.modal-title',
            )
          }}
        </h2>
        <small class="text-muted"
          >{{
            $t(
              'knowledge.article-builder.side-bar.imported-docx.modal.modal-subtitle',
            )
          }}
        </small>
      </div>
      <custom-drop-zone
        class="col-8 mx-auto"
        v-model="files"
        :options="options"
        multiple
        v-loading="isLoading"
      ></custom-drop-zone>
    </div>
    <div slot="footer" class="col-12 d-flex justify-content-end">
      <base-button-old
        class="ml-2"
        type="secondary"
        size="sm"
        @click="$emit('close')"
        >{{
          $t(
            'knowledge.article-builder.side-bar.imported-docx.modal.button-cancel',
          )
        }}</base-button-old
      >
      <base-button-old
        v-if="files.length !== 0"
        class="ml-2"
        type="success"
        size="sm"
        @click="triggerImport()"
      >
        {{
          $t(
            'knowledge.article-builder.side-bar.imported-docx.modal.button-download',
          )
        }}
      </base-button-old>
      <base-button-old v-else class="ml-2" type="success" size="sm" disabled>
        {{
          $t(
            'knowledge.article-builder.side-bar.imported-docx.modal.button-download',
          )
        }}
      </base-button-old>
    </div>
  </modal>
</template>

<script>
import CustomDropZone from '../CustomDropZone';

export default {
  name: 'import-files',
  components: {
    CustomDropZone,
  },
  props: {
    display: Boolean,
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    triggerImport() {
      this.$emit('importFiles', this.files);
    },
  },
  data() {
    return {
      files: [],
      importArticle: this.display,
      options: {
        acceptedFiles:
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        autoProcessQueue: false,
      },
    };
  },
  watch: {
    display() {
      this.importArticle = this.display;
    },
    importArticle() {
      if (!this.importArticle) {
        this.files = [];
        this.$emit('close');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.upload-zone {
  max-height: 60vh;
  overflow: auto;
}

.test:hover {
  background-color: $blue-mayday;
}

.ellipsis-text {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.import-article-icon {
  color: #b8bfcd;
  max-width: 12%;
  cursor: pointer;
}

.import-article-icon:hover {
  color: $blue-mayday;
  font-size: 110%;
}

.import-article-modal {
  :deep() .modal-body {
    max-height: 600px;
    overflow: auto;
  }
}

.search-article-input {
  :deep() input {
    border: 0px;
    border-left: 1px solid #b8bfcd !important;
    border-radius: 0px 5px 0px 0px;
    height: 43px;
  }
}

.change-plateform {
  :deep() input {
    border: 0px;
    border-radius: 5px 5px 0px 0px;
  }
}

.change-plateform {
  :deep() .prefix {
    margin-top: 12px;
    margin-left: 3px;
  }
}

.change-plateform {
  :deep() .el-input__inner {
    margin-left: 5px;
  }
}

.change-plateform {
  :deep() input:focus {
    border: 0px !important;
    border-radius: 5px 5px 0px 0px;
  }
}

.modal-search-header {
  border-bottom: 1px solid #b8bfcd;
}

.search-article-input {
  :deep() .el-icon-close {
    cursor: pointer !important;
  }
}

.search-article-input {
  :deep() .el-icon-search {
    cursor: pointer !important;
  }
}

.my-card {
  :deep() .card-body {
    padding: 10px;
    border-radius: 0px !important;
  }
}

.modal-upload-header {
  max-width: 100%;
}
</style>
