import { render, staticRenderFns } from "./IconEditionItem.vue?vue&type=template&id=4e18afe3&scoped=true&"
import script from "./IconEditionItem.vue?vue&type=script&lang=js&"
export * from "./IconEditionItem.vue?vue&type=script&lang=js&"
import style0 from "./IconEditionItem.vue?vue&type=style&index=0&id=4e18afe3&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e18afe3",
  null
  
)

export default component.exports