<template>
  <div>
    <card class="col" type="frame">
      <div class="row">
        <div class="col-11">
          <div class="d-flex align-items-center mb-3">
            <img src="~assets/automations.svg" width="20px" class="mr-2" />
            <h4 class="text-muted text-upercase mb-0 mr-2">{{ alias }}</h4>
          </div>
          <div class="d-flex mb-2">
            <img
              src="~assets/diag_icon.svg"
              width="20px"
              class="mr-2 diag-icon"
            />
            <div class="diag-element">
              <p class="mb-0">{{ nodeName }}</p>
              <p class="path mb-0">
                <small>{{ path }}</small>
              </p>
            </div>
          </div>
          <div class="rule-to-string">
            <p class="mb-0 ml-1" v-html="sanitize(ruleToString)"></p>
          </div>
        </div>
        <div class="col-1 m-auto">
          <base-dropdown class="nav-item" position="right">
            <a
              slot="title"
              class="nav-link nav-link-icon"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <img src="~assets/options_selection.png" width="20px" class />
            </a>
            <a class="dropdown-item cursor" @click="modals.rules = true"
              >Modifier</a
            >
            <div class="dropdown-divider"></div>
            <a class="dropdown-item cursor" @click="modals.delete = true"
              >Supprimer</a
            >
          </base-dropdown>
        </div>
      </div>
    </card>
    <rule-edition-modal
      v-if="modals.rules"
      :display="modals.rules"
      :group="parsedRule"
      :update="true"
      @close="modals.rules = false"
      @choice="updateRuleGroup"
    />
    <delete-modal
      v-if="modals.delete"
      type="rules"
      :display="modals.delete"
      @choice="deleteRuleGroup"
      @close="modals.delete = false"
    />
  </div>
</template>

<script>
import { sanitize } from '@/plugins/dompurify';
import { mapState } from 'vuex';
import RuleEditionModal from 'components/Modals/RuleEditionModal';
import DeleteModal from 'components/Modals/DeleteModal';

export default {
  name: 'rule-card',
  components: {
    RuleEditionModal,
    DeleteModal,
  },
  props: {
    rule: Object,
  },
  data() {
    return {
      operatorToString: {
        is: 'Est égal à',
        is_not: 'Est différent de',
        less_than: 'Est inférieur à',
        greater_than: 'Est supérieur à',
        includes: 'Contient',
        not_includes: 'Ne contient pas',
      },
      modals: {
        rules: false,
        delete: false,
      },
      sanitize: sanitize,
    };
  },
  computed: {
    alias() {
      return this.rule.alias;
    },
    nodeName() {
      return this.rule.node.content.label;
    },
    nodeAncestors() {
      return this.rule.ancestors;
    },
    path() {
      return this.nodeAncestors.map(({ content }) => content.label).join(' > ');
    },
    parsedRule() {
      return {
        alias: this.alias,
        rules: {
          type: this.rule.type,
          conditions: this.rule.ruleAttributes.map((elem) => ({
            id: elem.id === undefined ? null : String(elem.id),
            companyAttributeId: String(elem.companyAttribute.id),
            companyAttributeValueId:
              elem.companyAttributeValue === null
                ? ''
                : String(elem.companyAttributeValue.id),
            value: elem.value === null ? [] : elem.value,
            operator: elem.operator,
          })),
        },
      };
    },
    ruleToString() {
      const connector =
        this.rule.type === 'ALL'
          ? ' <span class="connector">ET</span> '
          : ' <span class="connector">OU</span> ';
      const conditions = this.rule.ruleAttributes.map(
        ({ operator, companyAttribute, companyAttributeValue, value }) => {
          if (value === null)
            return `<span class="variable">${
              companyAttribute.label
            }</span> <span class="rule">${
              this.operatorToString[operator]
            }</span> <span class="value">"${
              companyAttributeValue.valueName === null
                ? companyAttributeValue.value
                : companyAttributeValue.valueName
            }"</span>`;
          return `<span class="variable">${companyAttribute.label}</span> <span class="rule">${this.operatorToString[operator]}</span> <span class="value">"${value}"</span>`;
        },
      );
      return `Si ${conditions.join(
        connector,
      )} alors créer un <span class="final">raccourcis vers ${
        this.nodeName
      }</span>`;
    },
    conditionsName() {
      return this.rule.conditions.map(({ field }) => {
        const attribute = this.companyAttributes.find((o) => o.field === field);
        if (attribute) {
          const { label } = attribute;
          if (label) return { label, field };
        }
        return { label: field, field };
      });
    },
    ...mapState(['companyAttributes']),
  },
  methods: {
    updateRuleGroup(choice) {
      if (choice) {
        const { alias, rules } = choice;
        this.$emit('update-rule', {
          id: this.rule.id,
          alias,
          rules: JSON.stringify(rules),
        });
      }
      this.modals.rules = false;
    },
    deleteRuleGroup(choice) {
      if (choice) {
        this.$emit('delete-rule', { id: this.rule.id });
      }
      this.modals.delete = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.path {
  color: #b8bfcd;
}

.diag-icon {
  max-height: 20px;
}

.diag-element {
  display: table;
}

.rule-to-string {
  :deep() .variable {
    font-weight: bold;
  }
}

.rule-to-string {
  :deep() .connector {
    color: $red-mayday;
  }
}

.rule-to-string {
  :deep() .rule {
    color: $yellow-mayday;
  }
}

.rule-to-string {
  :deep() .value {
    color: $blue-mayday;
  }
}

.rule-to-string {
  :deep() .final {
    text-decoration: underline;
  }
}

.nav-link-icon:hover {
  cursor: pointer;
}

.cursor {
  cursor: pointer;
}
</style>
