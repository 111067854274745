<template>
  <div class="step-button">
    <ChildButtonLabelEditor
      :content="content"
      :is-key-step="isKeyStep"
      :has-redirection="hasRedirection"
      :edit="editLabel"
      :is-translated="isTranslated"
      v-loading="isDeleting"
      @click="$emit('focus-node')"
      @update-content="updateLabel"
    />

    <el-tooltip
      v-if="isMagicAnswer"
      content="Cette réponse est ✨magique✨"
      placement="top"
      class="group-tooltip"
    >
      <img class="magic-icon my-auto" src="~assets/magic-breadcrumb-icon.png" />
    </el-tooltip>

    <!-- MARGIN PURPOSE -->
    <span class="ml-1" v-if="isMagicAnswer"></span>

    <el-tooltip
      v-if="automation.length"
      :content="automation.alias"
      placement="top"
      class="group-tooltip"
    >
      <badge class="group-badge my-auto pl-1 pr-1" rounded type="primary"
        >🎖️</badge
      >
    </el-tooltip>

    <ChildButtonOptions
      v-if="editable"
      :child="node"
      :root-id="rootId"
      :options="options"
      :parent="parent"
      @edit-label="editLabel = true"
      @relocate-child="$emit('relocate-child', $event)"
      @delete-content="deleteContent"
      @update-content="updateRedirection"
    />
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import ChildButtonLabelEditor from './ChildButtonLabelEditor';
import ChildButtonOptions from './ChildButtonOptions';

export default {
  name: 'child-button',
  props: {
    node: Object,
    content: Object,
    rootId: String,
    editable: {
      type: Boolean,
      default: true,
    },
    options: Object,
    parent: {
      type: Object,
      default: () => {},
    },
  },
  components: { ChildButtonLabelEditor, ChildButtonOptions },
  data() {
    return {
      editLabel: false,
      popoverOpen: false,
      isDeleting: false,
      diagnostic: {},
      newLabel: '',
    };
  },
  computed: {
    isTranslated() {
      const returnLanguage =
        this.options.isStaged && this.content.stagedData
          ? this.content.stagedData.returnLanguage
          : this.content.returnLanguage;

      return returnLanguage === this.navigationLanguage;
    },
    isMagicAnswer() {
      return (
        this.node &&
        !!this.magicAnswers.find((ma) => ma.treeNodeId === this.node.id)
      );
    },
    hasRedirection() {
      return !!this.node.redirection;
    },
    automation() {
      return this.node && this.groups.find((g) => g.treeNodeId === this.node.id)
        ? this.groups.find((g) => g.treeNodeId === this.node.id)
        : {};
    },
    isKeyStep() {
      return this.node && this.node.type === 'keyStep';
    },
    ...mapState('kbStore', ['groups', 'magicAnswers']),
    ...mapGetters('knowledgeModule', ['navigationLanguage']),
  },
  methods: {
    updateLabel(e) {
      const { label } = e;
      if (label !== this.content.label)
        this.$emit('update-content', {
          id: this.content.id,
          payload: e,
          source: 'child',
        });
      this.editLabel = false;
    },
    updateRedirection(e) {
      this.$emit('update-content', {
        id: this.content.id,
        payload: e,
        source: 'child',
      });
    },
    deleteContent(e) {
      this.isDeleting = true;
      this.$emit('delete-content', e);
    },
  },
  watch: {
    editLabel() {
      this.$emit('edit-label', this.editLabel);
    },
  },
};
</script>
<style scoped>
.diag-step {
  border-radius: 2px;
}

.key-step-icon {
  margin-right: 10px;
}
.move-to {
  max-width: 18px;
}

.step-button {
  display: flex;
}

.magic-icon {
  max-width: 25px;
  margin-right: 10px;
}

.modify-step {
  max-width: 20px;
  cursor: pointer;
  padding-right: 2px;
  padding: 2px;
}

.modify-step:hover {
  border-radius: 3px;
  background-color: #f2f5f9;
}

.diag-next-steps:hover .diag-next-steps-edit-options {
  display: flex;
  visibility: visible;
}

.diag-next-steps-edit-options {
  visibility: hidden;
  margin-top: auto;
  margin-bottom: auto;
}
</style>
