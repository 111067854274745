import { BaseAiSession } from './base';

export class OpenPromptAiSession extends BaseAiSession {
  constructor({ services, language }) {
    super({ name: 'open-prompt' });
    this.services = services;
    this.language = language;
  }

  async _pingFileUpload({ url }) {
    return this.services.brainClient.pingFileUploadOpenPrompt({ url });
  }

  async _trigger({ prompt, doc }) {
    return this.services.brainClient.triggerOpenPrompt({
      prompt,
      language: this.language,
      doc,
    });
  }

  async _getStatus({ taskId, token, suffix }) {
    return this.services.brainClient.getOpenPromptPollStatus({
      taskId,
      token,
      suffix,
    });
  }

  _stream() {
    return this.services.brainClient.streamOpenPromptResponse(
      this.streamingConfig,
    );
  }

  stream() {
    if (!this.streamingConfig) throw new Error('ERROR');
    return this._stream();
  }
}
