<template>
  <button
    class="documentation-widget"
    :class="{ 'documentation-widget-active': open }"
    @click="toggleWidget"
  >
    <i class="fa-light fa-circle-question"></i>
  </button>
</template>

<script>
export default {
  props: {
    locale: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      open: false,
      loaded: false,
      WIDGET_URL: 'https://documentation.mayday.fr/widget/md-selfcare.umd.js',
    };
  },
  methods: {
    loadScript() {
      if (window.location.pathname.includes('widget')) {
        return;
      }

      const scriptEl = document.createElement('script');
      scriptEl.setAttribute('src', this.WIDGET_URL);
      document.body.append(scriptEl);
    },

    initSelfcare() {
      if (window.location.pathname.includes('widget')) {
        return;
      }

      window.addEventListener('MdSelfcareLoaded', (e) => {
        const detail = e.detail;
        window.__MAYDAY_SELFCARE__ = detail;
        window.__MAYDAY_SELFCARE__.init({
          accessKey: '589aa1ac-970c-404b-8ee3-60b893af90ba',
          position: 'bottom-left',
          widgetOpen: true,
          hideWidgetToggle: true,
          locale: this.locale,
          widgetWrapperStyle: {
            top: '60px',
            left: '64px',
            bottom: '60px',
            height: 'calc(100vh - 118px)',
          },
        });

        setTimeout(() => {
          window.__MAYDAY_SELFCARE__.updateLocale(this.locale);
        }, 500);
      });
    },

    toggleWidget() {
      if (!this.loaded) {
        this.loadScript();
        this.initSelfcare();
        this.loaded = true;
      }

      this.open = !this.open;
      if (window.__MAYDAY_SELFCARE__) {
        window.__MAYDAY_SELFCARE__.setWidgetOpeningState(this.open);
        window.__MAYDAY_SELFCARE__.updateLocale(this.locale);
      }
    },
    closeWidget() {
      if (this.loaded) {
        this.open = false;
        window.__MAYDAY_SELFCARE__.setWidgetOpeningState(this.open);
      }
    },

    cleanupScript() {
      const scriptEl = document.querySelector(
        `script[src="${this.WIDGET_URL}"]`,
      );
      if (scriptEl) {
        scriptEl.remove();
      }
      window.removeEventListener('MdSelfcareUpdated', this.initSelfcare);
    },
  },

  watch: {
    locale(newLocale) {
      if (this.loaded) {
        window.__MAYDAY_SELFCARE__.updateLocale(newLocale);
      }
    },
  },

  beforeDestroy() {
    this.cleanupScript();
  },
};
</script>

<style scoped lang="scss">
.documentation-widget {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $grey-6-mayday;
  background-color: transparent;
  transition: color 250ms, background-color 250ms;
  font-size: 16px;
  border: none;
  margin: 8px auto;
}

.documentation-widget:hover {
  background-color: $grey-2-mayday;
  color: $blue-mayday;
}

.documentation-widget-active {
  background-color: $grey-2-mayday !important;
  color: $blue-mayday !important;
}
</style>
