<template>
  <div>
    <div
      class="element-title d-flex justify-content-between align-items-center"
    >
      {{ $t('settings.general.kb-manage.subtitle') }}
      <router-link
        class="mb-2"
        type="primary"
        size="mini"
        to="/settings/knowledges/new"
      >
        <base-button
          :text="$t('settings.general.kb-manage.new-kb')"
          variant="primary"
          size="sm"
        ></base-button>
      </router-link>
    </div>
    <div class="element-description mb-3">
      {{ $t('settings.general.kb-manage.subdescription') }}
    </div>

    <div v-loading="isLoading" class="main">
      <div v-for="knowledgeDetail in adminKnowledges" :key="knowledgeDetail.id">
        <KnowledgeManageSettingsRow
          :knowledge-details="knowledgeDetail"
          :is-focused-for-edit="focusedKnowledge === knowledgeDetail.id"
          :has-access="hasAccessToKnowledge(knowledgeDetail.id)"
          :has-access-to-default-knowledge="hasAccessToDefaultKnowledge"
          @edit-knowledge-label="editKnowledgeLabel"
          @edit-knowledge-is-default="editKnowledgeIsDefault"
          @edit-knowledge-is-deleted="editKnowledgeIsDeleted"
          @edit-knowledge-icon="editKnowledgeIcon"
          @edit-knowledge-is-public="editKnowledgeIsPublic"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import KnowledgeManageSettingsRow from './KnowledgeManageSettingsRow.vue';

export default {
  name: 'knowledgeManageSettings',
  components: {
    KnowledgeManageSettingsRow,
  },
  data() {
    return {
      knowledgesDetails: [],
      isLoading: false,
      focusedKnowledge: '',
    };
  },
  async created() {
    this.isLoading = true;
    await this.getSettingsKnowledges();
    await this.getKnowledges();
    this.isLoading = false;
  },
  computed: {
    hasAccessToDefaultKnowledge() {
      return this.knowledges.some((knowledge) => knowledge.isDefault);
    },
    ...mapGetters('adminModule', ['adminKnowledges']),
    ...mapGetters('knowledgeModule', ['knowledges']),
  },
  methods: {
    hasAccessToKnowledge(knowledgeId) {
      return this.knowledges.some((knowledge) => knowledge.id === knowledgeId);
    },
    async editKnowledgeLabel({ id, newLabel }) {
      if (!id || !newLabel) return;

      await this.updateKnowledgeLabel({ id, newLabel });
    },

    async editKnowledgeIsDefault({ id }) {
      if (!id) return;
      this.isLoading = true;
      await this.updateKnowledgeIsDefault({ id });
      this.isLoading = false;
    },

    async editKnowledgeIsDeleted({ id }) {
      if (!id) return;
      this.isLoading = true;
      await this.updateKnowledgeIsDeleted({ id });
      this.isLoading = false;
    },

    async editKnowledgeIcon({ id, iconType, value, color }) {
      if (!id) return;
      this.isLoading = true;
      await this.updateKnowledgeIcon({ id, iconType, value, color });
      this.isLoading = false;
    },

    async editKnowledgeIsPublic({ id, isPublic }) {
      if (!id || isPublic === undefined || isPublic === null) return;

      await this.$services.hierarchies.updateIsPublic(id, isPublic);
    },

    ...mapActions('publicKnowledgeModule', ['getPendingDeploymentStatuses']),
    ...mapActions('knowledgeModule', ['getKnowledges']),
    ...mapActions('adminModule', [
      'getSettingsKnowledges',
      'updateKnowledgeLabel',
      'updateKnowledgeIsDeleted',
      'updateKnowledgeIsDefault',
      'updateKnowledgeIcon',
    ]),
  },
  watch: {
    knowledges: {
      handler() {
        this.isLoading = true;
        this.knowledgesDetails = this.knowledges;
        this.isLoading = false;
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.element-title {
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  font-weight: bold;
}

.element-description {
  font-size: 12px;
  color: $grey-7-mayday;
}
</style>
