<template>
  <div>
    <div class="label col d-flex align-items-center">
      <!-- ICON EDITION -->
      <SymbolEditor
        :icon="newLabel.icon"
        :color="newLabel.color"
        @update-icon="updateLabelIcon"
        @update-color="updateLabelColor"
      />

      <!-- ITEM EDITION -->
      <ItemEditor
        v-if="edit.label"
        :value="newLabel.labelName"
        :isLabel="true"
        :placeholder="'settings.card-settings-options.content-parameters.give-label-name'"
        @edit="updateLabelName"
        @cancel="edit.label = false"
        class="category"
      />
      <p class="mb-0" v-else>{{ label.labelName }}</p>

      <!-- ITEM OPTIONS -->
      <div class="option">
        <el-tooltip
          :content="
            $t('settings.card-settings-options.content-parameters.edit-label')
          "
          placement="top"
        >
          <base-button
            :icon-only="true"
            icon="pen"
            size="sm"
            variant="tertiary"
            @click="edit.label = true"
            class="ml-1"
          ></base-button>
        </el-tooltip>
        <el-popconfirm
          v-if="isUnique"
          confirm-button-text="Confirmer"
          cancel-button-text="Annuler"
          icon="el-icon-info"
          icon-color="red"
          title="En supprimant ce label vous supprimerez aussi la catégorie"
          @confirm="removeLabel"
        >
          <base-button
            slot="reference"
            :icon-only="true"
            icon="trash"
            size="sm"
            variant="tertiary"
            class="ml-1 mb-6"
          ></base-button>
        </el-popconfirm>
        <el-tooltip
          v-else
          :content="
            $t('settings.card-settings-options.content-parameters.delete-label')
          "
          placement="top"
        >
          <font-awesome-icon
            :icon="['fad', 'trash']"
            class="option__button-trash trash ml-2"
            @click="removeLabel"
          />
        </el-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import ItemEditor from './ItemEditor';
import SymbolEditor from './SymbolEditor';

export default {
  name: 'label-content',
  computed: {
    isUnique() {
      return this.category.labels.length === 1;
    },
  },
  props: {
    category: {
      type: Object,
      required: true,
    },
    label: {
      type: Object,
    },
  },
  components: {
    ItemEditor,
    SymbolEditor,
  },
  mounted() {
    this.newLabel = { ...this.newLabel, ...this.label };
  },
  data() {
    return {
      newLabel: {
        labelName: '',
        icon: 'tags',
        color: '#0081f9',
      },
      edit: {
        label: false,
      },
    };
  },
  methods: {
    updateLabelIcon(event) {
      this.newLabel.icon = event;
      this.editLabel();
    },
    updateLabelColor(event) {
      this.newLabel.color = event;
      this.editLabel();
    },
    updateLabelName(event) {
      this.newLabel.labelName = event;
      this.editLabel();
    },
    editLabel() {
      this.$emit('edit-label', this.newLabel);
      this.edit.label = false;
    },
    removeLabel() {
      this.$emit('remove-label', {
        categoryName: this.category.name,
        labelId: this.label.id,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.option__button-edit {
  max-width: 20px;
  cursor: pointer;
  padding: 2px;
  color: $grey-5-mayday;
  &:hover {
    color: $blue-mayday;
  }
}

.option__button-trash {
  max-width: 20px;
  cursor: pointer;
  padding: 2px;
  color: $grey-5-mayday;
  &:hover {
    color: $red-mayday;
  }
}
.label {
  height: 28px;
  margin: 2px 0;
}

.label .option {
  visibility: hidden;
  display: contents;
}

.label:hover .option {
  visibility: visible;
}
</style>
