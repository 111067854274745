<template>
  <div class="toast">
    <div class="toast__body">
      <span>⚠ {{ $t('knowledge.toasts.similar-contents.info') }}</span>
      <base-button-old
        class="mt-1 d-block"
        type="danger"
        size="sm"
        @click="$emit('go-to-content')"
      >
        {{ $t('knowledge.toasts.similar-contents.cta') }}
      </base-button-old>
    </div>
  </div>
</template>

<script>
export default {
  name: 'similar-content-toast',
};
</script>

<style lang="scss" scoped>
.toast {
  position: absolute;
  max-width: 250px;
  border-radius: 4px;
  background-color: white;
  border: 1px solid $red-mayday;
  z-index: 10001;

  &__body {
    padding: 8px;
    background: rgba($red-mayday, 0.1);
  }
}
</style>
