<template>
  <div>
    <DrawerRowTitle
      :title="
        $t('knowledge.drawers.content-view-setting.settings.position.title')
      "
      :tooltip="
        $t('knowledge.drawers.content-view-setting.settings.position.tooltip')
      "
    />
    <div>
      <BaseButtonCascader
        v-if="LATER_FLAG"
        :icon="['fas', 'folder']"
        :name="firstCaseParameter"
        :placeholder="
          $t(
            'knowledge.drawers.content-view-setting.settings.position.cascader',
          )
        "
        :showCascader="showCascader"
        @show="handleShow($event)"
      >
        <ContentParametersCascaderWrapper
          :source-type="'case'"
          :knowledge="knowledge"
          @clone-to-target="handleOptions"
          @close="handleShow(false)"
        />
      </BaseButtonCascader>
      <div class="reader-label">
        {{
          $t(
            'knowledge.drawers.content-view-setting.settings.position.placeholder',
          )
        }}
      </div>
      <!-- RECAP -->
      <div
        class="content-parameter-reader mt-1 w-100"
        v-if="options.length > 0"
      >
        <div
          class="d-flex mx-2 my-1 align-items-center justify-content-between"
          v-for="(option, index) in options"
          :key="option.id"
        >
          <div class="d-flex mx-2 my-1 align-items-center flex-wrap">
            <font-awesome-icon
              class="option-path__icon"
              :icon="['fad', 'home']"
            />
            <div v-for="children in option" :key="children.id">
              <span class="mx-1">></span>
              {{ children.label }}
            </div>
          </div>
          <div class="delete-path" v-if="LATER_FLAG">
            <font-awesome-icon
              class="option-path__icon"
              :icon="['fa', 'times']"
              @click="deletePath(index)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import BaseButtonCascader from '@/components/BaseButtonCascader';
import ContentParametersCascaderWrapper from '@/components/ContentParametersCascaderWrapper';
import DrawerRowTitle from '@/components/Drawers/Commons/DrawerRowTitle';

export default {
  name: 'content-view-settings-cascader',
  components: {
    BaseButtonCascader,
    ContentParametersCascaderWrapper,
    DrawerRowTitle,
  },
  props: {
    caseParameters: Array,
    knowledge: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      newGroupIds: this.groupIds,
      showCascader: false,
      options: [],
      LATER_FLAG: false,
    };
  },
  created() {
    // INIT Options on caseParameters array
    this.setOptions(this.caseParameters);
  },
  computed: {
    groupInfos() {
      return this.possibleUserGroups.filter((g) =>
        this.newGroupIds.includes(g.id),
      );
    },
    firstCaseParameter() {
      if (!this.options.length) return '';

      return this.options[0].map((option) => option.label).join(' > ');
    },
  },
  methods: {
    async handleOptions(newOptions) {
      // update options from multi cascader
      let lastCasesId = newOptions.map((caseInfo) => {
        return caseInfo.caseId;
      });
      this.options = await Promise.all(
        lastCasesId.map((lastCaseId) =>
          this.getRebuiltCasePathFromCaseId(lastCaseId),
        ),
      );
      //update caseParameters array
      this.updateCaseParameters();
      this.showCascader = false;
    },
    updateCaseParameters() {
      let newCaseParameters = [];
      this.options.forEach((option) => {
        newCaseParameters.push(this.getCaseParametersFromOption(option));
      });
      this.$emit('update-case', newCaseParameters);
    },
    getCaseParametersFromOption(option) {
      return option.map((option) => option.id).join('/');
    },
    handleShow(val) {
      this.showCascader = val;
    },
    deletePath(idx) {
      this.options.splice(idx, 1);
      this.updateCaseParameters();
    },
    async setOptions(caseParameters) {
      let lastCasesId = caseParameters.map((casePath) => {
        return casePath.split('/').pop();
      });

      this.options = await Promise.all(
        lastCasesId.map((lastCaseId) =>
          this.getRebuiltCasePathFromCaseId(lastCaseId),
        ),
      );
    },
    ...mapActions('knowledgeModule', ['getRebuiltCasePathFromCaseId']),
  },
  watch: {
    caseParameters() {
      this.setOptions(this.caseParameters);
    },
  },
};
</script>

<style lang="scss" scoped>
.reader-label {
  font-weight: bold;
  color: #000;
  font-size: 12px;
}
.content-parameter-reader {
  border: 0.5px solid #b8bfcd;
  border-radius: 2px;
  background: $grey-1-mayday;
  font-size: 12px;
}

.delete-path {
  cursor: pointer;
  color: $grey-4-mayday;
  &:hover {
    color: $red-mayday;
  }
}
</style>
