<template>
  <BaseActionCard
    v-loading="isLoading"
    :header-title="$t('knowledge.action-triggers.title')"
    :header-icon="['fal', 'comments-alt']"
    :display-add="!displaySelectForm && !selectedValue"
    @add-action="displaySelectForm = true"
  >
    <template v-slot:body>
      <component
        v-for="action in displayedActions"
        :key="`${content.id}-${action.mongoId}`"
        :is="valueToForm[action.type]"
        :hasZendesk="hasZendesk"
        :action="action"
        :languages-options="languagesOptions"
        @create="
          handleUpdateAction({
            ...$event,
            actionId: action.mongoId,
            type: action.type,
          })
        "
        @delete="handleDeleteAction(action.mongoId)"
      />
      <BaseActionCreateForm
        v-if="!selectedValue && displaySelectForm"
        class="form"
        :options="actionsOptions"
        @create="handleCreateSelectAction"
        @delete="handleCancelSelectAction"
      />
      <!-- NEW ACTION FORM -->
      <component
        :is="valueToForm[selectedValue]"
        v-else
        :hasZendesk="hasZendesk"
        :languages-options="languagesOptions"
        is-new
        auto-open
        @create="handleCreateAction"
        @delete="handleCancelSelectAction"
      />
    </template>
  </BaseActionCard>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import BaseActionCard from '../../../../../../components/Action/Base/BaseActionCard';
import BaseActionCreateForm from '../../../../../../components/Action/Base/BaseActionCreateForm';
import MessageActionForm from '../../../../../../components/Action/MessageActionForm';
import ZendeskActionForm from '../../../../../../components/Action/ZendeskActionForm';
import SalesforceCaseActionForm from '../../../../../../components/Action/SalesforceCaseActionForm';

const emojies = {
  all: '🌍',
  default: '🌍',
  'fr-fr': '🇫🇷',
  'en-gb': '🇬🇧',
  'en-us': '🇺🇸',
  'es-es': '🇪🇸',
  'it-it': '🇮🇹',
  'pt-pt': '🇵🇹',
  'de-de': '🇩🇪',
  'pl-pl': '🇵🇱',
  'nl-nl': '🇳🇱',
};

export default {
  name: 'action-main-card',
  components: {
    BaseActionCard,
    BaseActionCreateForm,
    MessageActionForm,
    ZendeskActionForm,
    SalesforceCaseActionForm,
  },
  props: {
    actions: {
      type: Array,
      default: () => [],
    },
    content: {
      type: Object,
      required: true,
    },
    templateId: {
      type: String,
      required: false,
    },
    descendentId: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      isLoading: false,
      displaySelectForm: false,
      selectedValue: null,
      valueToForm: {
        CUSTOM_MESSAGE: MessageActionForm,
        ZENDESK_MESSAGE: MessageActionForm,
        ZENDESK_MACRO: ZendeskActionForm,
        'salesforce.caseUpdate': SalesforceCaseActionForm,
      },
    };
  },
  methods: {
    async handleUpdateAction(event) {
      try {
        this.isLoading = true;
        const newActions = this.templateId
          ? await this.upsertTemplateAction({
              ...event,
              templateId: this.templateId,
              descendentId: this.descendentId,
            })
          : await this.upsertContentAction({
              ...event,
              contentId: this.content.id,
            });
        this.propagateActionUpdates(newActions);
        this.triggerSuccessMessage(
          this.$t('knowledge.action-triggers.update.success'),
        );
      } catch {
        this.triggerErrorMessage(
          this.$t('knowledge.action-triggers.update.error'),
        );
      } finally {
        this.isLoading = false;
      }
    },
    async handleCreateAction(event) {
      try {
        const newActions = this.templateId
          ? await this.upsertTemplateAction({
              ...event,
              templateId: this.templateId,
              descendentId: this.descendentId,
              type: this.selectedValue,
            })
          : await this.upsertContentAction({
              ...event,
              contentId: this.content.id,
              type: this.selectedValue,
            });
        this.propagateActionUpdates(newActions);
        this.handleCancelSelectAction();
        this.triggerSuccessMessage(
          this.$t('knowledge.action-triggers.create.success'),
        );
      } catch {
        this.triggerErrorMessage(
          this.$t('knowledge.action-triggers.create.error'),
        );
      } finally {
        this.isLoading = false;
      }
    },

    async handleDeleteAction(actionId) {
      try {
        this.isLoading = true;
        const newActions = this.templateId
          ? await this.deleteTemplateAction({
              templateId: this.templateId,
              descendentId: this.descendentId,
              actionId,
            })
          : await this.deleteContentAction({
              contentId: this.content.id,
              actionId,
            });
        this.propagateActionUpdates(newActions);
        this.triggerSuccessMessage(
          this.$t('knowledge.action-triggers.delete.success'),
        );
      } catch {
        this.triggerErrorMessage(
          this.$t('knowledge.action-triggers.delete.error'),
        );
      } finally {
        this.isLoading = false;
      }
    },

    propagateActionUpdates(newActions) {
      this.$emit('local-update-content', {
        ...this.content,
        actions: newActions,
      });
    },

    triggerSuccessMessage(message) {
      this.$message({
        message,
        type: 'success',
        center: true,
      });
    },

    triggerErrorMessage(message) {
      this.$message({
        message,
        type: 'error',
        center: true,
      });
    },

    handleCreateSelectAction(value) {
      this.selectedValue = value;
      this.displaySelectForm = false;
    },
    handleCancelSelectAction() {
      this.selectedValue = null;
      this.displaySelectForm = false;
    },
    ...mapActions('knowledgeModule', [
      'upsertContentAction',
      'deleteContentAction',
      'upsertTemplateAction',
      'deleteTemplateAction',
    ]),
  },
  computed: {
    displayedActions() {
      return this.actions.filter((action) =>
        [
          'CUSTOM_MESSAGE',
          'ZENDESK_MESSAGE',
          'ZENDESK_MACRO',
          'salesforce.caseUpdate',
        ].includes(action.type),
      );
    },
    actionsOptions() {
      const options = [];

      if (!this.hasZendesk) {
        options.push({
          label: this.$t('knowledge.action-triggers.options.message'),
          value: 'CUSTOM_MESSAGE',
        });
      } else {
        options.push({
          label: this.$t('knowledge.action-triggers.options.zendesk-macro'),
          value: 'ZENDESK_MACRO',
        });
        options.push({
          label: this.$t('knowledge.action-triggers.options.message'),
          value: 'ZENDESK_MESSAGE',
        });
      }

      if (this.hasSalesforce) {
        options.push({
          label: this.$t(
            'knowledge.action-triggers.options.salesforce-case-update',
          ),
          value: 'salesforce.caseUpdate',
        });
      }

      return options;
    },
    languagesOptions() {
      return this.content.setLanguages.map((lang) => {
        const flag = emojies[lang];
        const trad = this.$t(`knowledge.languages.${lang}`);
        return {
          value: lang,
          label: `${flag} ${trad}`,
        };
      });
    },
    ...mapGetters(['hasZendesk', 'hasSalesforce']),
  },
};
</script>

<style lang="scss" scoped>
.form {
  margin-bottom: 12px;
}
</style>
