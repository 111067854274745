<template>
  <div class="mt-2 case-viewer-header-body">
    <div class="fake-loader__wrapper" v-if="fakeLoading">
      <SkeletonText
        class="fake-loader"
        :style="`width: ${newBody.length * 8}px`"
      />
    </div>
    <base-button
      v-else-if="!body && !isEditing && editable"
      variant="secondary"
      @click="triggerBodyInput"
      :text="$t('knowledge.actions.add-case-description')"
    />
    <div v-else-if="!isEditing" class="row align-items-start">
      <div
        class="col-11 case-viewer-header-body__body"
        @dblclick="triggerBodyInput"
      >
        {{ body }}
      </div>
      <div v-if="!isEditing && editable" class="edit-body">
        <div class="icon-wrapper">
          <base-button
            icon="pen"
            variant="tertiary"
            size="sm"
            icon-only
            @click="triggerBodyInput"
          />
        </div>
        <div class="icon-wrapper">
          <base-button
            icon="trash"
            variant="tertiary"
            size="sm"
            icon-only
            @click="removeBody"
          />
        </div>
      </div>
    </div>
    <div v-else class="row">
      <input
        v-autowidth="{
          maxWidth: '700px',
          minWidth: '300px',
          comfortZone: 0,
        }"
        class="col-11 case-viewer-header-body__body"
        :placeholder="$t('knowledge.case-desc-placeholder')"
        ref="newBodyEdition"
        v-model="newBody"
        @blur="updateBody()"
        @keyup.enter="$event.target.blur()"
        @keyup.escape="escape($event)"
      />
    </div>
  </div>
</template>

<script>
import SkeletonText from '@/components/Loaders/SkeletonText';
export default {
  name: 'case-viewer-header-description',
  components: {
    SkeletonText,
  },
  props: {
    body: {
      type: String,
      required: true,
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isEditing: false,
      fakeLoading: false,
      newBody: '',
    };
  },
  methods: {
    triggerBodyInput() {
      this.newBody = this.body;
      this.isEditing = true;
      this.$nextTick(() => this.$refs.newBodyEdition.focus());
    },
    updateBody() {
      this.newBody = this.newBody.trim();
      if (this.newBody !== this.body) {
        this.fakeLoading = true;
        this.$emit('update-body', this.newBody);

        // fake delay to simulate wait of request response to avoid visual tilt
        setTimeout(() => {
          this.isEditing = false;
          this.fakeLoading = false;
        }, 1000);
      } else this.isEditing = false;
    },
    removeBody() {
      this.$emit('remove-body');
    },
    escape(event) {
      this.newBody = this.body;
      event.target.blur();
    },
  },
};
</script>

<style lang="scss" scoped>
.case-viewer-header-body {
  line-height: 22px;
  color: $grey-7-mayday;

  input {
    outline: none;
    border: none;
    line-height: 20px;
  }
}

.edit-body {
  visibility: hidden;
  line-height: 22px;
  display: flex;
  align-items: center;
  gap: 4px;
}

.case-viewer-header-body:hover .edit-body {
  visibility: visible;
}

.fake-loader {
  width: 100%;
  height: 18px;

  &__wrapper {
    display: flex;
    padding: 2px 0;
  }
}

.icon-wrapper {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: $grey-5-mayday;
  &:hover {
    border-radius: 3px;
    background-color: #f2f5f9;
    color: $blue-mayday;
  }
}

.modify-icon {
  width: 12px;
  height: 12px;
}
</style>
