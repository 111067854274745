<template>
  <div>
    <div class="information-paragraph mt-2">
      <div class="paragraph-title">
        {{ $t('settings.users-administration.drawer.main.informations') }}
      </div>
      <div class="paragraph-content">
        <div class="d-flex flex-row align-items-center mb-2">
          <div
            class="input-wrapper small-input d-flex flex-column align-items-left mr-2"
            v-if="!pending"
          >
            {{ $t('settings.users-administration.drawer.main.first-name') }}
            <el-input
              v-model="model.givenName"
              size="small"
              @change="handleUserChange"
            ></el-input>
          </div>
          <div
            class="input-wrapper small-input d-flex flex-column align-items-left"
            v-if="!pending"
          >
            {{ $t('settings.users-administration.drawer.main.last-name') }}
            <el-input
              v-model="model.familyName"
              size="small"
              @change="handleUserChange"
            ></el-input>
          </div>
        </div>
        <div class="d-flex flex-row align-items-center">
          <div class="email d-flex flex-column align-items-left">
            {{ $t('settings.users-administration.drawer.main.email') }}
            <el-input
              v-model="model.email"
              size="small"
              disabled
              @change="handleUserChange"
            ></el-input>
          </div>
        </div>
      </div>
    </div>
    <div class="group-paragraph mt-4">
      <div class="paragraph-title">
        {{ $t('settings.users-administration.drawer.main.group-paragraph') }}
      </div>
      <ContentViewSettingsCallout
        class="input-wrapper callout-wrapper"
        trad="settings.users-administration.drawer.main"
        icon="exclamation-triangle"
        type="warning"
      />

      <div class="paragraph-content">
        <div class="d-flex flex-row align-items-center mb-2">
          <div class="input-wrapper d-flex flex-column align-items-left">
            {{ $t('settings.users-administration.drawer.main.group') }}
            <el-select
              size="small"
              v-loading="loading"
              v-model="model.group"
              :placeholder="
                $t(
                  'components.modals.update-user-group.group-select-placeholder',
                )
              "
              @input="handleUserChange"
            >
              <el-option
                :disabled="loading"
                v-for="group in groups"
                :key="group.id"
                :label="group.name"
                :value="group.id"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div class="d-flex flex-row align-items-center mb-2">
          <div class="input-wrapper d-flex flex-column align-items-left">
            {{ $t('settings.users-administration.drawer.main.role') }}
            <el-select
              v-model="model.role"
              v-loading="loading"
              :placeholder="
                $t('components.modals.update-user-role.role-select-placeholder')
              "
              size="small"
              multiple
              collapse-tags
              @input="handleUserChange"
            >
              <el-option
                v-for="role in roles"
                :disabled="loading"
                :key="role.id"
                :label="role.name"
                :value="role.id"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div class="d-flex flex-row align-items-center mb-2">
          <div class="input-wrapper d-flex flex-column align-items-left">
            {{ $t('settings.users-administration.drawer.main.label') }}
            <el-select
              v-model="model.labels"
              v-loading="loading"
              multiple
              clearable
              collapse-tags
              size="small"
              @input="handleUserChange"
            >
              <el-option-group
                v-for="category in userLabelCategories"
                :disabled="loading"
                :key="category.id"
                :value="category.id"
                class="label-select"
              >
                <div class="option-group-title">
                  <ColoredIcon
                    class="option-icon"
                    :icon="category.icon"
                    :color="category.color"
                  />
                  {{ category.name }}
                </div>
                <el-option
                  v-for="label in category.companyUserLabels"
                  :key="label.id"
                  :label="label.name"
                  :value="label.id"
                ></el-option>
              </el-option-group>
            </el-select>
          </div>
        </div>
      </div>
    </div>
    <div class="configuration-paragraph mt-4" v-if="hasZendesk">
      <div class="paragraph-title">
        {{ $t('settings.users-administration.drawer.main.configuration') }}
      </div>
      <div class="paragraph-content">
        <div class="d-flex flex-row align-items-center mb-2">
          <div class="input-wrapper d-flex flex-column align-items-left mr-2">
            <div
              class="d-flex flex-row align-items-center justify-content-left mb-1"
            >
              {{ $t('settings.users-administration.drawer.main.helpdeskid') }}
              <font-awesome-icon
                :icon="['fal', 'sync']"
                class="ml-2 refresh-icon"
                @click="$emit('refresh-helpdesk-id', user.id)"
              ></font-awesome-icon>
            </div>

            <el-input
              v-model="model.helpdeskId"
              disabled
              size="small"
            ></el-input>
          </div>
        </div>
      </div>
      <div class="paragraph-content" v-if="model.externalId">
        <div class="d-flex flex-row align-items-center mb-2">
          <div class="input-wrapper d-flex flex-column align-items-left mr-2">
            <div
              class="d-flex flex-row align-items-center justify-content-left mb-1"
            >
              {{ $t('settings.users-administration.drawer.main.externalId') }}
            </div>
            <el-input
              v-model="model.externalId"
              disabled
              size="small"
            ></el-input>
          </div>
        </div>
      </div>
    </div>
    <div class="actions-paragraph mt-4 mb-3">
      <div class="paragraph-title mb-2">
        {{ $t('settings.users-administration.drawer.main.actions') }}
      </div>
      <div class="paragraph-content">
        <div
          class="d-flex flex-row align-items-center"
          v-if="allowResetPassword"
        >
          <div slot="reference">
            <base-button
              variant="primary"
              icon="key"
              size="md"
              class="mb-2"
              :loading="loader.reset"
              :text="
                $t('settings.users-administration.drawer.main.reset-password')
              "
              @click="handleResetPassword"
            ></base-button>
          </div>
        </div>
        <div class="d-flex flex-row align-items-center">
          <el-popconfirm
            class="indicator-popconfirm"
            :confirm-button-text="
              $t('settings.users-administration.drawer.main.confirm')
            "
            confirm-button-type="plain"
            :cancel-button-text="
              $t('settings.users-administration.drawer.main.cancel')
            "
            icon="el-icon-info"
            icon-color="red"
            :title="
              $t('settings.users-administration.drawer.main.confirm-title')
            "
            @confirm="handleDeleteUser"
          >
            <base-button
              slot="reference"
              variant="danger"
              size="md"
              :text="
                $t('settings.users-administration.drawer.main.delete-user')
              "
            ></base-button>
          </el-popconfirm>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ColoredIcon from 'components/Icons/ColoredIcon';
import ContentViewSettingsCallout from '@/components/Drawers/ContentViewSettingDrawer/ContentViewSettingsComponents/ContentViewSettingsCallout';

export default {
  name: 'user-drawer-main',
  props: {
    user: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
    pending: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ColoredIcon,
    ContentViewSettingsCallout,
  },
  data() {
    return {
      model: {
        familyName: '',
        givenName: '',
        email: '',
        group: '',
        role: [],
        labels: [],
        helpdeskId: '',
        externalId: '',
        lang: '',
        id: '',
      },
      loader: {
        reset: false,
      },
    };
  },
  mounted() {
    this.initModel();
  },
  computed: {
    labelToCategoryLookup() {
      const lookup = new Map();
      this.userLabelCategories.forEach((category) => {
        category.companyUserLabels.forEach((label) => {
          lookup.set(label.id, category.id);
        });
      });
      return lookup;
    },
    allowResetPassword() {
      return this.hasCompanyPreferenceWithValue('ALLOW_NO_EMAIL_USERS');
    },
    ...mapGetters('adminModule', ['groups', 'roles', 'userLabelCategories']),
    ...mapGetters(['hasZendesk', 'hasCompanyPreferenceWithValue']),
  },
  methods: {
    initModel() {
      Object.keys(this.model).map((key) => {
        switch (key) {
          case 'group':
            this.model.group = this.user.groups[0]
              ? this.user.groups[0].id
              : [];
            break;
          case 'role':
            this.model.role = this.user.role.reduce((acc, val) => {
              acc.push(val.id);
              return acc;
            }, []);
            break;
          case 'labels':
            this.model.labels = this.user.labels.reduce((acc, val) => {
              acc.push(val.id);
              return acc;
            }, []);
            break;
          case 'helpdeskId':
            this.model.helpdeskId = this.user.helpdeskId
              ? this.user.helpdeskId
              : this.$t(`settings.users-administration.empty-state.helpdeskId`);
            break;
          case 'externalId':
            this.model.externalId = this.user.externalId
              ? this.user.externalId
              : this.$t(`settings.users-administration.empty-state.externalId`);
            break;
          default:
            this.model[key] = this.user[key];
        }
      });
    },
    handleUserChange() {
      this.model.labels = [
        ...this.uniqueValuePerCategory(this.model.labels).values(),
      ];

      if (this.verifyUser(this.model))
        this.$emit('update-focused-user', { user: this.model });
    },
    uniqueValuePerCategory(newSelectedValues) {
      const lookup = new Map();
      newSelectedValues.forEach((labelId) => {
        lookup.set(this.labelToCategoryLookup.get(labelId), labelId);
      });
      return lookup;
    },
    verifyUser(user) {
      if (user.role.length === 0) {
        this.$message({
          message: this.$t(
            'settings.users-administration.drawer.main.select-role',
          ),
          type: 'error',
          center: true,
        });
        return false;
      }
      return true;
    },
    handleDeleteUser() {
      this.$emit('delete-user', { userIds: [this.user.id] });
      this.$emit('close');
    },
    async handleResetPassword() {
      this.loader.reset = true;
      try {
        const resetLink = await this.resetEmployeePasswordFromAdmin(
          this.user.id,
        );
        await navigator.clipboard.writeText(resetLink);
        this.$message({
          message: this.$t(
            'settings.users-administration.drawer.main.reset-password-success',
          ),
          type: 'success',
        });
      } catch (err) {
        console.log(err);
        this.$message({
          message: this.$t(
            'settings.users-administration.drawer.main.reset-password-failure',
          ),
          type: 'error',
        });
      }
      this.loader.reset = false;
    },
    ...mapActions('adminModule', ['resetEmployeePasswordFromAdmin']),
  },
  watch: {
    'model.role'(newVal, oldVal) {
      if (newVal.length === 0 && oldVal.length !== 0) {
        if (this.user.role)
          this.model.role = this.user.role.map((role) => role.id);
        else this.model.role = [this.roles[0].id];
      }
    },
    'user.helpdeskId'(newVal) {
      this.model.helpdeskId = newVal
        ? newVal
        : this.$t(`settings.users-administration.empty-state.helpdeskId`);
    },
  },
};
</script>

<style lang="scss" scoped>
.paragraph-title {
  font-size: 12px;
  color: $grey-6-mayday;
}

.email {
  width: 80% !important;
  min-width: 280px;
}

.small-input {
  :deep() .el-input--small {
    width: 100% !important;
  }
}

.el-select--small {
  min-width: 280px;
}

:deep() .el-input {
  height: 32px;
}

:deep() .el-select .el-input .el-input__inner {
  height: 100%;
}

.option-group-title {
  display: flex;
  margin-left: 12px;
  .option-icon {
    margin-right: 4px;
  }
}

.input-wrapper {
  width: 55%;
}

.refresh-icon {
  width: 12px;
  &:hover {
    color: $blue-mayday;
    cursor: pointer;
  }
}

.callout-wrapper {
  min-width: 280px;
}
</style>
