<template>
  <BaseModal
    size="md"
    button-size="md"
    :sidebar="false"
    :title="$t(modalTitle)"
    :description="$t('components.modals.add-company-group.description')"
    :open="localDisplay"
    :confirm-text="$t(confirmText)"
    :disabled="!validate"
    @cancel="handleCancel"
    @confirm="handleConfirm"
    @close="userChoice(false)"
  >
    <div class="d-flex justify-content-center modal-steps" v-loading="loading">
      <div class="col-12 text-center body-wrapper">
        <set-group-name
          v-if="currentStep === 1"
          :current-name="groupName"
          :registeredNames="registeredNames"
          :former-name="formerName"
          @input="setGroupName"
        />
        <set-knowledge-restrictions
          v-else-if="currentStep === 2"
          :current-knowledges="newGroup.knowledges"
          :group-id="newGroup.id"
          @update-restrictions="updateRestrictions"
        />
        <group-summary
          v-else-if="currentStep === 3 || loading"
          :group="newGroup"
        />
      </div>
    </div>
    <div class="row pb-3 md-stepper-group">
      <el-steps
        align-center
        class="col-12"
        :active="currentStep - 1"
        finish-status="success"
      >
        <el-step
          :description="
            $t('components.modals.add-company-group.sequence.step-1')
          "
        ></el-step>
        <el-step
          :description="
            $t('components.modals.add-company-group.sequence.step-2')
          "
        ></el-step>
        <el-step
          :description="
            $t('components.modals.add-company-group.sequence.step-3')
          "
        ></el-step>
      </el-steps>
    </div>
  </BaseModal>
</template>
<script>
import { mapGetters } from 'vuex';
import SetGroupName from './SetGroupName';
import SetKnowledgeRestrictions from './SetKnowledgeRestrictions/SetKnowledgeRestrictions';
import GroupSummary from './GroupSummary';

export default {
  name: 'add-company-group-modal',
  components: {
    SetGroupName,
    SetKnowledgeRestrictions,
    GroupSummary,
  },
  props: {
    step: {
      type: Number,
      default: () => 1,
    },
    groups: {
      type: Array,
      default: () => ({}),
    },
    group: {
      type: Object,
      default: () => {
        return {
          name: '',
          knowledges: [],
        };
      },
    },
    formerName: {
      type: String,
      default: '',
    },
    display: Boolean,
    loading: Boolean,
    isEdit: Boolean,
  },
  computed: {
    registeredNames() {
      return this.groups.map((group) => group.name);
    },
    groupName() {
      return this.newGroup ? this.newGroup.name : '';
    },
    confirmText() {
      if (this.currentStep === 1 || this.currentStep === 2) {
        return 'components.modals.add-company-group.next-step';
      } else return 'components.modals.add-company-group.confirm';
    },
    modalTitle() {
      if (this.isEdit) {
        return 'components.modals.add-company-group.edit-title';
      } else return 'components.modals.add-company-group.title';
    },
    validate() {
      switch (this.currentStep) {
        case 1:
          if (this.groupName.length >= 3) {
            return (
              !this.registeredNames.includes(this.groupName) ||
              (this.formerName !== '' && this.groupName === this.formerName)
            );
          } else {
            return false;
          }
        case 2: {
          const { knowledges } = this.newGroup;
          const restrictedKnowledgesHaveAtLeastOneSelectedCase =
            knowledges.reduce(
              (acc, knowledge) =>
                acc &&
                (!knowledge.isRestricted ||
                  (knowledge.isRestricted && knowledge.restrictedNodes.length)),
              true,
            );

          return (
            knowledges.length > 0 &&
            restrictedKnowledgesHaveAtLeastOneSelectedCase
          );
        }
        default:
          return true;
      }
    },
    ...mapGetters(['isMultiLingual']),
  },
  data() {
    return {
      currentStep: this.step,
      newGroup: {
        name: '',
        knowledges: [],
      },
      localDisplay: this.display,
    };
  },
  created() {
    this.newGroup = Object.assign(this.newGroup, this.group);
  },
  methods: {
    handleConfirm() {
      if (this.currentStep === 1 || this.currentStep === 2)
        return (this.currentStep = this.currentStep + 1);
      else return this.userChoice(true);
    },
    handleCancel() {
      if (this.currentStep === 1) return this.userChoice(false);
      else return (this.currentStep = this.currentStep - 1);
    },
    userChoice(choice) {
      if (choice) {
        this.currentStep += 1;
      }
      this.$emit('choice', choice ? this.newGroup : null);
    },
    setGroupName(groupName) {
      this.newGroup.name = groupName;
    },
    setReachable(reachable) {
      this.newGroup.reachable = reachable;
    },
    updateRestrictions(restrictions) {
      this.newGroup.knowledges = restrictions;
    },
  },
  watch: {
    localDisplay() {
      if (!this.localDisplay) this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.md-stepper-group {
  :deep() .el-step__main .el-step__description {
    padding-top: 10px;
  }
}

.body-wrapper {
  max-height: 50vh;
  overflow: auto;
}

.modal-steps {
  border: 1px solid $grey-3-mayday;
  border-radius: 4px;
}
</style>
