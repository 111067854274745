var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BaseModal',{attrs:{"button-size":"md","custom-width":"400px","custom-height":"400px","sidebar":false,"title":_vm.$t(
      'public-knowledge.appearances.items.code-custom.custom-css.modal.title',
    ),"description":_vm.$t(
      'public-knowledge.appearances.items.code-custom.custom-css.modal.description',
    ),"open":_vm.localDisplay,"confirm-text":_vm.$t('public-knowledge.appearances.items.code-custom.custom-css.confirm'),"disabled":_vm.fileUrl || !_vm.file},on:{"confirm":_vm.uploadFile,"close":function($event){return _vm.$emit('close')}}},[(_vm.callout)?_c('ContentViewSettingsCallout',{attrs:{"trad":_vm.callout,"icon":"lightbulb","type":"info"}}):_vm._e(),(_vm.templateUrl)?_c('div',[_c('base-link',{staticClass:"mb-1",attrs:{"text":_vm.$t(
          'public-knowledge.appearances.items.code-custom.custom-css.template',
        ),"href":_vm.templateUrl,"target":"_blank","variant":"primary"}})],1):_vm._e(),(_vm.upload)?_c('el-form',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"enctype":"multipart/form-data"}},[_c('el-upload',{staticClass:"my-2 upload-demo",attrs:{"drag":"","action":"","auto-upload":false,"show-file-list":false,"accept":_vm.accept,"on-change":_vm.setFiles}},[_c('img',{attrs:{"src":require("assets/empty-contents.svg"),"width":"100px"}}),_c('div',{staticClass:"el-upload__text"},[_vm._v(" "+_vm._s(_vm.$t('knowledge.modals.contribution.drop-file'))+" "),_c('em',[_vm._v(_vm._s(_vm.$t('knowledge.modals.contribution.upload')))])])])],1):_c('div',{staticClass:"d-flex align-items-center justify-content-center mb-3 files-upload-modal-body"},[(!_vm.fileUrl)?_c('div',{class:{ error: _vm.error }},[_vm._v(" "+_vm._s(_vm.file ? _vm.file.name : null)+" ")]):_c('div',[_c('base-link',{staticClass:"mb-1",attrs:{"text":_vm.$t(
            'public-knowledge.appearances.items.code-custom.custom-css.download',
          ),"href":_vm.fileUrl,"target":"_blank","variant":"primary"}})],1),_c('div',{staticClass:"ml-2 icon-wrapper",on:{"click":_vm.deleteUrl}},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'trash']}})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }