<template>
  <el-collapse v-model="focusedContent">
    <el-collapse-item
      v-for="item in items"
      :key="item.group.id"
      :name="item.group.id"
      @click.native="
        $emit('open-automation', {
          contentId: item.content._id,
          contentType: item.content.type,
        })
      "
    >
      <!-- TITLE -->
      <template slot="title" class="row">
        <div
          class="d-flex flex-row align-items-center justify-content-between"
          style="width: 96%"
        >
          <div class="p-0">
            {{ item.group.alias }}
            <el-tooltip
              placement="top"
              v-if="item.group.RuleAttribute.length === 0"
            >
              <div slot="content">
                <small>{{ $t('automations.main.row.empty-state') }}</small>
              </div>
              <font-awesome-icon
                :icon="['fad', 'siren-on']"
                class="ml-2 mt-1"
                color="red"
              />
            </el-tooltip>
          </div>
          <div class="text-right">
            <span class="mr-2">{{ item.content.label }}</span>
            <span>
              <img
                class="type-icon"
                :src="getIcon(item.content.type)"
                alt="not_found"
              />
            </span>
          </div>
        </div>
      </template>

      <!-- BODY -->
      <div>
        <AutomationsRule :rule="item" />
        <div class="d-flex flex-row-reverse align-items-center my-2">
          <div class="text-right mx-1">
            <el-button
              type="danger"
              plain
              size="mini"
              @click="deleteRule(item.group)"
              :loading="isDeleting"
              >{{
                isDeleting ? 'Loading' : $t('automations.main.row.delete')
              }}</el-button
            >
          </div>
          <div class="text-right mx-1">
            <el-button
              type="primary"
              plain
              size="mini"
              @click="focusContent(item.content)"
              >{{ $t('automations.main.row.edit') }}
            </el-button>
          </div>
        </div>
      </div>
    </el-collapse-item>
  </el-collapse>
</template>

<script>
import AutomationsRule from './Main/AutomationsRule';
import gqlService from 'utils/gqlService';

export default {
  name: 'AutomationsMainRow',
  components: {
    AutomationsRule,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      focusedContent: '',
      isDeleting: false,
    };
  },
  methods: {
    focusContent(content) {
      let urlPath = '';

      if (content.type === 'Step') {
        const rootId = content.ancestorsContent[0].id;
        urlPath = `knowledge/content/${rootId}/step/${content._id}`;
      } else {
        urlPath = `knowledge/content/${content._id}`;
      }
      this.$router.push(urlPath);
    },
    deleteRule({ id }) {
      this.isDeleting = true;
      gqlService.knowledge.deleteKnowledgeGroup(id).then((res) => {
        if (res) {
          this.$emit('automation-delete', id);
          this.$message('Votre règle a été supprimée avec succès');
        } else {
          this.$message('Problème durant la supression de votre règle');
        }
        this.isDeleting = false;
      });
    },
    getIcon(type) {
      switch (type) {
        case 'Article':
          return require('@/assets/article_icon.svg');
        default:
          return require('@/assets/diag_icon.svg');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.add-condition-button {
  color: $grey-5-mayday;
  cursor: pointer;
}
.and-operator {
  color: $blue-mayday;
}
.collapse-condition-input {
  width: 50%;
}
.type-icon {
  width: 12px;
  height: 12px;
}
.collapse-title {
  color: $grey-5-mayday;
  text-transform: uppercase;
  border-right: 1px solid $grey-5-mayday;
}
.collapse-condition-content {
  margin-left: 10px;
  padding: 10px;
  border: 1px solid #d5dae3;
  background: $grey-1-mayday;
  border-radius: 5px;
}
.collapse-bc {
  a {
    font-weight: 800;
    color: $grey-5-mayday;
  }
  .collapse-bc-last {
    color: $blue-mayday;
  }
}
.collapse-actions {
  color: #525f7f;
  font-weight: 400;
  .collapse-actions-special {
    font-weight: 700;
    color: $blue-mayday;
  }
}
</style>
