import { render, staticRenderFns } from "./BuilderSideBarItem.vue?vue&type=template&id=77b45834&scoped=true&"
import script from "./BuilderSideBarItem.vue?vue&type=script&lang=js&"
export * from "./BuilderSideBarItem.vue?vue&type=script&lang=js&"
import style0 from "./BuilderSideBarItem.vue?vue&type=style&index=0&id=77b45834&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77b45834",
  null
  
)

export default component.exports