<template>
  <div class="import-company-attributes-modal">
    <modal :show.sync="localDisplay">
      <h4 slot="header" class="modal-title" id="modal-title-default">
        {{ $t('automations.import-modal.modal-auto-title') }}
      </h4>
      <div class="row d-flex justify-content-center">
        <div class="col-9 text-center">
          <p class="mb-4">
            {{ $t('automations.import-modal.modal-auto-subtitle') }}
          </p>
          <p>
            <small>{{
              $t('automations.import-modal.modal-auto-warning')
            }}</small>
          </p>
        </div>
        <hr class="col-4 my-4" />
        <el-table
          class="col-11 table-responsive attribute-table table-flush"
          v-loading="isFieldsLoading"
          header-row-class-name="thead-light"
          row-key="id"
          ref="multipleTable"
          :data="attributesList"
          @selection-change="onSelectionChange"
        >
          <el-table-column type="selection" align="center" min-width="120px">
          </el-table-column>
          <el-table-column label="Model" prop="name" width="120" sortable>
            <template v-slot="{ row }">
              <badge rounded type="primary">{{ row.model }}</badge>
            </template>
          </el-table-column>
          <el-table-column label="Attribut" prop="name" sortable>
            <template v-slot="{ row }">
              <b>{{ row.label }}</b>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <template slot="footer">
        <base-button-old
          type="primary"
          outline
          size="sm"
          @click="userChoice(true)"
          >{{
            $t('automations.import-modal.modal-auto-import-cta')
          }}</base-button-old
        >
        <base-button-old
          type="secondary"
          size="sm"
          @click="userChoice(false)"
          >{{
            $t('automations.import-modal.modal-auto-close-cta')
          }}</base-button-old
        >
      </template>
    </modal>
  </div>
</template>
<script>
import { mapActions } from 'vuex';

export default {
  name: 'import-company-attributes-modal',
  props: {
    display: Boolean,
    integration: String,
    companyAttributes: Array,
  },
  created() {
    this.getFields(this.integration);
  },
  mounted() {
    if (this.companyAttributes.length > 0) {
      this.companyAttributes.forEach((row) => {
        this.$refs.multipleTable.toggleRowSelection(row);
      });
    }
  },
  data() {
    return {
      localDisplay: this.display,
      attributesList: [],
      selectedRows: [],
      isFieldsLoading: true,
    };
  },
  methods: {
    getFields(integration) {
      this.isFieldsLoading = true;
      this.getIntegrationAttributes(integration).then((response) => {
        this.isFieldsLoading = false;
        if (response) {
          this.attributesList = response.data;
          this.$nextTick(() => {
            if (this.companyAttributes.length > 0) {
              this.companyAttributes.forEach((row) => {
                const el = this.attributesList.find(
                  ({ field }) => String(field) === row.field,
                );
                if (el) this.$refs.multipleTable.toggleRowSelection(el);
              });
            }
          });
        }
      });
    },
    onSelectionChange(selectedRows) {
      this.selectedRows = selectedRows;
    },
    userChoice(choice) {
      this.$emit('choice', choice ? this.selectedRows : null);
    },
    ...mapActions(['getIntegrationAttributes']),
  },
  watch: {
    localDisplay() {
      if (!this.localDisplay) this.$emit('close');
    },
  },
};
</script>
<style lang="scss" scoped>
.attribute-table {
  :deep() .el-table__body-wrapper {
    max-height: 250px;
    overflow: auto;
    overflow-x: hidden;
  }
}

.attribute-table {
  :deep() .cell {
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.attribute-table {
  :deep() .cell:active {
    white-space: unset;
    word-break: break-word;
    cursor: pointer;
  }
}

.attribute-table {
  :deep() .el-checkbox {
    margin-bottom: 0px;
  }
}
</style>
