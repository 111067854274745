<template>
  <div class="category-labels">
    <!-- EMPTY STATE -->
    <div v-if="!labels.length && !newLabel">
      <p class="empty-category-info my-0">
        {{
          $t('settings.card-settings-options.content-parameters.empty-category')
        }}
      </p>
      <base-button
        variant="secondary"
        class="mt-1"
        size="md"
        @click="$emit('new-label')"
        :text="
          $t('settings.card-settings-options.content-parameters.create-label')
        "
      ></base-button>
    </div>
    <!-- LABELS -->
    <div v-for="label in labels" :key="label.id">
      <ModuleLabelsSettingsOverviewItem
        v-bind="label"
        @delete-label="$emit('delete-label', $event)"
        @update-label-title="$emit('update-label-title', $event)"
        @update-label-icon="$emit('update-label-icon', $event)"
      />
    </div>
    <NewLabel
      v-if="newLabel"
      @reset-form="$emit('cancel-new-label-input')"
      @add-label="handleCreateLabel"
    />
  </div>
</template>

<script>
import ModuleLabelsSettingsOverviewItem from './ModuleLabelsSettingsOverviewItem';
import NewLabel from '@/components/Settings/ContentParameters/NewLabel.vue';

export default {
  name: 'ModuleLabelsSettingsOverview',
  components: {
    ModuleLabelsSettingsOverviewItem,
    NewLabel,
  },
  props: {
    labels: {
      type: Array,
      default: () => [],
    },
    newLabel: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleCreateLabel(event) {
      const { labelName, icon, color } = event;

      const payload = {
        title: labelName,
        icon: {
          value: icon,
          color,
          type: 'fa',
        },
      };

      this.$emit('create-label', payload);
    },
  },
};
</script>

<style scoped>
.empty-category-info {
  font-size: 14px;
}

.category-labels {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
</style>
