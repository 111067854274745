<template>
  <div :class="`${baseClass} my-3`">
    <IpRestrictionSettingsDrawerDeletion
      class="pt-2"
      v-if="showDeletion"
      @cancel="addIpRange()"
      @disable="disableIpRestrictions()"
    />
    <IpRestrictionSettingsDrawerIpRanges
      v-else
      :ip-ranges-prop="ipRanges"
      @change="ipRanges = $event"
    />
    <div :class="`${baseClass}__ip-ranges__submission`" v-if="!showDeletion">
      <base-button
        :text="$t('generic.cancel')"
        variant="secondary"
        size="md"
        @click="$emit('close')"
        class="mr-2"
      ></base-button>
      <base-button
        :text="$t('generic.confirm')"
        variant="primary"
        size="md"
        :disabled="!validIpRanges"
        @click="setIpRanges()"
        :loading="loader"
      ></base-button>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { randomString } from '@/components/stringUtils';
import isValidIpAddress from '@/utils/isValidIpAddress';
import IpRestrictionSettingsDrawerIpRanges from './IpRestrictionSettingsDrawerIpRanges.vue';
import IpRestrictionSettingsDrawerDeletion from './IpRestrictionSettingsDrawerDeletion.vue';

const baseClass = 'ip-restriction-settings-drawer';
const i18nPre =
  'settings-layout.menu-data.authentication.ip-restriction.drawer';
export default {
  name: 'ip-restriction-settings-drawer',
  components: {
    IpRestrictionSettingsDrawerIpRanges,
    IpRestrictionSettingsDrawerDeletion,
  },
  computed: {
    showDeletion() {
      return !this.ipRanges.length;
    },
    validIpRanges() {
      return (
        this.ipRanges &&
        this.ipRanges
          .flatMap((_el, idx) => {
            return ['name', 'start', 'end'].map((key) =>
              this.validInput(idx, key),
            );
          })
          .every((el) => el)
      );
    },
  },
  async mounted() {
    await this.loadIpRanges();
  },
  data() {
    return {
      baseClass,
      ipRanges: [],
      storedIpRanges: [],
      pre: i18nPre,
      loader: false,
      loaderTimeout: null,
    };
  },
  methods: {
    addIpRange() {
      this.ipRanges.push({
        id: randomString(8),
        name: '',
        start: '',
        end: '',
      });
    },
    validInput(idx, col) {
      const input = this.ipRanges[idx][col];
      if (col === 'name') {
        return input && input.length > 0;
      }
      return isValidIpAddress(input);
    },
    async loadIpRanges() {
      const { ipRanges } = await this.getCompanyIpRanges();
      if (ipRanges && !ipRanges.length) {
        this.addIpRange();
        this.storedIpRanges = [];
      } else {
        this.ipRanges = [...ipRanges];
        this.storedIpRanges = ipRanges;
      }
    },
    startLoader() {
      this.loader = true;
      this.loaderTimeout = setTimeout(() => {
        this.stopLoader();
        this.sendMessage('error');
      }, 5000);
    },
    stopLoader() {
      this.loader = false;
      clearTimeout(this.loaderTimeout);
      this.loaderTimeout = null;
    },
    sendMessage(type, key) {
      this.$message({
        duration: 2000,
        type,
        message: this.$t(`${this.pre}.${key || type}`),
      });
    },
    async setIpRanges() {
      try {
        this.startLoader();
        const res = await this.setCompanyIpRanges(this.ipRanges);
        this.ipRanges = res.ipRanges;
        this.sendMessage('success');
      } catch (err) {
        this.sendMessage('error');
      } finally {
        this.stopLoader();
      }
    },
    async disableIpRestrictions() {
      try {
        await this.deleteCompanyIpRanges();
        this.sendMessage('success', 'successDisable');
      } catch (err) {
        this.sendMessage('error', 'errorDisable');
      } finally {
        this.$emit('close');
      }
    },
    ...mapActions('adminModule', [
      'getCompanyIpRanges',
      'setCompanyIpRanges',
      'deleteCompanyIpRanges',
    ]),
  },
};
</script>

<style lang="scss" scoped>
.ip-restriction-settings-drawer {
  width: 100%;
  &__ip-ranges {
    flex-direction: column;
    align-content: space-between;
    max-height: 75vh;
    overflow: auto;

    &__header {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      position: sticky;
      top: 0px;
      z-index: 1;
      background-color: white;
    }

    &__row {
      display: flex;
      flex-direction: row;
      align-items: flex-end;

      &__header {
        position: sticky;
        top: 0px;
        z-index: 1;
        background-color: white;
      }

      &__column {
      }
      &__input {
        padding: 0 8px 0 0;
        :deep() .el-input__inner {
          padding: 0 8px;
        }
        &-invalid {
          :deep() .el-input__inner {
            border: 1px solid $red-mayday;
          }
        }
      }
      &__delete {
        padding-bottom: 16px;
        &__btn {
          &:hover {
            cursor: pointer;
            color: $red-mayday;
          }
        }
      }
    }

    &__add {
      width: fit-content;
    }

    &__submission {
      position: absolute;
      bottom: 0;
      right: 0;
      padding: 12px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }
  }
}
</style>
