<template>
  <modal
    class="content-attributes-modal"
    :show.sync="localDisplay"
    :close-icon="true"
    modal-classes="modal-dialog-centered modal-lg"
  >
    <h3 slot="header" class="m-0">
      {{
        $tc(
          'components.modals.update-content-attributes.modal-title',
          contentQty,
          {
            count: contentQty,
          },
        )
      }}
    </h3>

    <div class="attribute-selection-main">
      <attribute-edition-row
        v-if="computeOptions"
        :option="option"
        :options="computeOptions.options"
        :options-to-delete="computeOptions.optionsToDelete"
        :mainTitle="computeOptions.mainTitle"
        :label-key="computeOptions.labelKey"
        :to-collapse="computeOptions.toCollapse"
        :collapse-key="computeOptions.collapseKey"
        @add="updateToAdd(computeOptions.typeName, $event)"
        @remove="updateToRemove(computeOptions.typeName, $event)"
      >
        <template v-slot:add v-if="option === 'collections' && computeOptions">
          <CaseParameterSelector
            class="case-selection-overflow add-case"
            :knowledge="focusKnowledge"
            @update-case-parameters="
              updateToAdd('caseParameters', $event.caseSelection)
            "
          />
        </template>
        <template
          v-slot:remove
          v-if="option === 'collections' && computeOptions"
        >
          <CaseParameterSelector
            class="case-selection-overflow remove-case"
            :knowledge="focusKnowledge"
            @update-case-parameters="
              updateToRemove('caseParameters', $event.caseSelection)
            "
          />
        </template>
      </attribute-edition-row>

      <!-- EMPTY STATE -->
      <div
        v-else
        class="d-flex flex-column justify-content-center align-items-center"
      >
        <div class="empty-state-img">
          <img class="h-100" src="~assets/empty-states/empty-state.svg" />
        </div>
        <div class="empty-state-txt">
          <div class="empty-state-title mb-2">
            {{
              $t(
                'components.modals.update-content-attributes.labels-empty-state.title',
              )
            }}
          </div>
          <span class="empty-state-subtitle">{{
            $t(
              'components.modals.update-content-attributes.labels-empty-state.explanation',
            )
          }}</span>
          <span class="empty-state-subtitle mb-3">{{
            $t(
              'components.modals.update-content-attributes.labels-empty-state.redirection',
            )
          }}</span>
        </div>
        <el-button @click="redirectToSettings" type="primary">
          {{
            $t(
              'components.modals.update-content-attributes.labels-empty-state.button',
            )
          }}</el-button
        >
      </div>
    </div>

    <!-- FOOTER -->

    <div slot="footer" class="advanced-search-modal-footer">
      <base-button-old
        class="ml-2"
        type="link"
        outline
        size="sm"
        @click="$emit('close')"
      >
        {{ $t('components.modals.knowledge-migration.cancel') }}
      </base-button-old>

      <base-button-old
        class="mr-2"
        type="success"
        size="sm"
        @click="submitUpdates"
        :disabled="!computeOptions"
        >{{
          $t('components.modals.update-content-attributes.modify')
        }}</base-button-old
      >
    </div>
  </modal>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';

// COMPONENTS
import AttributeEditionRow from './AttributeEditionRow';
import CaseParameterSelector from '../ContentViewSettingModal/CaseParameterSelector';

export default {
  name: 'content-attributes-modal',
  components: { AttributeEditionRow, CaseParameterSelector },
  props: {
    display: {
      type: Boolean,
      default: false,
    },
    contentQty: {
      type: Number,
    },
    focusKnowledge: {
      type: String,
    },
    option: {
      type: String,
    },
    labelsToRemove: {
      type: Array,
      default: () => [],
    },
    accessRestrictionsIdsToRemove: {
      type: Array,
      default: () => [],
    },
    accessRestrictionIdsInCommon: {
      type: Array,
      default: () => [],
    },
    labelIdsInCommon: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    hasLabels() {
      return this.labels && this.labels.length;
    },
    enrichAccessRestrictionsToRemove() {
      return this.userGroups.reduce((acc, group) => {
        if (this.accessRestrictionsIdsToRemove.includes(group.id))
          acc.push(group);
        return acc;
      }, []);
    },
    computeOptions() {
      switch (this.option) {
        case 'labels':
          if (this.hasLabels)
            return {
              options: this.labels.filter(
                (label) => !this.labelIdsInCommon.includes(label.id),
              ),
              optionsToDelete: this.labelsToRemove,
              labelKey: 'labelName',
              typeName: 'categories',
              toCollapse: true,
              collapseKey: 'categoryName',
            };
          return null;
        case 'restrictions':
          if (this.isGranularAccessRestrictions)
            return {
              options: this.userGroups.filter(
                (ac) => !this.accessRestrictionIdsInCommon.includes(ac.id),
              ),
              optionsToDelete: this.enrichAccessRestrictionsToRemove,
              labelKey: 'name',
              typeName: 'accessRestrictions',
              toCollapse: false,
            };
          return null;
        case 'collections':
          if (this.focusKnowledge && this.isParametric)
            return {
              labelKey: 'name',
              typeName: 'accessRestrictions',
              toCollapse: false,
            };
          return null;
        default:
          return null;
      }
    },

    ...mapGetters(['isGranularAccessRestrictions', 'isParametric']),
    ...mapState(['userGroups']),
  },
  data() {
    return {
      localDisplay: this.display,
      labels: [],
      toAdd: [],
      toRemove: [],
    };
  },
  async created() {
    this.labels = await this.fetchContentParameterLabels();
  },
  methods: {
    redirectToSettings() {
      this.$router.push('/settings/knowledges#contents');
    },
    updateToAdd(key, values) {
      this.toAdd = this.toAdd.filter((el) => el.key !== key);
      if (values.length) {
        this.toAdd = this.toAdd.concat([{ key, values }]);
      }
    },
    updateToRemove(key, values) {
      this.toRemove = this.toRemove.filter((el) => el.key !== key);
      if (values.length) {
        this.toRemove = this.toRemove.concat([{ key, values }]);
      }
    },
    submitUpdates() {
      this.$emit('update-attributes', {
        toAdd: this.toAdd,
        toRemove: this.toRemove,
      });
      this.$emit('close');
    },
    ...mapActions('knowledgeModule', ['fetchContentParameterLabels']),
  },
  watch: {
    localDisplay() {
      if (!this.localDisplay) {
        this.$emit('close');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
:deep() .modal-dialog {
  width: 559px;
}

:deep() .modal-content {
  min-height: 60vh;
  max-height: 70vh;
}

:deep() .modal-body {
  display: flex;
  align-items: baseline;
  justify-content: center;
  padding: 16px 16px 0px 16px;
  overflow: auto;
}

:deep() .modal-header {
  background-color: #f9fbff;
  padding: 16px;
}
:deep() .modal-footer {
  background-color: #f9fbff;
  padding: 16px;
}
.attribute-title {
  color: $grey-5-mayday;
  cursor: pointer;
  margin-bottom: 0px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.is-checked {
  .attribute-title {
    color: $blue-mayday;
  }
}

.attribute-selection-main {
  max-height: 70vh;
  width: 100%;
}

.case-selection-overflow {
  max-height: 25vh;
}

.add-case {
  :deep() .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #67c23a;
  }
}

.remove-case {
  :deep() .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #f56c6c;
  }
}

.hover:hover {
  color: $blue-mayday;
}

.empty-state-img {
  height: 100px;
}

.empty-state-txt {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 15px 30px;
}

.empty-state-title {
  font-size: 16px;
  font-weight: 700;
}
.empty-state-subtitle {
  font-size: 14px;
  color: $grey-6-mayday;
}
</style>
