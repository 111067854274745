<template>
  <div class="delete-modal">
    <modal :show.sync="localDisplay">
      <div class="row d-flex mt-3 justify-content-center">
        <div class="col-11 text-center">
          <modal-header
            :style-name="{ backgroundColor: '#fd00440D' }"
            class="mb-3"
          >
            <div slot="icon" class="d-inline-flex">
              <font-awesome-icon
                class="icon red-mayday"
                :icon="['fad', 'siren']"
              />
            </div>
            <div class="mt-3">
              <div>
                <p class="mb-0 font-weight-bold">{{ content.title }}</p>
              </div>
              <el-divider class="my-3"></el-divider>
              <p class="">
                {{ content.description }}
              </p>
              <small class="mb-0 text-danger">{{ content.warning }}</small>
            </div>
          </modal-header>
        </div>
      </div>
      <div slot="footer" class="col-12 d-flex justify-content-end">
        <base-button
          variant="secondary"
          size="md"
          class="mr-1"
          @click="userChoice(false)"
          :text="content.ctas.cancel"
        ></base-button>
        <base-button
          variant="danger"
          size="md"
          @click="userChoice(true)"
          :loading="isLoading"
          :text="content.ctas.validate"
        >
        </base-button>
      </div>
    </modal>
  </div>
</template>
<script>
// import Modal from '@/components/Modal';
import ModalHeader from '@/components/Modals/ModalHeader';
import Modal from '@/components/Modal';

export default {
  name: 'delete-modal',
  props: {
    type: String,
    display: Boolean,
    value: String,
  },
  components: {
    Modal,
    ModalHeader,
  },
  computed: {
    content() {
      return this.contents[this.type];
    },
  },
  data() {
    return {
      contents: {
        step: {
          title: this.$t('components.modals.delete-modal.step.title'),
          description: this.$t(
            'components.modals.delete-modal.step.description',
          ),
          warning: this.$t('components.modals.delete-modal.warning-noreturn'),
          ctas: {
            validate: this.$t('components.modals.delete-modal.ctas.validate'),
            cancel: this.$t('components.modals.delete-modal.ctas.cancel'),
          },
        },
        // to-do: translate
        steps: {
          title: `Suppression ${
            this.value ? `de ${this.value} éléments` : 'de plusieurs éléments'
          }`,
          description: `Vous êtes sur le point de supprimer définitivement ${
            this.value ? `${this.value} éléments` : 'plusieurs éléments'
          }`,
          warning:
            '⚠️ Etes-vous sûr de vouloir continuer ? Cette action est irréversible ⚠️',
          ctas: {
            validate: 'Supprimer',
            cancel: 'Annuler',
          },
        },
        magicAnswer: {
          title: this.$t(
            'knowledge.diagnostic-builder.side-bar.magic-answer.modal.title',
          ),
          description: this.$t(
            'knowledge.diagnostic-builder.side-bar.magic-answer.modal.description',
          ),
          warning: this.$t(
            'knowledge.diagnostic-builder.side-bar.magic-answer.modal.alert',
          ),
          ctas: {
            validate: this.$t('knowledge.actions.delete'),
            cancel: this.$t('knowledge.actions.cancel'),
          },
        },
        userGroup: {
          title: this.$t('components.modals.delete-modal.user-group.title'),
          description: this.$t(
            'components.modals.delete-modal.user-group.description',
          ),
          warning: this.$t('components.modals.delete-modal.warning-noreturn'),
          ctas: {
            validate: this.$t('components.modals.delete-modal.ctas.validate'),
            cancel: this.$t('components.modals.delete-modal.ctas.cancel'),
          },
        },
        user: {
          title: this.$t('components.modals.delete-modal.user.title'),
          description: this.$t(
            'components.modals.delete-modal.user.description',
          ),
          warning: this.$t('components.modals.delete-modal.warning-noreturn'),
          ctas: {
            validate: this.$t('components.modals.delete-modal.ctas.validate'),
            cancel: this.$t('components.modals.delete-modal.ctas.cancel'),
          },
        },
        role: {
          title: this.$t('components.modals.delete-modal.role.title'),
          description: this.$t(
            'components.modals.delete-modal.role.description',
          ),
          warning: this.$t('components.modals.delete-modal.warning-basic'),
          ctas: {
            validate: this.$t(
              'components.modals.delete-modal.role.ctas.validate',
            ),
            cancel: this.$t('components.modals.delete-modal.ctas.cancel'),
          },
        },
        archives: {
          title: this.$t('components.modals.delete-modal.archives.title'),
          description: this.$t(
            'components.modals.delete-modal.archives.description',
          ),
          warning: this.$t('components.modals.delete-modal.warning-noreturn'),
          ctas: {
            validate: this.$t('components.modals.delete-modal.ctas.validate'),
            cancel: this.$t('components.modals.delete-modal.ctas.cancel'),
          },
        },
        rules: {
          title: this.$t('components.modals.delete-modal.rules.title'),
          description: this.$t(
            'components.modals.delete-modal.rules.description',
          ),
          warning: this.$t('components.modals.delete-modal.warning-noreturn'),
          ctas: {
            validate: this.$t('components.modals.delete-modal.ctas.validate'),
            cancel: this.$t('components.modals.delete-modal.ctas.cancel'),
          },
        },
        case_parameter: {
          title: this.$t('components.modals.delete-modal.rules.title'),
          description: this.$t(
            'components.modals.delete-modal.rules.description',
          ),
          warning: this.$t('components.modals.delete-modal.warning-noreturn'),
          ctas: {
            validate: this.$t('components.modals.delete-modal.ctas.validate'),
            cancel: this.$t('components.modals.delete-modal.ctas.cancel'),
          },
        },
      },
      localDisplay: this.display,
      isLoading: false,
    };
  },
  methods: {
    userChoice(choice) {
      this.isLoading = true;
      this.$emit('choice', choice);
    },
  },
  watch: {
    localDisplay() {
      if (!this.localDisplay) this.$emit('close');
    },
  },
};
</script>
<style lang="scss" scoped>
.delete-modal {
  :deep() .modal-body {
    padding-bottom: 0px;
  }
}
.content-primary-text-container {
  padding-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 4px;
  background-color: rgba($blue-mayday, 0.05);
}

.red-mayday {
  color: $red-mayday;
}

.icon {
  width: 26px;
  height: 26px;
}
</style>
