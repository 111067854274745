<template>
  <el-popover
    placement="bottom"
    width="300"
    trigger="click"
    popper-class="popover-container"
  >
    <div class="collapse-wrapper">
      <el-collapse accordion @change="resetSearch">
        <el-collapse-item v-for="filter in currentFilters" :key="filter.key">
          <template slot="title">
            <div class="collapse-title">
              <font-awesome-icon
                v-if="filter.icon"
                :icon="filter.icon"
                class="filter-icon"
              />
              {{ filter.label }}
              <el-badge
                :value="getFilterCount(filter.key)"
                :hidden="
                  currentFilters[filter.key].values.length < 1 ||
                  currentFilters[filter.key].values === false
                "
                class="collapse-badge"
              ></el-badge>
            </div>
          </template>
          <div v-if="filter.grouped">
            <div v-if="filter.searchable">
              <el-input
                :placeholder="$t('task-view.filter.search')"
                class="mr-2 search"
                @input="handleSearch(filter.key, $event)"
                @clear="resetSearch"
                :value="search[1]"
                clearable
              >
                <i slot="prefix" class="el-input__icon" v-if="!search[1]"
                  ><font-awesome-icon
                    :icon="['fal', 'search']"
                    class="search-icon"
                  ></font-awesome-icon
                ></i>
              </el-input>
              <p
                class="empty-message"
                v-if="search[1] && filter.options.length === 0"
              >
                {{ $t('task-view.filter.empty-search', { query: search[1] }) }}
              </p>
            </div>
            <div v-for="(group, i) in filter.options" :key="i">
              <div class="option-group-title">
                <ColoredIcon
                  class="option-icon"
                  :icon="group.icon"
                  :color="group.color"
                />
                {{ group.label }}
              </div>
              <component
                v-model="filter.values"
                @change="handleChangeFilter($event, filter)"
                class="checkbox-group"
                data-test-id="checkbox-group"
                :is="`el-${filter.input}-group`"
              >
                <div
                  class="checkbox-wrapper"
                  v-for="option in group.options"
                  :key="option.value"
                >
                  <component
                    :is="`el-${filter.input}`"
                    :label="option.value"
                    :value="option.value"
                    data-test-id="checkbox"
                  >
                    <div class="checkbox-label">
                      <div
                        class="initials-wrapper"
                        :style="colorStyle(option.label)"
                        v-if="group.dataType === 'user'"
                      >
                        <div class="initials">
                          {{ getInitials(option.label) }}
                        </div>
                      </div>
                      {{ option.label }}
                    </div>
                  </component>
                </div>
              </component>
            </div>
          </div>
          <div v-if="!filter.grouped && filter.input !== 'date'">
            <div v-if="filter.searchable">
              <el-input
                :placeholder="$t('task-view.filter.search')"
                class="mr-2 search"
                @input="handleSearch(filter.key, $event)"
                @clear="resetSearch"
                :value="search[1]"
                clearable
              >
                <i slot="prefix" class="el-input__icon" v-if="!search[1]"
                  ><font-awesome-icon
                    :icon="['fal', 'search']"
                    class="search-icon"
                  ></font-awesome-icon
                ></i>
              </el-input>
              <p
                class="empty-message"
                v-if="search[1] && filter.options.length === 0"
              >
                {{ $t('task-view.filter.empty-search', { query: search[1] }) }}
              </p>
            </div>
            <component
              v-model="filter.values"
              @change="handleChangeFilter($event, filter)"
              data-test-id="checkbox-group"
              :is="
                filter.input !== 'switch' ? `el-${filter.input}-group` : 'div'
              "
            >
              <div
                v-for="option in filter.options"
                :key="option.value"
                class="checkbox-wrapper"
              >
                <component
                  :label="option.value"
                  :value="option.value"
                  data-test-id="checkbox"
                  :is="`el-${filter.input}`"
                  v-if="filter.input !== 'switch'"
                >
                  <div class="checkbox-label">
                    <div
                      class="initials-wrapper"
                      :style="colorStyle(option.label)"
                      v-if="filter.dataType === 'user'"
                    >
                      <div class="initials">
                        {{ getInitials(option.label) }}
                      </div>
                    </div>
                    {{ option.label }}
                  </div>
                </component>
                <el-switch
                  v-else
                  :inactive-text="option.label"
                  v-model="filter.values"
                  @change="handleChangeFilter($event, filter)"
                />
              </div>
            </component>
          </div>
          <div v-if="filter.input === 'date'" class="calendar-container">
            <el-date-picker
              class="mb-2"
              type="date"
              v-model="filter.values"
              @change="handleChangeFilter($event, filter)"
              value-format="timestamp"
            />
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
    <div class="flex justify-end">
      <base-button
        icon="sync"
        variant="tertiary"
        size="md"
        :text="$t('task-view.filter.reset')"
        :disabled="filtersTotal < 1 && search[1] === ''"
        @click="handleReset"
      ></base-button>
    </div>

    <base-button
      icon="sliders-v"
      :text="$t('task-view.filter.cta')"
      :variant="filtersTotal < 1 ? 'secondary' : 'primary'"
      size="md"
      ref="openFiltersButton"
      slot="reference"
    ></base-button>
  </el-popover>
</template>

<script>
import ColoredIcon from '@/components/Icons/ColoredIcon.vue';
import cloneDeep from 'lodash.clonedeep';

export default {
  name: 'filters-popover',
  components: { ColoredIcon },

  inheritAttrs: false,

  props: {
    filters: {
      type: Object,
      required: true,
    },
    selectedFilters: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
    },
  },

  data() {
    return {
      currentFilters: {},
      filtersTotal: 0,
      search: ['', ''],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
    };
  },

  async created() {
    await this.parseFilters();
    this.filtersTotal = Object.keys(this.currentFilters).reduce(
      (acc, filter) => {
        return acc + this.currentFilters[filter].values.length;
      },
      0,
    );
  },

  watch: {
    selectedFilters() {
      this.filtersTotal = 0;
      Object.keys(this.currentFilters).forEach((filter) => {
        if (this.currentFilters[filter].input === 'date') {
          this.currentFilters[filter].values = '';
        } else {
          this.currentFilters[filter].values = [];
        }
      });
      this.selectedFilters.forEach((filter) => {
        if (filter.values.length) {
          if (!this.currentFilters[filter.key]) {
            this.currentFilters[filter.key] = {};
          }
          this.currentFilters[filter.key].values = filter.values;
          this.filtersTotal = this.getTotalFilters();
        }
      });
    },
    filters: {
      handler() {
        this.parseFilters();
      },
      deep: true,
    },
    search(newSearch, oldSearch) {
      if (newSearch[1].length > 2) {
        if (this.currentFilters[newSearch[0]].grouped) {
          let newOptions = this.currentFilters[newSearch[0]].options.map(
            (group) => {
              return {
                ...group,
                options: group.options.filter((option) =>
                  option.label
                    .toLowerCase()
                    .includes(newSearch[1].toLowerCase()),
                ),
              };
            },
          );
          newOptions = newOptions.filter((group) => group.options.length);
          this.currentFilters[newSearch[0]].options = newOptions;
        } else {
          this.currentFilters[newSearch[0]].options = this.currentFilters[
            newSearch[0]
          ].options.filter((option) => {
            return option.label
              .toLowerCase()
              .includes(this.search[1].toLowerCase());
          });
        }
      } else if (oldSearch && oldSearch[1].length > 2) {
        this.currentFilters[oldSearch[0]].options =
          this.filters[oldSearch[0]].options;
      }
    },
  },

  methods: {
    handleChangeFilter(values, filter) {
      if (filter.input === 'date') {
        values = values ? String(values) : '';
      }
      this.currentFilters[filter.key].values = values;
      this.filtersTotal = this.getTotalFilters();
      this.$emit('update-filter', {
        key: filter.key,
        path: filter.path || filter.key,
        values: values,
        type: filter.type || 'Array',
        operator: filter.operator || null,
      });
    },
    handleSearch(key, $event) {
      this.search = [key, $event];
    },
    handleReset() {
      Object.keys(this.currentFilters).forEach((i) => {
        if (this.currentFilters[i].input === 'date') {
          this.currentFilters[i].values = '';
        } else {
          this.currentFilters[i].values = [];
        }
      });
      this.resetSearch();
      this.filtersTotal = 0;
      this.$emit('empty-filters-search');
    },
    async parseFilters() {
      this.currentFilters = cloneDeep(this.filters);
      Object.keys(this.currentFilters).forEach((key) => {
        this.currentFilters[key].options = this.currentFilters[
          key
        ].options.sort((a, b) => {
          if (!a.label || !b.label) return 0;
          return a.label.localeCompare(b.label);
        });
        if (this.currentFilters[key].grouped) {
          this.currentFilters[key].options.forEach((group) => {
            group.options = group.options.sort((a, b) => {
              if (!a.label || !b.label) return 0;
              return a.label.localeCompare(b.label);
            });
          });
        }
      });
      this.selectedFilters.forEach((filter) => {
        if (filter.values.length) {
          if (!this.currentFilters[filter.key]) {
            this.currentFilters[filter.key] = {};
          }
          this.currentFilters[filter.key].values = filter.values;
        }
      });
    },
    resetSearch() {
      if (this.search[1] !== '') {
        this.currentFilters[this.search[0]].options =
          this.filters[this.search[0]].options;
        this.search = ['', ''];
      }
    },
    getFilterCount(filter) {
      if (this.currentFilters[filter].input === 'date') {
        return this.currentFilters[filter].values ? 1 : 0;
      } else if (this.currentFilters[filter].values === true) {
        return 1;
      } else if (this.currentFilters[filter].input === 'radio') {
        return this.currentFilters[filter].values.length > 0 ? 1 : 0;
      } else {
        return this.currentFilters[filter].values.length;
      }
    },
    getTotalFilters() {
      return Object.keys(this.currentFilters).reduce((acc, filter) => {
        return acc + this.getFilterCount(filter);
      }, 0);
    },
    userColor(str, s, l) {
      let hash = 0;
      for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }
      const h = hash % 360;
      return `hsl(${h},${s}%,${l}%)`;
    },
    colorStyle(username) {
      return {
        background:
          username !== null && username !== '?'
            ? this.userColor(username, 70, 50)
            : '#0081f9',
        color: 'white',
      };
    },
    getInitials(username) {
      if (!username) return '';

      const fullName = username.split(' ');
      let initials;
      if (fullName.length >= 2)
        initials = fullName.shift().charAt(0) + fullName.pop().charAt(0);
      else initials = fullName.shift().charAt(0);
      return initials.toUpperCase();
    },
  },
};
</script>

<style lang="scss" scoped>
.popover-button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.filters-open-link {
  color: $blue-mayday;
  height: 34px;
  display: inline-flex;
  gap: 4px;
  padding: 10px 15px;
  align-items: center;
  justify-content: center;
  background-color: rgba($blue-mayday, 0.1);
  border-radius: 4px;
  border: 1px solid $blue-mayday;
  transition: min-width 0.2s ease-in-out;
  font-size: 14px;

  .dashboard-filters-icon {
    margin-right: 4px;
  }

  &:hover {
    background-color: $blue-mayday;
    color: white;
    cursor: pointer;
  }

  &:disabled {
    background-color: rgba($grey-8-mayday, 0.1);
    color: $grey-8-mayday;
    border: none;
    cursor: progress;
  }

  :deep() .el-badge__content {
    background-color: white;
    color: $blue-mayday;
    border-color: $blue-mayday;
    display: flex;
    top: 0;
    align-items: center;
    justify-content: center;
  }
}

.option-group-title {
  display: inline-flex;
  align-items: center;
  gap: 4px;
  font-size: 14px;
}

.checkbox-group-wrapper {
  max-height: 50px;
  overflow-y: auto;
}

.collapse-wrapper :deep() {
  .el-collapse {
    border: none;
  }
  .el-collapse-item__arrow {
    color: black;
  }
  .el-collapse-item__header {
    border: none;
    padding-left: 8px;

    .collapse-title {
      font-family: 'Lato', serif;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 14px;
      letter-spacing: 0.02em;
      align-items: center;
      display: inline-flex;
      gap: 8px;
    }

    .collapse-badge .el-badge__content {
      background-color: $blue-mayday;
      color: white;
      display: flex;
      top: 0;
      align-items: center;
      justify-content: center;
    }
  }

  .el-collapse-item__content {
    float: right;
    width: 100%;
    padding-left: 12px;
    padding-bottom: 0px !important;
  }

  .el-collapse-item__wrap {
    border: none;
  }

  .el-switch {
    width: 100%;
    justify-content: space-between;
    .el-switch__label.is-active {
      color: $grey-8-mayday;
    }
    .el-switch__label {
      color: $blue-mayday;
    }
  }

  .el-checkbox-group,
  .el-radio-group {
    max-height: 300px;
    overflow-y: auto;
  }
}

.initials-wrapper {
  width: 20px;
  height: 20px;
  border-radius: 2px;
  font-size: 12px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
}

.initials {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

:deep() {
  .el-radio-group {
    width: 100%;
  }
}

.checkbox-wrapper {
  display: block;
  width: 100%;
  border-bottom: 1px solid $grey-2-mayday;
  &:last-of-type {
    border-bottom: none;
  }

  .checkbox-label {
    font-family: 'Lato', serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.02em;
    padding: 0px;
    display: inline-flex;
    align-items: center;
    gap: 8px;
  }

  :deep() {
    .el-checkbox,
    .el-radio {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center;
      padding: 8px 8px 8px 4px;
      margin: 0;
      &:hover {
        background-color: $grey-1-mayday;
      }
    }
    .el-checkbox__label,
    .el-radio__label {
      padding: 0;
      font-size: 12px;
    }
  }
}

.reset-button {
  color: $grey-7-mayday;
  border: none;
  float: right;
  font-family: 'Lato', serif;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  padding-right: 12px;
  padding-left: 12px;
  letter-spacing: 0.02em;

  .reset-button-label {
    margin-left: 8px;
  }

  &:disabled {
    color: $grey-4-mayday;
    cursor: not-allowed;
  }
}

.filter-icon {
  width: 16px;
}

.search-icon:hover {
  cursor: pointer;
  color: $blue-mayday;
}

.search {
  height: 34px;
  width: 100%;
  margin-bottom: 8px;
  :deep() .el-input__inner {
    height: 100%;
  }
}

.empty-message {
  font-family: 'Lato', serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.02em;
  color: $grey-7-mayday;
  margin-top: 8px;
  margin-bottom: 8px;
}

:deep() .el-input__icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.calendar-container {
  position: relative;
  :deep() .el-date-editor {
    width: 100%;
  }
  .calendar-clear-button {
    position: absolute;
    right: 8px;
    top: 10px;
    width: 20px;
    height: 20px;
    color: $grey-7-mayday;
    z-index: 10;
    cursor: pointer;
    &:hover {
      color: $blue-mayday;
    }
  }
}
</style>
