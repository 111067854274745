import { v4 as uuid } from 'uuid';

export class BaseAiSession {
  constructor({ name }) {
    this.id = uuid();
    this.name = name;
    this.currentTask = 'init';
  }

  resetAiSession() {
    this.currentTask = 'init';
    this.pollingConfig = undefined;
    this.streamingConfig = undefined;
  }

  processAiSessionResponseNext(res) {
    if (!res.next) return;
    const { responseType } = res.next;
    if (!responseType) return;
    if (responseType != this.currentTask) this.currentTask = responseType;
    switch (responseType) {
      case 'poll':
        this.pollingConfig = res.next;
        break;
      case 'stream':
        this.streamingConfig = res.next;
        break;
      default:
        throw new Error(`INVALID_AI_SESSION_RESPONSE_TYPE_${responseType}`);
    }
  }

  // eslint-disable-next-line no-unused-vars
  async _pingFileUpload(_args) {
    throw new Error('AI_SESSION_MISSING_PING_FILE_UPLOAD_METHOD');
  }

  async pingFileUpload(args) {
    await this._pingFileUpload(args);
  }

  // eslint-disable-next-line no-unused-vars
  async _trigger(_args) {
    throw new Error('AI_SESSION_MISSING_TRIGGER_METHOD');
  }

  async trigger(args) {
    const res = await this._trigger(args);
    return this.processAiSessionResponseNext(res.data);
  }

  // eslint-disable-next-line no-unused-vars
  async _getStatus(_args) {
    throw new Error('AI_SESSION_MISSING_GET_STATUS_METHOD');
  }

  async getStatus(args) {
    const res = await this._getStatus(args);
    return res.data;
  }

  async pollStatus({ onStatusChangeCallback, status, frequency = 1000 }) {
    if (
      !this.pollingConfig ||
      !this.pollingConfig.taskId ||
      !this.pollingConfig.token ||
      !onStatusChangeCallback
    )
      throw new Error('INVALID_POLLER_PARAMS');

    try {
      const res = await this.getStatus(this.pollingConfig);

      if (!res || !res.status) throw new Error('INVALID_STATUS_RESPONSE');

      if (onStatusChangeCallback && res.status !== status) {
        onStatusChangeCallback(res, { status });
      }

      if (['done', 'failed'].includes(res.status)) return;

      await new Promise((resolve) => setTimeout(resolve, frequency));

      await this.pollStatus({
        onStatusChangeCallback,
        status: res.status,
        frequency,
      });
    } catch (err) {
      console.log(err);
      onStatusChangeCallback({ status: 'failed' }, { status });
      return;
    }
  }

  async poll({ onStatusChangeCallback }) {
    onStatusChangeCallback(
      { source: 'poll', status: 'init', next: this.pollingConfig },
      { status: 'init' },
    );
    return this.pollStatus({
      onStatusChangeCallback: (res, payload) => {
        switch (res.status) {
          case 'done':
            this.processAiSessionResponseNext(res);
            break;
          default:
            break;
        }
        onStatusChangeCallback(res, payload);
      },
      status: 'init',
      frequency: 1000,
    });
  }

  _stream() {
    throw new Error('AI_SESSION_MISSING_STREAM_METHOD');
  }

  stream() {
    if (!this.streamingConfig) throw new Error('ERROR');
    return this._stream();
  }
}
