<template>
  <div class="mt-5 mb-4">
    <div class="title-container">
      <div class="translation-container">
        <TranslationDropdown
          v-if="focusKnowledgeIsMultilinguale"
          class="ml-2"
          :editable="!isArchive"
          :set-languages="content.setLanguages"
          :label-translations="content.labelTranslations"
          :status-translations="contentStatus"
          :rightDirectionDropdown="true"
          :loadingLanguages="loadingLanguages"
          :translation-dropdown-loading="translationDropdownLoading"
          :has-staged-version="hasStagedVersion"
          @set-new-language="$emit('open-set-language-modal', $event)"
          @unset-language="$emit('unset-language', $event)"
        />
        <div
          class="update-translate"
          @click="$emit('update-translations')"
          v-if="displayUpdateTranslations"
        >
          <font-awesome-icon :icon="['fas', 'sync']" />
          {{ $t('translation.modal.update-translations.button') }}
        </div>
      </div>
      <div
        :class="['content-title', titleClass]"
        class="col-lg-8 col-md-10 col-sm-12"
      >
        <ReturnButton v-if="displayOnWebParametric" />
        <div v-if="upperTitleText || isTemplate">
          <textarea
            ref="description"
            rows="3"
            class="upper-title-text-input"
            v-if="updateUpperText"
            v-model="newUpperText"
            autofocus
            @blur="updateUpperText = false"
            @keyup.esc="$event.target.blur()"
            @change="changeUpperText($event.target.value)"
          />
          <div
            v-else
            class="upper-title-text text-muted"
            @dblclick="updateUpperText = true"
          >
            {{
              upperTitleText ||
              $t('knowledge.templates.import.empty-description')
            }}
          </div>
        </div>
        <NodeTitleEditor
          :label="isStagedContent ? content.stagedData.label : content.label"
          :content="content"
          :node-type="content.type"
          :group-reviewers="content.groupReviewers"
          :editable="editable && canUpdateContent"
          :is-template="isTemplate"
          @update-label="updateContent"
        />
        <div
          class="d-flex align-items-start justify-content-between mb-2 flex-wrap"
          v-if="content.type !== 'Folder'"
        >
          <ContentDetails
            :author="content.enrichedAuthor"
            :archiveAuthor="archiveAuthor"
            :contributors="contributors"
            :total="total"
            :updated-at="createdAt"
            :created-at="content.createdAt"
            :step-id="stepId !== content.id ? stepId : null"
            @click="$emit('open-content-settings', $event)"
            :isArchive="isArchive"
          />
          <!-- FIRST ROW -->
          <div
            class="d-flex flex-row justify-content-end gap-2 align-items-center ml-1 pt-1"
            v-if="!isStagedContent"
          >
            <not-allowed-tooltip
              v-if="editable && !isTemplate"
              :user-permissions="userPermissions"
              permission-key="canUpdateContent"
              placement="top"
            >
              <template v-slot:main-content="{ hasPermissions }">
                <PublicationPolicyButton
                  :publication-date="
                    content.publicationPolicy
                      ? content.publicationPolicy.publicationDueDate
                      : null
                  "
                  :published="isPublished"
                  :disabled="!hasPermissions"
                  :has-staged-version="hasStagedVersion"
                  @publish="
                    hasPermissions
                      ? updateContent({ published: !isPublished })
                      : ''
                  "
                  @update-display-modal="
                    hasPermissions ? openPublicationPolicyModal() : ''
                  "
                  @create-staged-version="$emit('create-staged-version')"
                  @show-staged-version="$emit('show-staged-version')"
                />
              </template>
            </not-allowed-tooltip>

            <not-allowed-tooltip
              v-if="editable && !isTemplate"
              :user-permissions="userPermissions"
              permission-key="canVerifyContent"
              placement="top"
            >
              <template v-slot:main-content="{ hasPermissions }">
                <VerificationPolicyButton
                  :verificationPolicy="content.verificationPolicy || {}"
                  :isOutdated="content.isOutdated"
                  :contentId="content.id"
                  :reviewers="reviewers"
                  :disabled="!hasPermissions"
                  @set-content-trust="
                    hasPermissions ? $emit('set-content-trust', $event) : ''
                  "
                  @update-display-modal="
                    hasPermissions ? openVerificationPolicyModal() : ''
                  "
                />
              </template>
            </not-allowed-tooltip>
          </div>

          <!-- PUBLISH STAGED VERSION -->
          <button
            v-else
            class="btn btn-primary btn-sm flex-none my-1"
            @click="$emit('publish-staged-version')"
          >
            {{ $t('knowledge.staged-version.publish') }}
          </button>
        </div>
        <div
          class="d-flex align-items-center full-labels"
          v-if="content.labels && content.labels.length"
        >
          <ContentListItemFullLabelWrapper :labels="content.labels" />
        </div>
        <div v-if="showConcepts">
          <ConceptList @update-concepts="handleConceptsUpdate" />
        </div>
        <FolderChildrenCount
          class="d-flex collection-composition"
          :folder="content"
          v-if="content.type === 'Folder'"
        />
        <!-- SECOND ROW -->
        <div class="d-flex align-items-start" v-if="showBacklinks">
          <BacklinkButton :backlinks="backlinks" />
        </div>
      </div>
    </div>

    <ModalEvents
      :modal-name="modalName"
      :uid="mainTitleUniqueKey"
      @create-verification-policy="upsertVerificationPolicy"
      @update-verification-policy="upsertVerificationPolicy"
      @delete-verification-policy="deleteVerificationPolicy"
      @upsert-publication-policy="upsertPublicationPolicy"
      @delete-publication-policy="deletePublicationPolicy"
    />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import ReturnButton from './ReturnButton';
import BacklinkButton from './BacklinkButton';
import FolderChildrenCount from './FolderChildrenCount';
import NodeTitleEditor from './NodeTitleEditor';
import VerificationPolicyButton from './VerificationPolicyButton';
import PublicationPolicyButton from './PublicationPolicyButton';
import NotAllowedTooltip from '@/components/Commons/NotAllowedTooltip';
import TranslationDropdown from '@/components/TranslationDropdownWrapper';
import ContentListItemFullLabelWrapper from '@/components/ListItems/ContentListItemFullLabelWrapper';
import ConceptList from '@/components/ListItems/ConceptList';
import ContentDetails from 'components/ContentDetails.vue';
import ModalEvents from '@/components/Modals/ModalEvents';

export default {
  name: 'knowledge-editor-main-title',
  components: {
    ContentDetails,
    FolderChildrenCount,
    NodeTitleEditor,
    VerificationPolicyButton,
    PublicationPolicyButton,
    ReturnButton,
    BacklinkButton,
    NotAllowedTooltip,
    TranslationDropdown,
    ContentListItemFullLabelWrapper,
    ConceptList,
    ModalEvents,
  },
  data() {
    return {
      trustIconClass: 'fa-check-circle',
      untrustIconClass: 'fa-times-circle',
      isLabelsSettingModalOpen: false,
      updateUpperText: false,
      newUpperText: this.upperTitleText,
      modalName: '',
    };
  },
  props: {
    content: Object,
    contributors: {
      type: Array,
      default: () => [],
    },
    stepId: {
      type: String,
      default: null,
    },
    total: {
      type: Number,
      default: 0,
    },
    backlinks: {
      type: Array,
      default: () => [],
    },
    editable: Boolean,
    upperTitleText: {
      type: String,
      default: null,
    },
    archiveAuthor: {
      type: String,
      default: null,
    },
    createdAt: {
      type: String,
      default: null,
    },
    isTemplate: {
      type: Boolean,
      default: false,
    },
    isArchive: {
      type: Boolean,
      default: false,
    },
    loadingLanguages: {
      type: Array,
      default: () => [],
    },
    translationDropdownLoading: {
      type: Boolean,
      default: false,
    },
    displayUpdateTranslations: {
      type: Boolean,
      default: false,
    },
    isStagedContent: {
      type: Boolean,
      default: false,
    },
    hasStagedVersion: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    mainTitleUniqueKey() {
      return `${this.$options.name}-title-modal-${this.content.id}`;
    },
    displayOnWebParametric() {
      return this.$route.fullPath.includes('web-parametric');
    },
    verificationPolicy() {
      return this.content.verificationPolicy;
    },
    ...mapGetters([
      'userAllowConceptsPreference',
      'companyAllowConceptsPreference',
    ]),
    showConcepts() {
      return (
        this.userAllowConceptsPreference &&
        this.companyAllowConceptsPreference &&
        !this.isStagedContent
      );
    },
    reviewerOptions() {
      return [
        {
          label: 'user',
          options: this.content.reviewers
            ? this.content.reviewers.map((reviewer) => ({
                label:
                  reviewer.username === this.username
                    ? `${this.$t(
                        'components.modals.verification-policy.reviewer.you',
                      )} (${reviewer.username})`
                    : reviewer.username,
                value: {
                  type: 'user',
                  value: reviewer.username,
                  id: reviewer.id,
                },
              }))
            : [],
        },
        {
          label: 'role',
          options: this.content.reviewers
            ? this.content.groupReviewers.map((groupReviewer) => ({
                label: groupReviewer.name,
                value: {
                  type: 'role',
                  value: groupReviewer.name,
                  id: groupReviewer.id,
                },
              }))
            : [],
        },
      ];
    },
    titleClass() {
      let titleClass = '';
      switch (this.content.type) {
        case 'Article':
          titleClass = 'article-title';
          break;
        case 'Diagnostic':
          titleClass = 'diag-title';
          break;
        default:
          titleClass = 'folder-title';
          break;
      }
      return titleClass;
    },
    isPublished() {
      return this.content.published;
    },
    createdDate() {
      try {
        return this.content.createdAt.includes('-')
          ? new Date(Date.parse(this.content.createdAt)).toLocaleDateString(
              'fr-FR',
            )
          : new Date(parseInt(this.content.createdAt, 10)).toLocaleDateString(
              'fr-FR',
            );
      } catch {
        return new Date().toLocaleDateString('fr-FR');
      }
    },
    userPermissions() {
      return this.content.userPermissions || {};
    },
    canUpdateContent() {
      // TEMP UNTIL PERMISSIONS ON TEMPLATE
      if (this.isTemplate) return true;
      return (
        this.userPermissions && Boolean(this.userPermissions.canUpdateContent)
      );
    },
    showBacklinks() {
      return this.backlinks && this.backlinks.length && !this.isStagedContent;
    },
    contentStatus() {
      return this.content.publishedTranslations;
    },
    // Exceptionally we use mapState as these data are not
    // related with the knowledge
    ...mapState(['username']),
    ...mapGetters(['isParametric', 'hasCompanyPreferenceWithValue']),
    ...mapGetters('knowledgeModule', ['focusKnowledgeIsMultilinguale']),
    ...mapState('adminModule', ['reviewers']),
  },
  methods: {
    handleConceptsUpdate() {
      console.log('Do something');
    },
    updateContent(payload) {
      this.$emit('update-content', {
        id: this.content.id,
        payload,
        displayLoader: true,
        source: 'root',
      });
    },
    updateSettings(payload) {
      this.$emit('update-settings', {
        id: this.content.id,
        payload,
      });
    },
    upsertVerificationPolicy(payload) {
      payload.contentId = this.content.id;
      this.$emit('upsert-verification-policy', payload);
    },
    deleteVerificationPolicy() {
      this.$emit('delete-verification-policy', { contentId: this.content.id });
    },
    upsertPublicationPolicy(payload) {
      payload.contentId = this.content.id;
      this.$emit('upsert-publication-policy', payload);
    },
    deletePublicationPolicy() {
      this.$emit('delete-publication-policy', { contentId: this.content.id });
    },
    changeUpperText(payload) {
      this.$emit('update-description', payload);
      this.newUpperText = payload;
      this.updateUpperText = false;
    },
    openPublicationPolicyModal() {
      this.modalName = 'ContentPublicationPolicyModal';
      const payload = {
        component: 'ContentPublicationPolicyModal',
        uid: this.mainTitleUniqueKey,
        props: {
          title: this.content.title,
          display: true,
          publicationDueDate: this.content.publicationPolicy
            ? this.content.publicationPolicy.publicationDueDate
            : null,
        },
      };
      this.openModal(payload);
    },
    openVerificationPolicyModal() {
      this.modalName = 'ContentVerificationPolicyModal';
      const payload = {
        component: 'ContentVerificationPolicyModal',
        uid: this.mainTitleUniqueKey,
        props: {
          title: this.content.title,
          display: true,
          username: this.username,
          reviewerOptions: this.reviewerOptions,
          verificationPolicy: this.verificationPolicy || {},
        },
      };
      this.openModal(payload);
    },
    ...mapActions('modalsModule', ['openModal']),
  },
  watch: {
    updateUpperText(newValue) {
      this.newUpperText = this.upperTitleText;
      if (newValue) {
        this.$nextTick(() => {
          const { description } = this.$refs;
          description.focus();
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.title-container {
  width: 100%;
  padding-left: 16.7%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
}

.translation-container {
  padding-left: 10px;
  display: flex;
  flex-direction: row;
  gap: 16px;
}
.update-translate {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px;
  color: $purple-5-mayday;
  font-size: 12px;
  font-weight: 700;
  &:hover {
    cursor: pointer;
  }
}
.content-title {
  border-left: 3px solid $grey-4-mayday;
  width: 100%;
}

.article-title {
  border-color: $yellow-mayday;
}

.diag-title {
  border-color: $blue-mayday;
}

.folder-title {
  border-color: $grey-4-mayday;
}

.content-stamp {
  border: 1px solid;
  font-size: 10px;
  font-weight: 700;
  padding: 2px 4px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.draft {
  color: $grey-5-mayday;
  background-color: $grey-2-mayday;
  border-radius: 2px;
  border-color: 1px solid $grey-4-mayday;
}

.published {
  color: $blue-mayday;
  background-color: rgba($blue-mayday, 0.1);
  border-radius: 2px;
  border: 1px solid $blue-mayday;
}

.upper-title-text {
  white-space: pre-wrap;
}

.upper-title-text-input {
  width: 100%;
  padding: 4px 8px;
  border: 1px solid $grey-2-mayday;
  border-radius: 4px;
}
</style>
