<template>
  <div class="col-12">
    <card :class="isGranularAccessRestrictions ? 'is-parametric' : ''">
      <template slot="header">
        <div class="row justify-content-between">
          <div class="col-11 d-flex text-left">
            <p class="mb-0">{{ knowledge.label }}</p>
          </div>
          <div class="col-1 text-right">
            <input type="checkbox" v-model="check" />
          </div>
        </div>
      </template>

      <div
        v-if="!isGranularAccessRestrictions"
        class="row justify-content-between"
      >
        <div class="col-10 d-flex text-left">
          <p class="mb-0" v-if="!knowledgeRestriction.isRestricted">
            <small>{{
              $t(
                'components.modals.add-company-group.set-knowledge-restriction.knowledge-restriction-card.not-restricted',
              )
            }}</small>
          </p>
          <p class="mb-0" v-else>
            <small>{{
              $t(
                'components.modals.add-company-group.set-knowledge-restriction.knowledge-restriction-card.restricted',
              )
            }}</small>
          </p>
        </div>
        <div class="col-2 text-right">
          <base-switch
            v-model="knowledgeRestriction.isRestricted"
            @input="triggerRestrictions($event)"
            type="primary"
          ></base-switch>
        </div>
      </div>
      <div class="select-warning" v-if="showSelectionWarning">
        <small class="text-muted">{{
          $t(
            'components.modals.add-company-group.set-knowledge-restriction.knowledge-restriction-card.warning',
          )
        }}</small>
      </div>

      <div v-if="rootCases.length">
        <ul>
          <li class="without_bullet" v-for="node in rootCases" :key="node.id">
            <collection-navigator
              :restriction="true"
              :node="node"
              :focus-ids="focusIds"
              :node-ids-to-expand="nodeIdsToExpand"
              :set-focus="setFocus"
              :fetch-children="fetchChildren"
            />
          </li>
        </ul>
      </div>
    </card>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import CollectionNavigator from 'components/CollectionNavigator';

export default {
  name: 'knowledge-restriction-card',
  components: {
    CollectionNavigator,
  },
  props: {
    knowledge: Object,
    isRestricted: {
      type: Boolean,
      required: false,
    },
    restrictedNodes: {
      type: Array,
      default: () => [],
    },
    nodeIdsToExpand: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      check: this.isRestricted !== null && this.isRestricted !== undefined,
      focusIds: this.restrictedNodes.map(({ id }) => id),
      rootCases: [],
      nodeToDirectChildren: new Map(),
      knowledgeRestriction: {
        knowledgeId: this.knowledge.id,
        knowledgeValue: this.knowledge.value,
        knowledgeName: this.knowledge.label,
        isRestricted: this.isRestricted,
        restrictedNodes: this.restrictedNodes,
      },
    };
  },
  computed: {
    showSelectionWarning() {
      return (
        this.knowledgeRestriction.isRestricted &&
        this.check &&
        !this.focusIds.length
      );
    },
    ...mapGetters(['isGranularAccessRestrictions']),
  },
  mounted() {
    if (this.focusIds && this.focusIds.length > 0) {
      this.triggerRestrictions(true);
    }
  },
  methods: {
    async fetchChildren(parentId, isKnowledge, childrenOrder) {
      let cases;
      if (isKnowledge) {
        cases = await this.$services.cases.getSettingsRoots(
          this.knowledge.value,
        );
      } else {
        cases = await this.$services.cases.getSettingsChildren(
          parentId,
          this.knowledge.value,
        );
      }
      if (childrenOrder) {
        cases = childrenOrder
          .map((orderItem) => {
            return cases.find((child) => child.id === orderItem.id);
          })
          .filter(Boolean);
      }

      this.nodeToDirectChildren.set(
        parentId,
        cases.map(({ id, label }) => ({ id, label })),
      );

      return cases;
    },
    async triggerRestrictions(e) {
      if (e) {
        this.check = true;
        this.rootCases = await this.fetchChildren(this.knowledge.id, true);
      } else {
        this.resetRestriction();
        this.$nextTick(() => {
          this.$emit('update-knowledge-restriction', this.knowledgeRestriction);
        });
      }
    },
    resetRestriction() {
      this.focusIds = [];
      this.rootCases = [];
      this.knowledgeRestriction.isRestricted = false;
      this.knowledgeRestriction.restrictedNodes = [];
    },
    setFocus(nodeId) {
      if (this.focusIds.includes(nodeId)) {
        this.focusIds = this.focusIds.filter((o) => o !== nodeId);
      } else {
        const descendentIds = this.getAllChildren(nodeId).map(({ id }) => id);
        this.focusIds = this.focusIds
          .filter((focusId) => !descendentIds.includes(focusId))
          .concat([nodeId]);
      }
    },
    getAllChildren(nodeId) {
      const directChildren = this.nodeToDirectChildren.get(nodeId);
      return directChildren
        ? [
            ...directChildren,
            ...directChildren.flatMap(({ id }) => this.getAllChildren(id)),
          ]
        : [];
    },
  },
  watch: {
    check() {
      if (this.check) {
        this.$nextTick(() => {
          this.$emit('update-knowledge-restriction', this.knowledgeRestriction);
        });
      } else if (!this.check) {
        this.resetRestriction();
        this.$emit('update-knowledge-restriction', null);
      }
    },
    focusIds() {
      // Need to check for this.check otherwise update-knowledge-restriction
      // event is fired because of resetRestriction() method
      if (!this.focusIds || !this.check) return;

      this.knowledgeRestriction.restrictedNodes = this.focusIds.map(
        (nodeId) => {
          const node = this.getAllChildren(this.knowledge.id).find(
            (o) => String(o.id) === String(nodeId),
          );
          return { id: nodeId, label: node.label };
        },
      );
      this.$emit('update-knowledge-restriction', this.knowledgeRestriction);
    },
  },
};
</script>
<style scoped>
.nav-item {
  border: 1px solid #f2f5f9;
  border-radius: 3px;
}

.vertical-divider {
  max-height: 18px;
}

.without_bullet {
  list-style: none;
}

.is-parametric :deep() .card-body {
  display: none;
}
</style>
<style lang="scss">
.select-warning {
  width: 100%;
  padding: 4px;
  background-color: $grey-1-mayday;
  border: 1px solid $grey-2-mayday;
  border-radius: 4px;
  margin: 8px 0px;
}
</style>
