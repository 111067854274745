<template>
  <div class="d-flex justify-content-end">
    <!-- CANCEL -->
    <base-button-old @click="sendCloseEvent" type="secondary" size="sm">{{
      $t('knowledge.modals.notifications.cancel')
    }}</base-button-old>
    <!-- PREVIOUS -->
    <base-button-old
      v-if="currentStep > 0"
      @click="sendGoToPreviousStepEvent"
      type="primary"
      outline
      size="sm"
      >{{ $t('knowledge.modals.notifications.previous') }}</base-button-old
    >
    <!-- NEXT -->
    <base-button-old
      v-if="currentStep !== stepCount - 1"
      @click="sendGoToNextStep"
      type="primary"
      size="sm"
      :disabled="isNextActionDisabled"
      >{{ $t('knowledge.modals.notifications.next') }}</base-button-old
    >
    <!-- SUBMIT -->
    <base-button-old
      v-else
      @click="sendCreateProductNotificationEvent"
      type="primary"
      size="sm"
      >{{ $t('knowledge.product.notification.send') }}</base-button-old
    >
  </div>
</template>

<script>
export default {
  name: 'modal-actions',
  props: {
    stepCount: Number,
    currentStep: Number,
    isProductNotificationTitleInvalid: Boolean,
    isProductNotificationDescriptionInvalid: Boolean,
    isProductNotificationParametersInvalid: Boolean,
    isProductNotificationAccessRestrictionsInvalid: Boolean,
  },
  methods: {
    sendCloseEvent() {
      this.$emit('close');
    },
    sendGoToPreviousStepEvent() {
      this.$emit('goToPreviousStep');
    },
    sendGoToNextStep() {
      this.$emit('goToNextStep');
    },
    sendCreateProductNotificationEvent() {
      this.$emit('createProductNotification');
    },
  },
  computed: {
    isNextActionDisabled() {
      switch (this.currentStep) {
        case 0:
          return (
            this.isProductNotificationTitleInvalid ||
            this.isProductNotificationDescriptionInvalid
          );
        case 1:
          return this.isProductNotificationParametersInvalid;
        case 2:
          return this.isProductNotificationAccessRestrictionsInvalid;
        default:
          return false;
      }
    },
  },
};
</script>
