var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',_vm._l((_vm.selectedFilters),function(filter,i){return _c('div',{key:i,staticClass:"filter-container"},[_c('div',{staticClass:"category-operator"},[(i === 1)?_c('el-select',{attrs:{"value":_vm.operator},on:{"change":_vm.changeCategoryOperator}},[_c('el-option',{attrs:{"label":_vm.$t(
                'settings.general.collaborative-hub.drawer.content.operator.AND',
              ),"value":"AND"}}),_c('el-option',{attrs:{"label":_vm.$t(
                'settings.general.collaborative-hub.drawer.content.operator.OR',
              ),"value":"OR"}})],1):_c('div',[_vm._v(" "+_vm._s(i ? _vm.$t( `settings.general.collaborative-hub.drawer.content.operator.${_vm.operator}`, ) : _vm.$t( `settings.general.collaborative-hub.drawer.content.operator.WHERE`, ))+" ")])],1),_c('div',{staticClass:"filter-wrapper"},[_c('div',{staticClass:"filter-selector-wrapper"},[_c('div',{staticClass:"filter-type-wrapper"},[_c('el-select',{staticStyle:{"width":"100%"},attrs:{"value":filter.key},on:{"change":(e) => _vm.handleFilterType(e, filter.key)}},[_c('template',{slot:"prefix"},[_c('font-awesome-icon',{staticClass:"button-icon",attrs:{"icon":[
                    'fal',
                    _vm.filtersOptions.find((f) => f.key === filter.key).icon,
                  ]}})],1),_vm._l((_vm.filtersOptions),function(option,i){return _c('el-option',{key:i,attrs:{"disabled":_vm.isFilterSelected(option.key),"label":_vm.$t(option.label),"value":option.key}},[_c('template',{slot:"default"},[_c('font-awesome-icon',{staticClass:"button-icon",attrs:{"icon":['fal', option.icon]}}),_c('span',[_vm._v(" "+_vm._s(_vm.$t(option.label))+" ")])],1)],2)})],2)],1),_c('div',{staticClass:"filter-selector"},[_c('el-tooltip',{attrs:{"content":_vm.$t(
                  `settings.general.collaborative-hub.drawer.content.operator.${filter.operator}`,
                ),"effect":"dark","placement":"top","popper-class":"mb-1"}},[_c('button',{staticClass:"filter-operator",on:{"click":function($event){return _vm.changeFilterOperator(filter)}}},[_c('font-awesome-icon',{attrs:{"icon":[
                    'fal',
                    filter.operator === '$in' ? 'equals' : 'not-equal',
                  ]}})],1)]),_c('el-select',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.valuesLoading),expression:"valuesLoading"}],staticStyle:{"width":"100%"},attrs:{"value":filter.values,"filterable":"","multiple":"","value-key":"id","collapse-tags":"","remote":filter.key === 'ids',"remote-method":_vm.handleSearchUsers},on:{"change":(value) => _vm.handleUpdateFilter(value, filter.key),"visible-change":(visible) => _vm.handleTogglePopup(filter.key, visible)}},[_c('div',{directives:[{name:"infinite-scroll",rawName:"v-infinite-scroll",value:(_vm.handleScroll),expression:"handleScroll"},{name:"loading",rawName:"v-loading",value:(_vm.valuesLoading),expression:"valuesLoading"}],attrs:{"infinite-scroll-disabled":filter.key !== 'ids'}},_vm._l((_vm.filterTypeOptions(filter.key)),function(option){return _c('el-option',{key:option.id,attrs:{"label":option.name,"value":option.id}})}),1)])],1)]),_c('button',{staticClass:"delete-button",on:{"click":function($event){return _vm.handleRemoveFilter(filter.key)}}},[_c('font-awesome-icon',{attrs:{"icon":['fal', 'trash']}})],1)])])}),0),_c('base-button',{attrs:{"variant":"tertiary","size":"sm","disabled":!_vm.availableFilters.length,"text":_vm.$t('settings.general.collaborative-hub.drawer.content.add-rule'),"icon":"plus"},on:{"click":_vm.handleAddFilter}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }