<template>
  <div class="d-flex align-items-center justify-content-center">
    <span class="archive-banner" v-if="archiveError"
      >{{ $t(`${i18nPath}.error.text`) }}
      <span class="ml-4"
        ><base-button-old
          type="secondary"
          size="sm"
          text-color="danger"
          @click="goToArchives"
          >{{ $t(`${i18nPath}.error.cta`) }}</base-button-old
        >
      </span>
    </span>
    <span class="archive-banner" v-else
      >{{
        $t(`${i18nPath}.${currentFocus}.text1`, {
          collection: mainCaseArchiveLabel,
        })
      }}
      <span v-if="deletionDate">{{
        $t(`${i18nPath}.${currentFocus}.text2`, { date: deletionDate })
      }}</span>
      <div class="actions-container">
        <not-allowed-tooltip
          :user-permissions="focusKnowledge.userPermissions"
          permission-key="canCreateContent"
          placement="bottom"
        >
          <template v-slot:main-content="{ hasPermissions }">
            <base-button-old
              type="secondary"
              size="sm"
              text-color="danger"
              @click="hasPermissions ? beforeRestoreArchive() : ''"
              :icon="loading.restore"
              :disabled="!hasPermissions"
              >{{ $t(`${i18nPath}.${currentFocus}.actions.restore`) }}
            </base-button-old>
          </template>
        </not-allowed-tooltip>
        <not-allowed-tooltip
          :user-permissions="focusKnowledge.userPermissions"
          permission-key="canDeleteContent"
          placement="bottom"
        >
          <template v-slot:main-content="{ hasPermissions }">
            <base-button-old
              type="danger"
              size="sm"
              @click="hasPermissions ? deleteArchive() : ''"
              :icon="loading.delete"
              :disabled="!hasPermissions"
              >{{ $t(`${i18nPath}.${currentFocus}.actions.delete`) }}
            </base-button-old>
          </template>
        </not-allowed-tooltip>
      </div>
    </span>
    <CasesSelectionModal
      v-if="selectCases"
      :display="selectCases"
      :knowledge="focusArchiveKnowledge"
      :multi-select="currentFocus === 'content'"
      @choice="restoreArchive"
      @close="selectCases = false"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import CasesSelectionModal from '@/components/Modals/CasesSelectionModal/CasesSelectionModal';
import NotAllowedTooltip from '@/components/Commons/NotAllowedTooltip';

export default {
  name: 'ArchiveViewerHeader',
  components: {
    CasesSelectionModal,
    NotAllowedTooltip,
  },
  computed: {
    currentFocus() {
      return this.$route.params.caseArchiveId ? 'case' : 'content';
    },
    focusArchiveKnowledge() {
      return this.currentFocus === 'case'
        ? this.mainCaseArchiveKnowledge
        : this.archiveContentKnowledge;
    },
    deletionDate() {
      return this.currentFocus === 'case'
        ? this.mainCaseArchiveDeletionDate
        : this.archiveDeletionDate;
    },
    focusRequiresCaseSelection() {
      return this.currentFocus === 'case'
        ? this.mainCaseArchiveHasFormerCaseParents
        : this.archiveHasFormerCaseParents;
    },
    ...mapGetters('knowledgeModule', [
      'archiveContent',
      'archiveContentKnowledge',
      'archiveDeletionDate',
      'archiveHasFormerCaseParents',
      'archiveError',
      'mainCaseArchiveHasFormerCaseParents',
      'mainCaseArchiveKnowledge',
      'mainCaseArchiveLabel',
      'mainCaseArchiveDeletionDate',
      'focusKnowledge',
      'focusKnowledgeId',
      'editingLanguage',
    ]),
  },
  data() {
    return {
      loading: {
        restore: null,
        delete: null,
      },
      selectCases: false,
      i18nPath: 'knowledge.archives.top-banner',
    };
  },
  methods: {
    async beforeRestoreArchive() {
      if (this.focusRequiresCaseSelection) {
        return this.restoreArchive();
      } else {
        this.selectCases = true;
      }
    },
    async restoreArchive(caseParameters = null) {
      this.selectCases = false;
      this.loading.restore = 'fas fa-spinner fa-spin';

      try {
        if (this.currentFocus === 'content') {
          await this.restoreFocusArchive(caseParameters);
        } else if (this.currentFocus === 'case') {
          let parent = caseParameters ? caseParameters[0] : null;
          parent = parent.split('/').slice(-1)[0];
          await this.restoreMainCaseArchive(parent);
        }
      } catch (err) {
        return err;
      } finally {
        this.loading.restore = null;
        this.goToArchives();
      }
    },
    async deleteArchive() {
      this.loading.delete = 'fas fa-spinner fa-spin';

      if (this.currentFocus === 'content') {
        await this.deleteFocusArchive();
      } else if (this.currentFocus === 'case') {
        await this.deleteCaseArchive();
      }

      this.goToArchives();
    },
    goToArchives() {
      window.location.href = `/archives/${this.editingLanguage}/${this.focusKnowledgeId}`;
    },
    ...mapActions('knowledgeModule', [
      'restoreFocusArchive',
      'deleteFocusArchive',
      'restoreMainCaseArchive',
      'deleteCaseArchive',
    ]),
  },
};
</script>

<style lang="scss" scoped>
.archive-banner {
  color: white;
  :deep() .btn {
    border-radius: 2px;
  }
  :deep() .btn-danger {
    border-color: white;
  }
}

.actions-container {
  display: inline-flex;
  gap: 8px;
  margin-left: 16px;
}
</style>
