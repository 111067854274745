<template>
  <div class="">
    <div class="d-flex align-items-center">
      <transition name="slide-fade" mode="out-in" v-on:after-enter="afterEnter">
        <BaseInput
          ref="searchInput"
          v-if="displaySearch"
          input-group-classes="input-group-sm"
          v-model="searchFilter"
          addon-left-icon="fa fa-search"
          :placeholder="translate.placeholder"
          class="mr-0 my-0 search-field__input"
          @input="launchSearchDebounce"
          @keyup.enter="launchSearch()"
          @keyup.esc="updateDisplaySearch"
        />
        <div v-else class="my-0 search-field">
          <el-tooltip
            :content="translate.tooltip"
            placement="top"
            class="group-tooltip"
          >
            <base-button
              icon="search"
              variant="tertiary"
              size="sm"
              icon-only
              @click.prevent="updateDisplaySearch"
            />
          </el-tooltip>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash.debounce';
import BaseInput from '@/components/BaseInput';

export default {
  name: 'search-field',
  props: {
    initSearch: {
      type: String,
      default: '',
    },
    initDisplay: {
      type: Boolean,
      default: false,
    },
    translate: {
      type: Object,
      default: () => ({
        placeholder: '',
        tooltip: '',
      }),
    },
    updatedValue: {
      type: String,
      default: '',
    },
  },
  components: {
    BaseInput,
  },
  data() {
    return {
      searchFilter: this.initSearch,
      displaySearch: this.initDisplay,
      launchSearchDebounce: debounce(
        () => this.$emit('launch-search', this.searchFilter),
        500,
      ),
    };
  },
  mounted() {
    window.addEventListener('beforeunload', () =>
      this.launchSearchDebounce.flush(),
    );
    document.addEventListener('keydown', this.searchEventListener);
  },
  methods: {
    searchEventListener(e) {
      if (e.key === 'f' && (e.ctrlKey || e.metaKey)) {
        e.preventDefault();
        this.updateDisplaySearch();
      }
    },
    afterEnter(el) {
      if (this.displaySearch) {
        el.children[0].children[1].focus();
      }
    },
    launchSearch() {
      this.launchSearchDebounce.flush();
    },
    updateDisplaySearch() {
      this.displaySearch = !this.displaySearch;
    },
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.searchEventListener);
    this.launchSearchDebounce.flush();
  },
  watch: {
    updatedValue() {
      this.searchFilter = this.updatedValue;
      // Focus on input when searchFilter is empty and is displayed
      if (!this.searchFilter && this.displaySearch) {
        this.$refs.searchInput.$el.children[0].children[1].focus();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
/* durations and timing functions.              */
.slide-fade-enter-active .slide-fade-leave-active {
  transition: all 0.05s ease;
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(-10px);
  opacity: 0;
}

.search-field__input {
  border-radius: 4px;

  :deep() .form-control {
    height: 26px;
  }
  :deep() .input-group-text {
    padding: 0 8px;
  }
}

.search-field__button {
  padding: 0;
  background-color: transparent;
  border: none;
  color: $grey-7-mayday;
}

.search-field__button--active {
  color: $blue-mayday;
}

.search-field__trigger-wrapper {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  border-radius: 4px;
  width: 24px;
  height: 24px;
  &:hover {
    background-color: $grey-2-mayday;
  }

  .search-field__trigger {
    font-size: 16px;
    width: 16px;
    height: 16px;
  }
}
</style>
