<template>
  <div class="top-container">
    <div
      v-if="!workflowMetricsIsLoading && !isEmpty"
      style="height: 100%; width: 100%"
      class="pb-4"
    >
      <div
        style="padding-top: 24px; display: flex; justify-content: space-between"
        class="px-2"
      >
        <div>
          <AnalyticsStatsCard
            class="analytics-workflow-stats-card"
            :loading="workflowMetricsIsLoading"
            :stats="workflowCardMetrics"
            :title="$t('new-dashboard.header.title.analytics-workflow-stats')"
            flex
          >
            <template v-slot:trendBadge="{ additionalStat }">
              <font-awesome-icon
                :class="additionalStat"
                class="trend-badge"
                :icon="['fad', 'arrow-right']"
              />
            </template>
          </AnalyticsStatsCard>
        </div>
        <div>
          <AnalyticsTitle
            :title="$t('new-dashboard.header.title.analytics-workflow-status')"
            :tooltipContent="
              $t('new-dashboard.header.tooltip.analytics-workflow-status')
            "
          ></AnalyticsTitle>
          <component
            v-if="statusChartData"
            :is="'DoughnutChart'"
            :height="200"
            :chart-data="statusChartData"
            :extra-options="{ layout: { padding: { bottom: 10, top: 10 } } }"
          ></component>
        </div>
        <div style="width: 25vw">
          <AnalyticsTitle
            :title="
              $t('new-dashboard.header.title.analytics-workflow-error-status')
            "
            :tooltipContent="
              $t('new-dashboard.header.tooltip.analytics-workflow-error-status')
            "
          ></AnalyticsTitle>
          <AnalyticsWorkflowStatusTable
            v-if="statusCountTableData.length"
            :data="statusCountTableData"
            :data-options="{}"
            :is-loading="false"
            :is-empty="!statusCountTableData.length"
            :table-headers="statusCountTableHeaders"
          ></AnalyticsWorkflowStatusTable>
          <div
            class="d-flex px-2 pb-4"
            style="align-items: center; height: 100%"
            v-else
          >
            <p style="text-align: center">
              {{
                $t(
                  'new-dashboard.header.empty-state.analytics-workflow-error-status',
                )
              }}
            </p>
          </div>
        </div>
      </div>
      <el-divider></el-divider>
      <div>
        <AnalyticsTitle
          :title="
            $t('new-dashboard.header.title.analytics-workflow-count-per-second')
          "
          :tooltipContent="
            $t(
              'new-dashboard.header.tooltip.analytics-workflow-count-per-second',
            )
          "
          style="height: 40px; margin-bottom: 12px"
        ></AnalyticsTitle>
        <component
          v-if="functionCallsPerSecondLineChartData"
          :is="'LineChart'"
          :height="500"
          :chart-data="functionCallsPerSecondLineChartData"
          :extra-options="extraOptions"
        ></component>
        <AnalyticsWorkflowTable
          :data="functionCallsPerSecondTableData"
          :dataOptions="dataOptions"
          :isLoading="workflowMetricsIsLoading"
          :isEmpty="isEmpty"
          :tableHeaders="functionCallsPerSecondTableHeaders"
          maxHeight="none"
          @update-data-options="handleUpdateDataOptions"
        >
        </AnalyticsWorkflowTable>
      </div>
      <el-divider></el-divider>
      <div>
        <AnalyticsTitle
          :title="$t('new-dashboard.header.title.analytics-workflow-count')"
          :tooltipContent="
            $t('new-dashboard.header.tooltip.analytics-workflow-count')
          "
          style="height: 40px; margin-bottom: 12px"
        ></AnalyticsTitle>
        <component
          v-if="countLineChartData"
          :is="'LineChart'"
          :height="500"
          :chart-data="countLineChartData"
          :extra-options="extraOptions"
        ></component>
        <AnalyticsWorkflowTable
          :data="statusTableData"
          :dataOptions="dataOptions"
          :isLoading="workflowMetricsIsLoading"
          :isEmpty="isEmpty"
          :tableHeaders="statusTableHeaders"
          maxHeight="none"
          @update-data-options="handleUpdateDataOptions"
        >
        </AnalyticsWorkflowTable>
      </div>
      <el-divider></el-divider>
      <div class="duration-container-inner">
        <AnalyticsTitle
          :title="$t('new-dashboard.header.title.analytics-workflow-duration')"
          :tooltipContent="
            $t('new-dashboard.header.tooltip.analytics-workflow-duration')
          "
        ></AnalyticsTitle>
        <el-select
          class="content-filter-select"
          v-model="durationLineChartStat"
          value="avg"
          size="mini"
          style="width: 100px; height: 40px"
        >
          <el-option
            v-for="({ label, key }, idx) in durationLineChartOptions"
            :key="`content-filter-select-${idx}`"
            :label="label"
            :value="key"
          />
        </el-select>
      </div>
      <div>
        <component
          v-if="durationLineChartData"
          :is="'LineChart'"
          :height="400"
          :chart-data="durationLineChartData"
          :extra-options="extraOptions"
        ></component>
        <AnalyticsWorkflowTable
          :data="durationTableData"
          :dataOptions="dataOptions"
          :isLoading="workflowMetricsIsLoading"
          :isEmpty="isEmpty"
          :tableHeaders="durationTableHeaders"
          @update-data-options="handleUpdateDataOptions"
          maxHeight="none"
        >
        </AnalyticsWorkflowTable>
      </div>
    </div>
    <EmptyState
      v-else
      class="empty-state-container"
      @open-filters-drawer="() => $emit('open-filters-drawer')"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import EmptyState from '../EmptyState';
import AnalyticsTitle from '../AnalyticsTitle';
import AnalyticsWorkflowTable from './AnalyticsWorkflowTable';
import {
  colorDistribution,
  mapTrendToBadgeType,
  mapTrendToBadgeTypeInverted,
} from '@/store/dashboard/utils';
import AnalyticsStatsCard from '../AnalyticsStatsCard';
import { dateBetween } from '@/utils/dates/dateBetween';
import moment from 'moment';
import AnalyticsWorkflowStatusTable from './AnalyticsWorkflowStatusTable.vue';

const mapStatusToColor = (statusCode) => {
  if (statusCode <= 200) {
    return '#4CAF50';
  } else if (statusCode <= 400) {
    return '#FFC107';
  } else {
    return '#F44336';
  }
};

export default {
  props: {
    externalDocUrl: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      durationTableCurrentPage: 1,
      durationTableItemsPerPage: 2,
      statusTableCurrentPage: 1,
      statusTableItemsPerPage: 2,
      dataOptions: {},
      durationLineChartStat: '50.0',
      durationLineChartData: null,
      durationLineChartOptions: [
        { key: '50.0', label: 'min' },
        { key: '50.0', label: 'median' },
        { key: 'avg', label: 'avg' },
        { key: '75.0', label: 'p75' },
        { key: '90.0', label: 'p90' },
        { key: '95.0', label: 'p95' },
        { key: '99.0', label: 'p99' },
        { key: 'max', label: 'max' },
      ],
      statusCountTableHeaders: Object.freeze([
        {
          prop: 'status',
          path: 'status',
          key: 'status',
          label: this.$t(`new-dashboard.table.header.workflow.status`),
          width: 100,
        },
        {
          prop: 'count',
          path: 'count',
          key: 'count',
          label: this.$t(`new-dashboard.table.header.workflow.total`),
          width: 100,
        },
        {
          prop: 'ratio',
          path: 'ratio',
          key: 'ratio',
          label: '%',
          template: true,
          width: 100,
        },
      ]),
    };
  },
  created() {
    this.initWorkflowMetrics();
  },
  components: {
    EmptyState,
    AnalyticsTitle,
    DoughnutChart: () => import('@/components/Charts/DoughnutChart'),
    LineChart: () => import('@/components/Charts/LineChart'),
    AnalyticsWorkflowTable,
    AnalyticsWorkflowStatusTable,
    AnalyticsStatsCard,
  },
  methods: {
    ...mapActions('dashboard', ['getWorkflowMetrics', 'initWorkflowMetrics']),
    mapDatasetNameToLabel(datasetName) {
      const functionId = datasetName.split('|')[0];
      let label = this.workflowFunctionInfos.get(functionId).name;

      const statusCode = datasetName.split('|')[1];
      if (!statusCode) return label;

      return `${label} - ${statusCode}`;
    },
    handleUpdateDataOptions(options) {
      this.$emit('update:dataOptions', options);
    },

    updateDurationLineChartData() {
      if (this.isEmpty) {
        this.durationLineChartData = null;
        return;
      }

      const allDatasets = new Set();
      this.workflowMetrics.requestsDuration.forEach((dataPoint) => {
        Object.keys(dataPoint.data).forEach((key) => {
          allDatasets.add(key);
        });
      });

      const color = colorDistribution(allDatasets.length);
      this.durationLineChartData = {
        labels: this.workflowMetrics.requestsDuration.map(
          (dataPoint) => dataPoint.time,
        ),
        datasets: Array.from(allDatasets).map((datasetName, idx) => ({
          label: this.mapDatasetNameToLabel(datasetName),
          borderColor: color[idx],
          pointBackgroundColor: color[idx],
          data: this.workflowMetrics.requestsDuration.map((dataPoint) =>
            dataPoint.data[datasetName]
              ? dataPoint.data[datasetName][this.durationLineChartStat]
              : 0,
          ),
        })),
      };
    },
  },
  computed: {
    ...mapGetters('dashboard', [
      'workflowMetricsIsLoading',
      'workflowMetrics',
      'workflowFunctionInfos',
      'filterDateRange',
    ]),
    workflowCardMetrics() {
      return [
        {
          name: 'new-dashboard.stat-card.name.analytics-workflow-metrics.queries',
          tooltipContent:
            'new-dashboard.header.tooltip.analytics-workflow-metrics.queries',
          stat: this.workflowMetrics.cards.queries.value,
          badgeType:
            mapTrendToBadgeType[this.workflowMetrics.cards.queries.trend],
          additionalStat: this.workflowMetrics.cards.queries.trend,
          isEmpty: this.workflowMetrics.cards.queries.value === 0,
        },
        {
          name: 'new-dashboard.stat-card.name.analytics-workflow-metrics.successes',
          tooltipContent:
            'new-dashboard.header.tooltip.analytics-workflow-metrics.successes',
          stat: this.workflowMetrics.cards.successes.value,
          badgeType:
            mapTrendToBadgeType[this.workflowMetrics.cards.successes.trend],
          additionalStat: this.workflowMetrics.cards.successes.trend,
          badgeSlot: 'trendBadge',
          isEmpty: this.workflowMetrics.cards.queries.value === 0,
        },
        {
          name: 'new-dashboard.stat-card.name.analytics-workflow-metrics.failures',
          tooltipContent:
            'new-dashboard.header.tooltip.analytics-workflow-metrics.failures',
          stat: this.workflowMetrics.cards.failures.value,
          badgeType:
            mapTrendToBadgeTypeInverted[
              this.workflowMetrics.cards.failures.trend
            ],
          additionalStat: this.workflowMetrics.cards.failures.trend,
          badgeSlot: 'trendBadge',
          isEmpty: this.workflowMetrics.cards.queries.value === 0,
        },
      ];
    },
    extraOptions() {
      return {
        layout: { padding: { bottom: 50, top: 50 } },
        scales: {
          xAxes: [
            {
              ticks: {
                callback: function (value, index, values) {
                  const timeScale = dateBetween(
                    values[0],
                    values.slice(-1)[0],
                    'days',
                  );
                  const momentVal = moment.unix(value / 1000);

                  if (timeScale < 2)
                    return index % 4 == 0
                      ? momentVal.format('Do MMMM, h:mm a')
                      : '';

                  if (timeScale < 4)
                    return index % 4 == 0
                      ? momentVal.format('Do MMMM, h a')
                      : '';

                  if (index > 0) {
                    const prevMomentVal = moment.unix(values[index - 1] / 1000);
                    if (
                      prevMomentVal.format('Do MMMM') !==
                      momentVal.format('Do MMMM')
                    ) {
                      return momentVal.format('Do MMMM');
                    }
                  }

                  return '';
                },
              },
            },
          ],
        },
        tooltips: {
          callbacks: {
            title: function (...args) {
              return moment
                .unix(args[0][0].xLabel / 1000)
                .format('Do MMMM, h:mm a');
            },
            label: function (tooltipItem, data) {
              if (tooltipItem.yLabel === 0) return '';

              var label = data.datasets[tooltipItem.datasetIndex].label || '';

              if (label) {
                label += ': ';
              }
              label += Math.round(tooltipItem.yLabel * 10000) / 10000;
              return label;
            },
          },
        },
      };
    },
    isEmpty() {
      if (
        this.workflowMetricsIsLoading ||
        !this.workflowMetrics ||
        !this.workflowMetrics.allStatus ||
        Object.keys(this.workflowMetrics.allStatus).length === 0
      )
        return true;
      return false;
    },
    statusChartData() {
      if (this.isEmpty) {
        return null;
      }
      return {
        labels: Object.keys(this.workflowMetrics.allStatus).map((key) =>
          this.$t(`new-dashboard.chart.workflow.status`, { key }),
        ),
        datasets: [
          {
            backgroundColor: Object.keys(this.workflowMetrics.allStatus).map(
              (key) => mapStatusToColor(parseInt(key)),
            ),
            data: Object.keys(this.workflowMetrics.allStatus).map(
              (key) => this.workflowMetrics.allStatus[key],
            ),
          },
        ],
      };
    },
    statusCountTableData() {
      const allStatus = this.workflowMetrics.allStatus;
      const total = Object.entries(allStatus).reduce((a, [status, count]) => {
        if (parseInt(status) < 300) return a;
        return a + count;
      }, 0);
      const errors = Object.entries(allStatus).reduce(
        (a, [status, count], idx, arr) => {
          if (parseInt(status) < 300) return a;
          const data = {
            status,
            count,
            ratio: Math.round((count / total) * 100),
          };
          if (idx + 1 === arr.length && arr.length > 1)
            data.ratio = 100 - a.reduce((s, v) => s + v.ratio, 0);
          return [...a, data];
        },
        [],
      );
      return errors.sort((a, b) => b.count - a.count);
    },
    durationTableHeaders() {
      return [
        {
          prop: 'function',
          path: 'functionName',
          key: 'label',
          label: this.$t(`new-dashboard.table.header.workflow.function`),
          template: true,
          width: 200,
        },
        {
          prop: 'url',
          path: 'url',
          key: 'url',
          label: this.$t(`new-dashboard.table.header.workflow.url`),
          template: true,
          width: 250,
        },
        ...['min', 'avg', 'median', 'p75', 'p90', 'p95', 'p99', 'max'].map(
          (p) => ({
            prop: p,
            key: p,
            width: 50,
            label: this.$t(`new-dashboard.table.header.workflow.${p}`),
            template: true,
          }),
        ),
      ];
    },
    durationTableData() {
      return Object.keys(this.workflowMetrics.allDurations).map((key) => {
        const functionInfo = this.workflowFunctionInfos.get(key.split(',')[0]);
        return {
          functionName: `${functionInfo.name} - ${key.split(',')[1]}`,
          url: functionInfo.url,
          min: this.workflowMetrics.allDurations[key].min,
          max: this.workflowMetrics.allDurations[key].max,
          avg: this.workflowMetrics.allDurations[key].avg,
          median: this.workflowMetrics.allDurations[key]['50.0'],
          p75: this.workflowMetrics.allDurations[key]['75.0'],
          p90: this.workflowMetrics.allDurations[key]['90.0'],
          p95: this.workflowMetrics.allDurations[key]['95.0'],
          p99: this.workflowMetrics.allDurations[key]['99.0'],
        };
      });
    },
    statusTableHeaders() {
      return [
        {
          prop: 'function',
          path: 'functionName',
          key: 'label',
          label: this.$t(`new-dashboard.table.header.workflow.function`),
          template: true,
          width: 200,
        },
        {
          prop: 'url',
          path: 'url',
          key: 'url',
          label: this.$t(`new-dashboard.table.header.workflow.url`),
          template: true,
          width: 250,
        },
        ...['total', 's2xx', 's3xx', 's4xx', 's5xx'].map((p) => ({
          prop: p,
          key: p,
          width: 50,
          label: this.$t(`new-dashboard.table.header.workflow.${p}`),
          template: true,
        })),
      ];
    },
    statusTableData() {
      return Object.keys(this.workflowMetrics.statusPerFunction).map(
        (functionKey) => {
          const out = {
            functionName: this.workflowFunctionInfos.get(functionKey).name,
            url: this.workflowFunctionInfos.get(functionKey).url,
            s2xx: 0,
            s3xx: 0,
            s4xx: 0,
            s5xx: 0,
          };
          for (let key of Object.keys(
            this.workflowMetrics.statusPerFunction[functionKey],
          )) {
            if (key === 'total') {
              out.total =
                this.workflowMetrics.statusPerFunction[functionKey][key];
              continue;
            }
            if (Number.parseInt(key) < 300) {
              out['s2xx'] =
                this.workflowMetrics.statusPerFunction[functionKey][key];
            } else if (Number.parseInt(key) < 400) {
              out['s3xx'] =
                this.workflowMetrics.statusPerFunction[functionKey][key];
            } else if (Number.parseInt(key) < 500) {
              out['s4xx'] =
                this.workflowMetrics.statusPerFunction[functionKey][key];
            } else {
              out['s5xx'] =
                this.workflowMetrics.statusPerFunction[functionKey][key];
            }
          }
          return out;
        },
      );
    },
    functionCallsPerSecondTableHeaders() {
      return [
        {
          prop: 'function',
          path: 'functionName',
          key: 'label',
          label: this.$t(`new-dashboard.table.header.workflow.function`),
          template: true,
          width: 200,
        },
        {
          prop: 'url',
          path: 'url',
          key: 'url',
          label: this.$t(`new-dashboard.table.header.workflow.url`),
          template: true,
          width: 250,
        },
        ...['min', 'avg', 'max'].map((p) => ({
          prop: p,
          key: p,
          width: 50,
          label: this.$t(`new-dashboard.table.header.workflow.${p}`),
          template: false,
        })),
      ];
    },
    functionCallsPerSecondTableData() {
      if (this.isEmpty) {
        return null;
      }
      const allDatasets = new Set();
      this.workflowMetrics.requestsDuration.forEach((dataPoint) => {
        Object.keys(dataPoint.data).forEach((key) => {
          allDatasets.add(key);
        });
      });

      const dataDistributions = this.workflowMetrics.requestsDuration.reduce(
        (acc, val) => {
          const dataPoints = val.data;
          Array.from(allDatasets).forEach((datasetName) => {
            if (!acc[datasetName]) acc[datasetName] = [];
            acc[datasetName].push(
              dataPoints[datasetName] ? dataPoints[datasetName].rate : 0,
            );
          });
          return acc;
        },
        {},
      );

      return Object.keys(dataDistributions)
        .map((functionKey) => {
          const dist = dataDistributions[functionKey];
          return {
            functionName: this.mapDatasetNameToLabel(functionKey),
            url: this.workflowFunctionInfos.get(functionKey.split('|')[0]).url,
            min: Math.ceil(Math.min(...dist) * 1000) / 1000,
            avg:
              Math.ceil(
                (dist.reduce((a, v) => a + v, 0) * 1000) / dist.length,
              ) / 1000,
            max: Math.ceil(Math.max(...dist) * 1000) / 1000,
          };
        })
        .sort((a, b) => b.max - a.max);
    },
    functionCallsPerSecondLineChartData() {
      if (this.isEmpty) {
        return null;
      }
      const allDatasets = new Set();
      this.workflowMetrics.functionCallsPerSecond.forEach((dataPoint) => {
        Object.keys(dataPoint.data).forEach((key) => {
          allDatasets.add(key);
        });
      });

      const color = colorDistribution(allDatasets.length);
      return {
        labels: this.workflowMetrics.functionCallsPerSecond.map(
          (dataPoint) => dataPoint.time,
        ),
        datasets: Array.from(allDatasets).map((datasetName, idx) => ({
          label: this.mapDatasetNameToLabel(datasetName),
          borderColor: color[idx],
          pointBackgroundColor: color[idx],
          data: this.workflowMetrics.functionCallsPerSecond.map((dataPoint) =>
            dataPoint.data[datasetName]
              ? Math.ceil(dataPoint.data[datasetName]['rate'] * 1000) / 1000
              : 0,
          ),
        })),
      };
    },
    countLineChartData() {
      if (this.isEmpty) {
        return null;
      }
      const allDatasets = new Set();
      this.workflowMetrics.requestsDuration.forEach((dataPoint) => {
        Object.keys(dataPoint.data).forEach((key) => {
          allDatasets.add(key);
        });
      });

      const color = colorDistribution(allDatasets.length);
      return {
        labels: this.workflowMetrics.requestsDuration.map(
          (dataPoint) => dataPoint.time,
        ),
        datasets: Array.from(allDatasets).map((datasetName, idx) => ({
          label: this.mapDatasetNameToLabel(datasetName),
          borderColor: color[idx],
          pointBackgroundColor: color[idx],
          data: this.workflowMetrics.requestsDuration.map((dataPoint) =>
            dataPoint.data[datasetName]
              ? dataPoint.data[datasetName]['count']
              : 0,
          ),
        })),
      };
    },
  },
  watch: {
    workflowMetrics: {
      handler() {
        this.updateDurationLineChartData();
      },
      deep: true,
    },
    durationLineChartStat: {
      handler() {
        this.updateDurationLineChartData();
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.stat-footer {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 10px;
  letter-spacing: 0.02em;
  color: $grey-6-mayday;
}

.trend-badge {
  width: 90%;
  height: 90%;

  &.up {
    transform: rotate(-45deg);
  }
  &.down {
    transform: rotate(45deg);
  }
}

.analytics-ask-stats-card {
  :deep() .stat-cards-wrapper.flex-wrapper .stat-container {
    flex-basis: 33%;
  }
}

.top-container {
  display: flex;
  height: 100%;
  width: 100%;
}

.bottom-container {
  display: flex;
  height: 100%;
  width: 100%;
}

.status-container {
  height: 100%;
  padding: 12px;
}
.duration-container {
  padding: 12px;
  border-left: 1px solid #b8bfcd;
}

.duration-container-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.empty-state-container {
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;

  &-icon {
    width: 100px;
    height: 100px;
    background-image: url('~@/assets/empty-state-data.svg');
    background-size: cover;
    background-position: center;
    display: inline-block;
  }

  &-label {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    line-height: 16px;
    letter-spacing: 0.02em;

    color: #000000;
  }

  &-text {
    margin-bottom: unset;
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.02em;
    color: $grey-5-mayday;
  }
}
</style>
