<template>
  <div class="row article-editor-hosting-row justify-content-center relative">
    <div class="mb-4 col-sm-12 col-md-10 d-lg-none">
      <base-button-old
        type="primary"
        size="md"
        class="mx-0 w-100"
        style="font-size: 12px !important"
        @click="showParametersModal = true"
        >PARAMÈTRES</base-button-old
      >
    </div>
    <div class="col-lg-2 d-flex justify-content-center">
      <BuilderSideBar
        type="article"
        :editable="editable"
        :knowledge-id="content.knowledge"
        :root-id="content.id"
        :node-id="content.id"
        :content="content"
        :user-permissions="content.userPermissions"
        :is-published="content.published"
        :is-hidden="content.isHidden"
        :generating-summary="generatingSummary"
        :generating-short-summary="generatingShortSummary"
        :generating-action-items="generatingActionItems"
        :generating-guide="generatingGuide"
        :ai-drawer-tab="focusedAiDrawerTab"
        :minimum-content-size="minimumContentSize"
        :is-staged-content="isStagedContent"
        @add-notification="$emit('add-notification', $event)"
        @update-content="
          $emit('update-content', {
            id: content.id,
            payload: { ...$event, lang: content.returnLanguage },
          })
        "
        @generate-content="handleDisplayAiDrawer"
        @generate-guide="displayArticleToGuideModal = true"
        @generate-open-prompt="handleGenerateOpenPrompt"
        @click:historization="sessionHistorization"
      />
    </div>

    <div
      class="col-lg-8 col-md-10 col-sm-12 new-article-editor"
      @mouseleave="onMouseLeave"
    >
      <ParametricContentThreads
        v-if="!isTemplate && !isArchive && editable && !isStagedContent"
        :content-id="content.id"
      />

      <BaseCallout
        v-if="!isStagedContent && hasStagedContent"
        class="mb-3"
        :text="$t('knowledge.staged-version.warning-callout')"
      />

      <MaydayEditorCEWrapper
        ref="maydayEditorWrapper"
        :key="editorKey"
        :body="editorBody"
        :editable="isContentEditable"
        :locale="$i18n.locale"
        @update-written-content="updateWrittenContent"
        @generate-summary="handleGenerateSummary"
        @generate-action-items="handleGenerateActionItems"
      />
      <SatisfactionActions
        v-if="!editable && !isArchive && !isTemplate"
        :content-id="content.id"
        :content="content"
        @open-feedback-modal="openFeedbackModal"
      />
    </div>
    <Transition name="collapse">
      <AiDrawer
        @close="handleCloseAiDrawer"
        @summary-generated="saveSummary"
        @action-items-generated="saveActionItems"
        @km-recommendations-generated="saveKMRecommendations"
        @generating-summary="generatingSummary = true"
        @generating-short-summary="generatingShortSummary = true"
        @generating-action-items="generatingActionItems = true"
        @expand="
          (e) => {
            focusedAiDrawerTab = e;
          }
        "
        v-if="displayAiDrawerConditions"
        :summary="content.summary"
        :short-summary="content.shortSummary"
        :action-items="content.actionItems"
        :km-recommendations="content.kmRecommendations"
        :content-id="content.id"
        :lang="content.returnLanguage"
        :generating-summary="generatingSummary"
        :generating-short-summary="generatingShortSummary"
        :generating-action-items="generatingActionItems"
        :focused-tab-from-parent="focusedAiDrawerTab"
        :docLastUpdate="content.contentUpdatedAt"
      />
    </Transition>
    <div class="col-lg-2 px-0 side-pannel">
      <ParameterSideBar
        :content="content"
        v-if="$route.name.includes('knowledge') && !isTemplate"
      />
      <ProductParameterSideBar
        v-else-if="$route.name.includes('web-content')"
        :enriched-parameters="content.enrichedParameters"
      />
    </div>
    <ParametersModal
      v-if="showParametersModal"
      :enriched-parameters="content.enrichedParameters"
      :display="showParametersModal"
      @close="showParametersModal = false"
    />
    <ArticleToGuideModal
      v-if="displayArticleToGuideModal"
      :body="this.content.body"
      :display="displayArticleToGuideModal"
      :label="this.content.label"
      :language="this.content.returnLanguage"
      @close="displayArticleToGuideModal = false"
      @generate-guide="handleGenerateGuide"
    />
    <ArticleBrainImportModal
      v-if="displayBrainImportModal"
      @close="displayBrainImportModal = false"
      @input="handleBrainImport"
    />
    <ArticleBrainFilesMergeModal
      v-if="displayBrainFilesMergeModal"
      @close="displayBrainFilesMergeModal = false"
      @input="handleBrainFilesMerge"
    />
    <ArticleBrainGenerateFromPromptModal
      v-if="displayBrainGenerateFromPromptModal"
      @close="displayBrainGenerateFromPromptModal = false"
      @input="handleBrainGenerateFromPromptModal"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

// COMPONENTS
import BuilderSideBar from './ParametricBuilderSibeBar/BuilderSideBar';
import MaydayEditorCEWrapper from 'components/Editors/MaydayEditorCEWrapper';
import SatisfactionActions from './SatisfactionActions';
import ParameterSideBar from './ParametricParameterSideBar/ParameterSideBar';
import ParametersModal from '@/views/KnowledgeEditor/KnowledgeEditorMain/ProductViewer/ParametersModal';
import ProductParameterSideBar from '@/views/KnowledgeEditor/KnowledgeEditorMain/ProductViewer/ProductParameterSideBar';
import ParametricContentThreads from './ParametricContentThreads/ParametricContentThreads';
import AiDrawer from 'components/AI/AiDrawer.vue';
import ArticleToGuideModal from 'components/AI/ArticleToGuide/ArticleToGuideModal.vue';
import ArticleBrainImportModal from 'components/AI/ArticleBrainImportModal.vue';
import ArticleBrainFilesMergeModal from 'components/AI/ArticleBrainFilesMergeModal.vue';
import ArticleBrainGenerateFromPromptModal from 'components/AI/ArticleBrainGenerateFromPromptModal.vue';
import BaseCallout from '@/components/BaseCallout.vue';

export default {
  name: 'parametric-article-builder',
  components: {
    ArticleToGuideModal,
    ArticleBrainImportModal,
    ArticleBrainFilesMergeModal,
    ArticleBrainGenerateFromPromptModal,
    AiDrawer,
    BuilderSideBar,
    MaydayEditorCEWrapper,
    SatisfactionActions,
    ParameterSideBar,
    ParametersModal,
    ProductParameterSideBar,
    ParametricContentThreads,
    BaseCallout,
  },
  props: {
    content: Object,
    editable: {
      type: Boolean,
      default: true,
    },
    isContentEditable: {
      type: Boolean,
      default: true,
    },
    isArchive: {
      type: Boolean,
      default: false,
    },
    isTemplate: {
      type: Boolean,
      default: false,
    },
    aiDrawerTab: {
      type: String,
      default: null,
    },
    isStagedContent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showParametersModal: false,
      generatingSummary: false,
      generatingShortSummary: false,
      generatingActionItems: false,
      displayAiDrawer: false,
      focusedAiDrawerTab: null,
      minimumContentSize: 300,
      displayArticleToGuideModal: false,
      displayBrainImportModal: false,
      displayBrainFilesMergeModal: false,
      displayBrainGenerateFromPromptModal: false,
    };
  },
  computed: {
    displayAiDrawerConditions() {
      return (
        this.displayAiDrawer &&
        (this.companyAllowSummaryPreference ||
          this.companyAllowActionItemsPreference)
      );
    },
    generatingGuide() {
      return !!this.articleToGuideProcesses.find(
        (process) => process.articleId === this.content.id,
      );
    },
    editorKey() {
      return `${this.content.id}-${this.content.returnLanguage}-${
        this.content.forceRefreshCount
      }-${this.isStagedContent ? '1' : '0'}`;
    },
    editorBody() {
      if (this.isStagedContent) return this.content.stagedData.body;
      return this.content.body;
    },
    hasStagedContent() {
      return !!this.content.stagedData;
    },
    ...mapState(['session']),
    ...mapGetters([
      'companyAllowSummaryPreference',
      'companyAllowActionItemsPreference',
    ]),
    ...mapGetters('brainModule', ['articleToGuideProcesses']),
    ...mapGetters('knowledgeModule', ['focusKnowledge']),
  },
  methods: {
    openFeedbackModal() {
      this.$emit('open-feedback-modal');
    },
    async sessionHistorization(payload) {
      try {
        this.loadingMessage = this.$message({
          duration: 0,
          dangerouslyUseHTMLString: true,
          iconClass: 'display: none',
          showClose: true,
          message: `<i class="fas fa-spinner fa-spin" style="margin-right: 1rem;"></i> ${this.$t(
            'knowledge.article-builder.side-bar.historization.loading',
          )}`,
        });
        await this.$services.parametricContents.sessionHistorization({
          sessionId: this.session,
          solution: {
            id: this.content.id,
            ...payload,
            url: window.location.href,
          },
        });
        this.loadingMessage.close();
        this.$message({
          message: this.$t(
            'knowledge.article-builder.side-bar.historization.success',
          ),
          type: 'success',
          center: true,
        });
      } catch (error) {
        this.loadingMessage.close();
        this.$message({
          message: this.$t(
            'knowledge.article-builder.side-bar.historization.error',
          ),
          type: 'error',
          center: true,
        });
      }
    },
    handleGenerateOpenPrompt() {
      if (!this.$refs.maydayEditorWrapper) return;
      this.$refs.maydayEditorWrapper.handleGenerateOpenPrompt();
    },
    updateWrittenContent(payload) {
      this.$emit('update-written-content', {
        id: this.content.id,
        payload: {
          ...payload,
          lang: this.content.returnLanguage,
        },
      });
    },
    onMouseLeave({ relatedTarget }) {
      if (!relatedTarget) return;

      const { className } = relatedTarget;
      const handlerClass = 'mayday-editor__drag-icon-container';

      if (className === handlerClass) return;

      const editorDragHandleElement =
        document.getElementsByClassName(handlerClass);

      if (!editorDragHandleElement || !editorDragHandleElement[0]) return;

      editorDragHandleElement[0].style.visibility = 'hidden';
    },
    handleGenerateSummary(isShortSummary) {
      this.handleDisplayAiDrawer({
        target: isShortSummary ? 'short-summary' : 'summary',
        newGeneration: isShortSummary
          ? !this.content.shortSummary.body
          : !this.content.summary.body,
      });
    },
    handleGenerateActionItems() {
      this.handleDisplayAiDrawer({
        target: 'action-items',
        newGeneration: !this.content.actionItems.body,
      });
    },
    async handleGenerateGuide(prompt) {
      this.displayArticleToGuideModal = false;
      this.updateArticleToGuideProcess({
        processes: [
          ...this.articleToGuideProcesses,
          {
            articleId: this.content.id,
            status: 'PENDING',
            hidden: false,
            id: '',
          },
        ],
      });
      const process = await this.postArticleToGuide({
        label: this.content.label,
        body: this.content.body,
        language: this.content.returnLanguage,
        knowledge: this.content.knowledgeId,
        knowledgeId: this.focusKnowledge.id,
        caseParameters: this.content.caseParameters,
        objective: prompt,
      });
      if (process) {
        this.updateArticleToGuideProcess({
          processes: this.articleToGuideProcesses.map((p) => {
            if (p.articleId === this.content.id) {
              return {
                ...p,
                id: process.data.id,
              };
            }
            return p;
          }),
        });
      } else {
        this.updateArticleToGuideProcess({
          processes: this.articleToGuideProcesses.map((p) => {
            if (p.articleId === this.content.id) {
              return {
                ...p,
                status: 'ERROR',
              };
            }
            return p;
          }),
        });
      }
    },
    handleDisplayAiDrawer(payload) {
      const { target, newGeneration } = payload;

      if (target === 'brain-import') {
        this.displayBrainImportModal = true;
        return;
      }

      if (target === 'brain-files-merge') {
        this.displayBrainFilesMergeModal = true;
        return;
      }

      if (target === 'brain-generate-from-prompt') {
        this.displayBrainGenerateFromPromptModal = true;
        return;
      }

      if (target === 'knowledge-manager') {
        this.displayAiDrawer = true;
        this.focusedAiDrawerTab = target;
        return;
      }

      if (this.content.body.length < this.minimumContentSize) {
        this.$message({
          message: this.$t('knowledge.ai-drawer.error-empty'),
          type: 'error',
        });
        return;
      }
      this.displayAiDrawer = true;
      setTimeout(() => {
        this.focusedAiDrawerTab = target;
        if (newGeneration) {
          if (target === 'summary') this.generatingSummary = true;
          else if (target === 'short-summary')
            this.generatingShortSummary = true;
          else if (target === 'action-items') this.generatingActionItems = true;
        }
      }, 100);
    },
    handleCloseAiDrawer() {
      this.displayAiDrawer = false;
      this.focusedAiDrawerTab = null;
    },
    saveSummary(payload, isShortSummary = false) {
      if (isShortSummary) this.generatingShortSummary = false;
      else this.generatingSummary = false;

      if (payload.error) {
        this.$message({
          message: this.$t('knowledge.ai-drawer.error'),
          type: 'error',
        });
        return;
      }

      if (
        (isShortSummary && payload.body === this.content.shortSummary.body) ||
        (!isShortSummary && payload.body === this.content.summary.body)
      )
        return;

      const summariesPayload = { shortSummary: {}, summary: {} };
      if (isShortSummary) {
        summariesPayload.shortSummary = payload;
        if (payload.isVisible) summariesPayload.summary = { isVisible: false };
      } else {
        summariesPayload.summary = payload;
        if (payload.isVisible)
          summariesPayload.shortSummary = { isVisible: false };
      }

      this.$emit('update-content', {
        id: this.content.id,
        payload: { ...summariesPayload, lang: this.content.returnLanguage },
      });
      this.content.summary = {
        ...this.content.summary,
        ...summariesPayload.summary,
        bodyUpdatedAt: summariesPayload.summary.body
          ? Date.now().toString()
          : this.content.summary.bodyUpdatedAt,
      };
      this.content.shortSummary = {
        ...this.content.shortSummary,
        ...summariesPayload.shortSummary,
        bodyUpdatedAt: summariesPayload.shortSummary.body
          ? Date.now().toString()
          : this.content.shortSummary.bodyUpdatedAt,
      };
    },
    saveActionItems(payload) {
      this.generatingActionItems = false;
      if (payload.error) {
        this.$message({
          message: this.$t('knowledge.ai-drawer.error'),
          type: 'error',
        });
        return;
      }
      this.$emit('update-content', {
        id: this.content.id,
        payload: {
          actionItems: payload,
          lang: this.content.returnLanguage,
        },
      });
      this.content.actionItems = {
        ...this.content.actionItems,
        ...payload,
        bodyUpdatedAt: payload.body
          ? Date.now().toString()
          : this.content.actionItems.bodyUpdatedAt,
      };
    },
    saveKMRecommendations(payload) {
      this.generatingKMRecommendations = false;
      if (payload.error) {
        this.$message({
          message: this.$t('knowledge.ai-drawer.error'),
          type: 'error',
        });
        return;
      }
      this.$emit('update-content', {
        id: this.content.id,
        payload: {
          kmRecommendations: payload,
          lang: this.content.returnLanguage,
        },
      });
      this.content.kmRecommendations = {
        ...this.content.kmRecommendations,
        ...payload,
        bodyUpdatedAt: payload.body
          ? Date.now().toString()
          : this.content.kmRecommendations.bodyUpdatedAt,
      };
    },
    handleBrainImport({ rawFiles, settings }) {
      this.$emit('brain-import', { importType: 'pdf', rawFiles, settings });
      this.displayBrainImportModal = false;
    },
    handleBrainFilesMerge({ rawFiles, settings }) {
      this.$emit('brain-files-merge', { rawFiles, settings });
      this.displayBrainFilesMergeModal = false;
    },
    handleBrainGenerateFromPromptModal({ prompt }) {
      this.$emit('brain-generate-from-prompt', { prompt });
      this.displayBrainGenerateFromPromptModal = false;
    },
    ...mapActions('brainModule', [
      'postArticleToGuide',
      'updateArticleToGuideProcess',
    ]),
    ...mapActions('knowledgeModule', ['getFocusContentDetails']),
  },
  watch: {
    content: {
      handler(newValue, oldValue) {
        if ((newValue && !oldValue) || newValue.id !== oldValue.id) {
          this.focusedAiDrawerTab = null;
          this.displayAiDrawer =
            (!!newValue.summary &&
              !!newValue.summary.body &&
              !!newValue.summary.body.length) ||
            (!!newValue.shortSummary &&
              !!newValue.shortSummary.body &&
              !!newValue.shortSummary.body.length) ||
            (!!newValue.actionItems &&
              !!newValue.actionItems.body &&
              !!newValue.actionItems.body.length) ||
            (!!newValue.kmRecommendations &&
              !!newValue.kmRecommendations.body &&
              !!newValue.kmRecommendations.body.length);
        }
      },
      immediate: true,
      deep: true,
    },
    async $route(to, from) {
      if (to.path === from.path && to.query.staged !== from.query.staged) {
        return this.getFocusContentDetails({
          forceFetch: false,
          isStaged: this.isStagedContent,
        });
      }
    },
  },
};
</script>

<style scoped>
@media only screen and (max-device-width: 992px) {
  .side-pannel {
    display: none;
  }
}

.side-pannel {
  z-index: 0;
}

.new-article-editor {
  margin-bottom: 30vh;
}
</style>
