<template>
  <modal :show.sync="localDisplay" :show-close="false" headerClasses="p-0 m-0">
    <ModalHeader
      slot="header"
      :title="$t('knowledge.modals.content-view-setting.main-title')"
      @close="closeModal"
    />

    <form @keyup.enter.prevent.stop @keydown.enter.prevent.stop>
      <CaseParameterSelector
        :knowledge="knowledge"
        :case-parameters="caseParameters"
        :multi-select="multiSelect"
        @update-case-parameters="updateCaseParameters"
        class="content-view-setting-modal__section"
      >
        <template slot="subtitle">
          <div class="d-flex align-items-center mb-4">
            <font-awesome-icon
              :icon="['fad', 'info-circle']"
              class="info-icon"
            />
            <p class="text-small mb-0">
              {{
                $t(
                  'knowledge.modals.content-view-setting.case-parameters-selector',
                )
              }}
            </p>
          </div>
        </template>
        <template slot="main-action"> </template>
      </CaseParameterSelector>
    </form>

    <footer>
      <div class="d-flex justify-content-end mt-4">
        <!-- CANCEL -->
        <base-button-old @click="$emit('close')" type="secondary" size="sm">{{
          $t('knowledge.modals.notifications.cancel')
        }}</base-button-old>
        <!-- SUBMIT -->
        <base-button-old
          :disabled="!caseParameters.length"
          @click="$emit('choice', caseParameters)"
          type="primary"
          size="sm"
          >{{ $t('knowledge.product.notification.send') }}</base-button-old
        >
      </div>
    </footer>
  </modal>
</template>

<script>
import ModalHeader from '../CreateProductNotificationModal/ModalHeader';
import CaseParameterSelector from '../ContentViewSettingModal/CaseParameterSelector';

export default {
  name: 'cases-selection-modal',
  components: {
    ModalHeader,
    CaseParameterSelector,
  },
  props: {
    display: {
      type: Boolean,
      required: true,
    },
    content: {
      type: Object,
      default: () => ({
        caseParameters: [],
      }),
    },
    contentTitle: {
      type: String,
    },
    knowledge: {
      type: String,
    },
    multiSelect: {
      type: Boolean,
      default: true,
    },
  },
  created() {
    this.caseParameters = this.content.caseParameters;
  },
  data() {
    return {
      localDisplay: this.display,
      caseParameters: [],
    };
  },
  methods: {
    closeModal() {
      this.localDisplay = false;
      this.$emit('close');
    },
    updateCaseParameters(caseParameters) {
      this.caseParameters = caseParameters.caseSelection;
    },
  },
  watch: {
    localDisplay() {
      if (!this.localDisplay) {
        this.$emit('close');
      }
    },
  },
};
</script>

<style lang="scss">
.case-icon {
  color: $grey-5-mayday;
  margin-right: 8px;
}

.overflow-modal {
  max-height: 80vh !important;
  overflow-y: scroll !important;
  overflow-x: hidden !important;
}

.content-view-setting-modal__section {
  max-height: 60vh;
  overflow: auto;
}

.info-icon {
  color: $grey-5-mayday;
  margin-right: 8px;
}
</style>
