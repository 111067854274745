<template>
  <BaseDropdownOptionsWrapper
    :options="options"
    :is-horizontal="true"
    @select="handleDropdownSelect"
  ></BaseDropdownOptionsWrapper>
</template>

<script>
export default {
  name: 'label-category-settings-actions',

  props: {
    displayModifyIcon: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    handleDropdownSelect(e) {
      const optionId = e.detail[0];
      if (!optionId) return;
      switch (optionId) {
        case 1:
          return this.$emit('edit-label');
        case 2:
          return this.$emit('edit-icon');
        case 3:
          return this.$emit('trigger-delete');
      }
    },
    editLabel() {
      this.$emit('edit-label');
    },
    editIcon() {
      this.$emit('edit-icon');
    },
    triggerDelete() {
      this.$emit('trigger-delete');
    },
  },
  computed: {
    options() {
      return [
        {
          key: 1,
          label: this.$t('settings.general.kb-manage.actions.rename'),
          icon: ['fal', 'pen'],
        },
        ...(this.displayModifyIcon
          ? [
              {
                key: 2,
                label: this.$t(
                  'settings.general.kb-manage.actions.modify-icon',
                ),
                icon: ['fal', 'icons'],
              },
            ]
          : []),

        {
          key: 3,
          label: this.$t('settings.general.kb-manage.actions.delete'),
          icon: ['fal', 'trash'],
          type: 'danger',
          divider: true,
        },
      ];
    },
  },
};
</script>
<style lang="scss" scoped></style>
