<template>
  <div class="category">
    <settings-dropdown :force-open="forceOpen" @close="forceOpen = false">
      <template slot="title">
        <settings-dropdown-capitalized-column
          class="category-name"
          :text="category.name"
          :icon="icon"
          :color="'#b8bfcd'"
          :is-editing="editCategoryName"
          can-edit
          @edit-text="editCategoryName = true"
          @cancel="editCategoryName = false"
          @update-text="handleUpdateCategory"
        >
        </settings-dropdown-capitalized-column>

        <div class="labels-count">
          {{ $tc('settings.permissions.user-labels.count', count, { count }) }}
        </div>
        <!-- OPTIONS -->
        <div class="d-flex align-items-center">
          <!-- ADD LABEL -->
          <el-tooltip
            :content="
              $t(
                'settings.card-settings-options.content-parameters.create-tooltip',
              )
            "
            placement="top"
          >
            <base-button
              size="sm"
              variant="tertiary"
              :icon-only="true"
              icon="plus"
              @click="handleCreateLabel"
            ></base-button>
          </el-tooltip>
          <KnowledgeManageSettingsRowAction
            class="ml-1"
            :displayIsDefault="false"
            :displayModifyIcon="false"
            :user-permissions="category.userPermissions"
            @edit-label="editCategoryName = true"
            @trigger-delete="displayDangerDeleteModal = true"
          />
        </div>
      </template>
      <template slot="dropdown-content">
        <EmptyCategory v-if="labels.length === 0 && !edit.label" />
        <div
          v-for="label in labels"
          :key="label.id"
          class="row justify-content-start mr-0 align-items-center label-line"
        >
          <LabelContent
            :category="category"
            :label="label"
            @edit-label="updateLabel"
            @remove-label="$emit('delete-label', $event)"
          />
        </div>
        <NewLabel
          v-if="edit.label"
          @add-label="createLabel($event, category)"
          @reset-form="edit.label = false"
        />
      </template>
    </settings-dropdown>
    <DangerDeleteModal
      v-if="displayDangerDeleteModal"
      :display="displayDangerDeleteModal"
      type="category"
      :to-delete-name="category.name"
      :count="count"
      @choice="deleteCategory"
      @close="displayDangerDeleteModal = false"
    />
  </div>
</template>

<script>
import SettingsDropdown from '@/components/Settings/SettingsDropdown';
import SettingsDropdownCapitalizedColumn from '@/components/Settings/SettingsDropdownCapitalizedColumn';
import EmptyCategory from './EmptyCategory';
import LabelContent from './LabelContent';
import NewLabel from './NewLabel';
import KnowledgeManageSettingsRowAction from '@/views/Settings/GeneralSettings/KnowledgeManageSettings/KnowledgeManageSettingsRowAction';
import DangerDeleteModal from '@/components/Modals/DangerDeleteModal';

export default {
  name: 'category-dropdown',
  components: {
    SettingsDropdown,
    SettingsDropdownCapitalizedColumn,
    EmptyCategory,
    LabelContent,
    NewLabel,
    KnowledgeManageSettingsRowAction,
    DangerDeleteModal,
  },
  props: {
    category: Object,
  },
  computed: {
    labels() {
      return this.category.labels;
    },
    count() {
      return this.labels.length;
    },
  },
  data() {
    return {
      forceOpen: false,
      icon: 'tags',
      edit: {
        label: false,
      },
      editCategoryName: false,
      displayDangerDeleteModal: false,
      updatedCategoryLabel: null,
    };
  },
  methods: {
    handleUpdateCategory(newName) {
      this.editCategoryName = false;
      this.$emit('update-category', {
        oldName: this.category.name,
        newLabel: newName,
      });
    },
    updateLabel(event) {
      this.$emit('update-label', event);
    },
    handleCreateLabel() {
      this.forceOpen = true;
      this.edit.label = true;
    },
    createLabel(event, category) {
      event.categoryName = category.name;
      this.$emit('create-label', event);
      this.edit.label = false;
    },
    deleteCategory() {
      this.$emit('delete-category', { id: this.category.id });
      this.displayDangerDeleteModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.plus-button-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
}

.category-name {
  width: 250px;
}

.labels-count {
  font-size: 14px;
  font-weight: 500;
  width: 100px;
}
</style>
