<template>
  <div>
    <div
      class="d-flex flex-row justify-content-between align-items-center new-knowledge-header sticky-top"
    >
      <div class="d-flex flex-row">
        <div class="d-flex align-items-center">
          <div class="d-flex justify-content-center align-items-center title">
            <div
              class="title-icon-wrapper d-flex justify-content-center align-items-center"
            >
              <font-awesome-icon
                class="title-icon"
                :icon="['fal', 'book']"
              ></font-awesome-icon>
            </div>
            {{ $t('settings.new-knowledge.title') }}
          </div>
        </div>
      </div>
      <router-link to="/settings/general#knowledges">
        <base-button
          variant="tertiary"
          size="md"
          :text="$t('settings.new-knowledge.close')"
        ></base-button>
      </router-link>
    </div>
    <div v-loading="newKBLoading" class="bg-white">
      <new-knowledge-choose-type
        :step="step"
        :hostnameAlreadyExist="hostnameAlreadyExist"
        :validityLoading="validityLoading"
        :group-options="groups"
        class="new-knowledge-choose"
        @new-kb-models="models = $event"
        @check-hostname="handleCheckHostname"
      ></new-knowledge-choose-type>
    </div>

    <div
      :class="[
        'new-knowledge-footer d-flex',
        step === 1 ? 'justify-content-end' : 'justify-content-between',
      ]"
    >
      <base-button
        v-if="step > 1"
        variant="secondary"
        size="md"
        @click="handleBackClick"
        :text="$t('settings.new-knowledge.previous')"
      ></base-button>
      <base-button
        variant="primary"
        size="md"
        :text="$t('settings.new-knowledge.next')"
        @click="handleClick"
        :loading="newKBLoading"
        :disabled="
          (step === 2 && !(models.label || models.subdomain)) || newKBLoading
        "
      ></base-button>
    </div>
  </div>
</template>

<script>
import NewKnowledgeChooseType from './NewKnowledgeChooseType.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'new-knowledge',
  components: {
    NewKnowledgeChooseType,
  },
  data() {
    return {
      step: 1,
      models: {},
      newKBLoading: false,
      newConfig: {},
      hostnameAlreadyExist: false,
      validityLoading: false,
    };
  },
  computed: {
    ...mapGetters('publicKnowledgeModule', ['deploymentStatus']),
    ...mapGetters('adminModule', ['groups']),
  },
  methods: {
    handleBackClick() {
      if (this.step === 2) {
        this.models = {};
        this.step = 1;
      }
    },
    async handleClick() {
      if (this.step === 1) return (this.step = 2);
      if (this.models.subdomain) {
        this.newKBLoading = true;
        const newPublicConfig = await this.createCompanyPublicConfig({
          models: this.models,
        });
        this.newConfig = newPublicConfig;
        await this.getPendingDeploymentStatus(newPublicConfig.id);
      }
      if (this.models.label) {
        await this.addKnowledge({
          label: this.models.label,
          lang: this.models.lang,
          groups: this.models.groups,
        });
        const path = `/settings/general#knowledges`;
        this.$router.push({ path });
      }
    },
    async handleCheckHostname(hostname) {
      this.validityLoading = true;
      this.hostnameAlreadyExist = !(await this.checkHostname({
        hostname,
      }));
      this.validityLoading = false;
    },
    ...mapActions('publicKnowledgeModule', [
      'createCompanyPublicConfig',
      'checkHostname',
      'getPendingDeploymentStatus',
    ]),
    ...mapActions('adminModule', ['addKnowledge']),
  },
  watch: {
    async deploymentStatus() {
      if (
        this.deploymentStatus.deploymentStatus === 'STARTED' &&
        this.newConfig &&
        this.newConfig.knowledgeId
      ) {
        const path = `/public-settings/${this.newConfig.knowledgeId}`;
        this.$router.push({ path });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.new-knowledge-header {
  background-color: #f9f9fe;
  padding: 15px;
  border-bottom: 2px solid $grey-4-mayday;
  overflow-x: auto;
  max-width: 100%;
  height: 64px;
  z-index: 50;
  overflow: hidden;
}

.title {
  font-size: 28px;
  font-weight: 700;
}

.title-icon-wrapper {
  width: 40px;
  height: 40px;
  background-color: $blue-mayday;
  border-radius: 4.74074px;
  color: white;
  margin-right: 12px;
}

.new-knowledge-footer {
  background-color: #f9f9fe;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 60px;
  z-index: 50;
  bottom: 0;
  padding: 10px;
}
</style>
