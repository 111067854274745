<template>
  <div class="d-flex justify-content-end">
    <!-- CANCEL -->
    <base-button-old @click="sendCloseEvent" type="secondary" size="sm">{{
      $t('knowledge.modals.notifications.cancel')
    }}</base-button-old>
    <!-- PREVIOUS -->
    <base-button-old
      v-if="currentStep > 0"
      @click="sendGoToPreviousStepEvent"
      type="primary"
      outline
      size="sm"
      >{{ $t('knowledge.modals.notifications.previous') }}</base-button-old
    >
    <!-- NEXT -->
    <base-button-old
      v-if="currentStep !== lastStepValue"
      @click="sendGoToNextStep"
      type="primary"
      size="sm"
      :disabled="isNextActionDisabled"
      >{{ $t('knowledge.modals.notifications.next') }}</base-button-old
    >
    <!-- SUBMIT -->
    <base-button-old
      v-else
      @click="sendUpdateContentViewSettingEvent"
      type="primary"
      size="sm"
      >{{ $t('knowledge.product.notification.send') }}</base-button-old
    >
  </div>
</template>

<script>
export default {
  name: 'content-view-setting-modal-actions',
  props: {
    currentStep: Number,
    lastStepValue: Number,
    isGroupIdsInvalid: Boolean,
  },
  methods: {
    sendCloseEvent() {
      this.$emit('close');
    },
    sendGoToPreviousStepEvent() {
      this.$emit('go-to-previous-step');
    },
    sendGoToNextStep() {
      this.$emit('go-to-next-step');
    },
    sendUpdateContentViewSettingEvent() {
      this.$emit('update-content-view-setting');
    },
  },
  computed: {
    isNextActionDisabled() {
      return this.currentStep === 3 && this.isGroupIdsInvalid;
    },
  },
};
</script>
