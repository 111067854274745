import { render, staticRenderFns } from "./HomeV2Redirection.vue?vue&type=template&id=676c4dd6&scoped=true&"
import script from "./HomeV2Redirection.vue?vue&type=script&lang=js&"
export * from "./HomeV2Redirection.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "676c4dd6",
  null
  
)

export default component.exports