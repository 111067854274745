<template>
  <div>
    <div class="card bg-secondary shadow border-0" id="error-box">
      <div class="card-body px-md-5 py-md-5 pb-md-3 text-center">
        <div class="text-muted mb-4">
          <h1>{{ $t('landing.validated-title') }}</h1>
        </div>
        <router-link to="/login">
          <base-button-old type="primary" class="my-2">{{
            $t('landing.validated-button')
          }}</base-button-old>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'validate',
};
</script>
<style>
#error-box {
  margin-top: -50px;
  background-color: rgba(255, 255, 255, 0.82) !important;
  border-radius: 4px !important;
}
</style>
