<template>
  <section class="content__result">
    <div class="position-relative tabs-results">
      <el-tabs v-model="selectedTab" @tab-click="getPostFilter">
        <el-tab-pane name="all" v-if="tabs.all">
          <span slot="label" class="d-flex align-items-center"
            ><font-awesome-icon
              :icon="['fad', 'list-ul']"
              class="mr-2 product-icon"
            />{{ totalCount }}</span
          >
        </el-tab-pane>
        <el-tab-pane name="cases" v-if="tabs.cases">
          <div slot="label" class="d-flex align-items-center">
            <font-awesome-icon
              :icon="['fad', 'folder']"
              class="mr-2 product-icon"
            />{{ casesCount
            }}<span class="ml-1 d-none d-md-block">{{
              $t('knowledge.search-admin.collections')
            }}</span>
          </div>
        </el-tab-pane>
        <el-tab-pane name="articles" v-if="tabs.articles">
          <div slot="label" class="d-flex align-items-center">
            <img src="~assets/article_icon.svg" class="icon mr-2" />{{
              articlesCount
            }}<span class="ml-1 d-none d-md-block">{{
              $t('knowledge.search-admin.articles')
            }}</span>
          </div>
        </el-tab-pane>
        <el-tab-pane name="diagnostics" v-if="tabs.diagnostics">
          <div slot="label" class="d-flex align-items-center">
            <img src="~assets/diag_icon.svg" class="icon mr-2" />{{
              diagnosticsCount
            }}<span class="ml-1 d-none d-md-block">{{
              $t('knowledge.search-admin.diagnostics')
            }}</span>
          </div>
        </el-tab-pane>
        <el-tab-pane name="products" v-if="tabs.products">
          <div slot="label" class="d-flex align-items-center">
            <font-awesome-icon
              :icon="['fal', 'barcode-scan']"
              class="mr-2 product-icon"
            />{{ productsCount }}
            <span class="ml-1 d-none d-md-block">{{
              $t('knowledge.search-admin.products')
            }}</span>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <div v-if="results.length > 0">
      <bulk-contents-wrapper
        :items="results"
        :contents-displayed-count="counts.contentsCount"
        :show-select-all="showSelectAll"
        :show-select="!isReadOnly"
        :bulk-trigger="bulkTrigger"
        :display-clone="false"
        :is-result-list="true"
        @bulk-update-status="$emit('bulk-update-status', $event)"
        @bulk-update-attributes="$emit('bulk-update-attributes', $event)"
        @bulk-archive-contents="$emit('bulk-archive-contents', $event)"
      >
        <template
          v-slot:main-list="{
            showBulkActions,
            selectedContents,
            updateSelectedContents,
          }"
        >
          <ResultsList
            :results="results"
            :selected-contents="selectedContents"
            :show-bulk-actions="showBulkActions"
            :is-read-only="isReadOnly"
            :labelSearchedIds="labelSearchedIds"
            :searchLanguage="searchLanguage"
            @archive="bulkArchiveContents"
            @open-document="openDocument"
            @update-selected-contents="updateSelectedContents"
            @update-content-settings="updateContentSettings"
            @bulk-update-status="$emit('bulk-update-status', $event)"
          />
        </template>
      </bulk-contents-wrapper>
    </div>
    <slot v-else></slot>
  </section>
</template>

<script>
import ResultsList from './ResultsList.vue';
import BulkContentsWrapper from '@/components/ListItems/BulkContentsWrapper';

const LIMIT_COUNT_TO_DISPLAY = 10000;
export default {
  name: 'results-list-section',
  components: {
    ResultsList,
    BulkContentsWrapper,
  },
  props: {
    results: Array,
    counts: Object,
    tabs: {
      type: Object,
      default: () => {
        return {
          all: true,
          cases: false,
          articles: false,
          diagnostics: false,
          products: false,
        };
      },
    },
    moreOptions: Boolean,
    knowledge: {
      type: String,
    },
    isReadOnly: {
      type: Boolean,
      default: false,
    },
    labelSearchedIds: { type: Array, default: () => [] },
    searchLanguage: {
      type: String,
      default: 'default',
    },
  },
  data() {
    return {
      showActions: false,
      edit: {
        attributes: false,
        products: false,
      },
      selectedTab: 'all',
      bulksLimit: 50,
      bulkTrigger: false,
      cloneToTargetModal: false,
      sourceToClone: null,
    };
  },
  methods: {
    updateStatus(payload) {
      this.$emit('bulk-update-status', payload);
    },
    updateContentSettings(event) {
      this.$emit('update-content-settings', event);
    },
    bulkArchiveContents($event) {
      this.$emit('bulk-archive-contents', {
        contentIds: $event,
      });
    },
    getPostFilter() {
      let postFilter = {};
      switch (this.selectedTab) {
        case 'all':
          postFilter = {};
          break;
        case 'cases':
          this.showActions = false;
          postFilter.entity = ['cases'];
          break;
        case 'articles':
          postFilter = {
            entity: ['attached-contents'],
            type: 'Article',
          };
          break;
        case 'diagnostics':
          postFilter = {
            entity: ['attached-contents'],
            type: 'Diagnostic',
          };
          break;
        case 'products':
          this.showActions = false;
          postFilter.entity = ['new-products'];
          break;
      }
      this.bulkTrigger = !this.bulkTrigger;
      this.$emit('update-post-filters', postFilter);
    },
    showBulkActions() {
      this.showActions = !this.showActions;
    },
    firstAvailableTab() {
      return Object.keys(this.tabs).find((t) => this.tabs[t] === true);
    },
    openDocument(document) {
      this.$emit('open-document', document);
    },
  },
  watch: {
    tabs: {
      handler() {
        if (!this.tabs[this.selectedTab]) {
          this.selectedTab = this.firstAvailableTab();
          this.getPostFilter();
        }
      },
      deep: true,
    },
  },
  computed: {
    isBulkActions() {
      return (
        this.selectedTab === 'all' ||
        this.selectedTab === 'articles' ||
        this.selectedTab === 'diagnostics'
      );
    },
    showSelectAll() {
      return (
        (this.tabs.all || this.tabs.articles) &&
        this.results.length <= this.bulksLimit
      );
    },
    totalCount() {
      return (
        (this.counts.totalCount <= LIMIT_COUNT_TO_DISPLAY
          ? this.counts.totalCount
          : '+ 10 K') +
        ' ' +
        this.$t('knowledge.search-admin.results')
      );
    },
    casesCount() {
      return this.counts.casesCount <= LIMIT_COUNT_TO_DISPLAY
        ? this.counts.casesCount
        : '+ 10 K';
    },
    articlesCount() {
      return this.counts.articlesCount <= LIMIT_COUNT_TO_DISPLAY
        ? this.counts.articlesCount
        : '+ 10 K';
    },
    diagnosticsCount() {
      return this.counts.diagnosticsCount <= LIMIT_COUNT_TO_DISPLAY
        ? this.counts.diagnosticsCount
        : '+ 10 K';
    },
    productsCount() {
      return this.counts.productsCount <= LIMIT_COUNT_TO_DISPLAY
        ? this.counts.productsCount
        : '+ 10 K';
    },
  },
};
</script>

<style lang="scss" scoped>
.tabs-results {
  :deep() .el-tabs__item {
    padding: 0px 10px;
  }
}
.tab-actions {
  height: 40px;
  font-size: 14px;
  line-height: 40px;
  cursor: pointer;
}
.active-bar {
  color: #409eff;
}
.icon {
  width: 16px;
  height: 16px;
}
.select-all {
  padding-left: 8px;
  margin-bottom: 6px;
  color: $grey-6-mayday;

  &:hover {
    color: $grey-7-mayday;
  }

  .label {
    font-size: 12px;
    font-weight: bold;
    padding-left: 12px;
    cursor: pointer;
  }
}
.delete-cta {
  border-bottom: 1px solid $blue-mayday;
  cursor: pointer;
}
</style>
