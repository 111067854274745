<template>
  <div
    v-loading="true"
    class="d-flex h-100 w-100 justify-content-center align-items-center full-screen"
  ></div>
</template>

<script>
export default {
  name: 'HomeV2Redirection',
  mounted() {
    window.location.href = `${window.location.origin}/home`;
  },
};
</script>

<style lang="scss" scoped></style>
