<template>
  <div class="icons-listing">
    <div v-for="item in icons" :key="item.category">
      <small class="text-uppercase font-weight-bold">{{ item.category }}</small>
      <div class="row mx-0">
        <div v-for="iconCode in item.iconCodes" :key="iconCode[1]">
          <IconEditionItem
            @change="$emit('update', $event)"
            :icon-code="iconCode"
            :focused-icon="focusedIconValue"
            :focused-icon-color="focusedIconColor"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconEditionItem from '@/components/Modals/IconEditionModal/IconEditionItem';

export default {
  components: {
    IconEditionItem,
  },
  props: {
    focusedIconValue: String,
    focusedIconColor: String,
  },
  data() {
    return {
      icons: {
        General: {
          category: 'General',
          iconCodes: [
            ['fad', 'folder-open'],
            ['fad', 'mailbox'],
            ['fad', 'anchor'],
            ['fad', 'bags-shopping'],
            ['fad', 'bookmark'],
            ['fad', 'stars'],
            ['fad', 'magic'],
            ['fad', 'books'],
            ['fad', 'image-polaroid'],
            ['fad', 'map-marker-alt'],
            ['fad', 'users'],
            ['fad', 'medal'],
            ['fad', 'lock-alt'],
            ['fad', 'camera-home'],
            ['fad', 'wrench'],
            ['fad', 'bell-exclamation'],
          ],
        },
        Commerce: {
          category: 'Analyse & Commerce',
          iconCodes: [
            ['fad', 'shopping-cart'],
            ['fad', 'chart-pie'],
            ['fad', 'chart-area'],
            ['fad', 'abacus'],
            ['fad', 'badge-percent'],
          ],
        },
        Location: {
          category: 'Transport & Localisation',
          iconCodes: [
            ['fad', 'paper-plane'],
            ['fad', 'rocket'],
            ['fad', 'feather-alt'],
            ['fad', 'radar'],
            ['fad', 'globe-stand'],
          ],
        },
        Computer: {
          category: 'Informatique',
          iconCodes: [
            ['fad', 'desktop'],
            ['fad', 'code'],
            ['fad', 'file'],
            ['fad', 'database'],
            ['fad', 'upload'],
          ],
        },
      },
    };
  },
  methods: {
    handleChange(iconValue) {
      let chosenIcon = { ...this.focusedIcon };
      chosenIcon.value = iconValue;
      this.$emit('update', chosenIcon);
    },
  },
};
</script>

<style lang="scss" scoped>
.icons-listing {
  max-height: 30vh;
  overflow: auto;
}
</style>
