<template>
  <div>
    <div class="mb-1">
      <div class="element-title">
        {{ $t(title) }}
      </div>
      <div class="element-description" v-if="description">
        {{ $t(description) }}
      </div>
    </div>
    <div
      :class="[
        'd-flex align-items-center mt-1  gap-2',
        isEmpty ? '' : 'align-items-end',
        layout === 'vertical' && isEmpty ? 'flex-column' : 'flex-row',
      ]"
    >
      <!-- PLACEHOLDER -->
      <div
        :class="[
          'img-placeholder-wrapper',
          { 'img-placeholder-wrapper-empty': isEmpty },
        ]"
        @click="modals.img.display = true"
        @mouseover="hover = true"
        @mouseleave="hover = false"
      >
        <el-tooltip
          :content="
            $t('public-knowledge.appearances.items.brand.tooltip-download')
          "
          :disabled="isEmpty"
        >
          <img :src="value" class="img-placeholder"
        /></el-tooltip>
      </div>

      <div
        :class="[
          'd-flex flex-column justify-content-between',
          { 'download-group': isEmpty },
        ]"
      >
        <!-- UPLOAD BUTTON WHEN EMPTY SRC -->
        <base-button
          :text="$t('public-knowledge.appearances.items.brand.button-download')"
          v-if="isEmpty"
          variant="primary"
          size="sm"
          @click="modals.img.display = true"
        ></base-button>

        <!-- NO SE -->
        <el-popconfirm
          v-else
          :confirm-button-text="
            $t('public-knowledge.appearances.items.brand.confirm-delete-true')
          "
          :cancel-button-text="
            $t('public-knowledge.appearances.items.brand.confirm-delete-false')
          "
          icon="el-icon-info"
          icon-color="red"
          :title="$t('public-knowledge.appearances.items.brand.confirm-delete')"
          @confirm="$emit('delete')"
        >
          <el-tooltip
            :content="
              $t('public-knowledge.appearances.items.brand.tooltip-delete')
            "
            slot="reference"
          >
            <div>
              <div class="delete-icon-wrapper">
                <font-awesome-icon
                  :icon="['fas', 'trash']"
                  class="delete-icon"
                />
              </div>
            </div> </el-tooltip
        ></el-popconfirm>

        <div v-if="isEmpty && !hideDescription" class="img-input-advise">
          {{
            $t('public-knowledge.appearances.items.brand.download-advice', {
              width: width,
              height: height,
            })
          }}
        </div>
      </div>
      <IconEditionModal
        v-if="modals.img.display"
        :title="assetTitle"
        :description="assetDescription"
        :display="modals.img.display"
        :editionOptions="modals.img.editionOptions"
        :aspect-ratio="modals.img.aspectRatio"
        @close="modals.img.display = false"
        @choice="newValue"
      />
    </div>
  </div>
</template>

<script>
import IconEditionModal from '@/components/Modals/IconEditionModal/IconEditionModal';
export default {
  name: 'settings-image',
  components: {
    IconEditionModal,
  },
  data() {
    return {
      modals: {
        img: {
          display: false,
          editionOptions: {
            emoji: false,
            fa: false,
            upload: true,
            link: false,
          },
          aspectRatio: '',
        },
      },
      hover: false,
    };
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '',
    },
    height: {
      type: String,
      default: '',
    },
    hideDescription: {
      type: Boolean,
      default: false,
    },
    layout: {
      type: String,
      default: 'horizontal',
    },
    type: {
      type: String,
      default: 'logo',
    },
  },
  computed: {
    isEmpty() {
      return !this.value;
    },

    assetTitle() {
      if (this.type === 'banner') {
        return 'settings.company.company-settings.banner-title';
      }
      return 'settings.company.company-settings.logo-title';
    },
    assetDescription() {
      if (this.type === 'banner') {
        return 'settings.company.company-settings.banner-modal-description';
      }
      return 'settings.company.company-settings.logo-modal-description';
    },
  },
  mounted() {
    this.modals.img.aspectRatio = parseInt(this.width) / parseInt(this.height);
  },
  methods: {
    newValue(e) {
      this.$emit('update', e.value);
      this.modals.img.display = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.element-title {
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  font-weight: bold;
}

.element-description {
  font-size: 12px;
  color: $grey-7-mayday;
}

.img-placeholder-wrapper {
  max-width: 107px;
  height: 50px;
  display: flex;
  align-items: center;
  padding: 8px;
}
.img-placeholder-wrapper-empty {
  width: 107px;
  background-color: $grey-1-mayday;
  border: 1px $grey-4-mayday dashed;
  justify-content: center;
  &:hover {
    border: 1px $blue-mayday dashed;
    cursor: pointer;
    background-color: $grey-1-mayday;
    filter: drop-shadow(0px 15px 15px rgba(112, 144, 176, 0.2));
  }
  &:hover .img-placeholder {
    transform: translate(2px) scale(1.3);
  }
}

.img-placeholder {
  max-width: 100%;
  max-height: 100%;
  cursor: pointer;
}

.img-button {
  padding: 4px 8px;
  border-radius: 2px;
  width: 80px;
}

.display-on-hover {
  display: none;
}

.delete-icon-wrapper {
  width: 24px;
  height: 24px;
  cursor: pointer;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: $grey-2-mayday;
  }
}

.delete-icon {
  width: 10px;
  height: 12px;
  color: $grey-7-mayday;
}

.download-group {
  padding: 2px;
}
.img-input-advise {
  font-size: 12px;
  color: $grey-6-mayday;
}
</style>
