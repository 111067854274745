<template>
  <div class="row mx-0">
    <div
      class="knowledge-manage-settings-row flex-column align-items-center mt-2 col-12"
    >
      <div
        class="knowledge-manage-settings-row-header row justify-content-between align-items-center"
      >
        <KnowledgeManageSettingsRowHeader
          :label="label"
          :icon="focusedIcon"
          :isDefault="isDefault"
          :isDeleted="knowledgeDetails.isDeleted"
          :edit="edit"
          :id="id"
          @update-knowledge-label="$emit('edit-knowledge-label', $event)"
          @edit-label="edit.label = !edit.label"
        />
        <div class="d-flex align-items-center justify-content-end actions mr-1">
          <el-tooltip :content="$t('generic.configure')">
            <base-button
              v-if="knowledgeDetails.publicData.isPublic"
              @click="redirectToSettings('public')"
              variant="tertiary"
              :icon-only="true"
              size="sm"
              icon="cog"
              class="mr-1"
            ></base-button>

            <base-button
              v-else-if="
                companyAllowMultilingualKnowledgeSettings &&
                !hasDefaultAsSupportedLanguage
              "
              @click="redirectToSettings('private')"
              variant="tertiary"
              :icon-only="true"
              size="sm"
              icon="cog"
              class="mr-1"
            ></base-button>
          </el-tooltip>
          <KnowledgeManageSettingsRowAction
            class="cursor"
            :display-is-default="
              !knowledgeDetails.isDefault && !knowledgeDetails.isDeleted
            "
            :display-modify-icon="true"
            :user-permissions="userPermissions"
            :has-access-to-default-knowledge="hasAccessToDefaultKnowledge"
            @edit-label="edit.label = !edit.label"
            @update-is-default="updateIsDefault"
            @trigger-delete="modals.delete = true"
            @edit-icon="modals.icon = true"
          />
        </div>
      </div>
      <div class="knowledge-manage-settings-row-groups flex-column">
        <div
          v-for="group in knowledgeDetails.enrichedAccessRestrictions"
          :key="group.id"
        >
          <KnowledgeManageSettingsGroupRow :group="group" />
        </div>
      </div>
    </div>
    <div>
      <IconEditionModal
        v-if="modals.icon"
        :display="modals.icon"
        :current-icon="focusedIcon"
        description="knowledge.modals.edit-icon.description"
        @choice="updateIcon($event)"
        @delete="updateIcon({ iconType: 'fa', value: 'folder' })"
        @close="modals.icon = false"
      />
      <DangerDeleteModal
        v-if="modals.delete"
        :display="modals.delete"
        type="knowledge"
        :to-delete-name="label"
        @choice="updateIsDeleted"
        @close="modals.delete = false"
      />
    </div>
  </div>
</template>
<script>
import KnowledgeManageSettingsRowHeader from './KnowledgeManageSettingsRowHeader.vue';
import KnowledgeManageSettingsRowAction from './KnowledgeManageSettingsRowAction.vue';
import IconEditionModal from 'components/Modals/IconEditionModal/IconEditionModal';
import DangerDeleteModal from 'components/Modals/DangerDeleteModal';
import KnowledgeManageSettingsGroupRow from './KnowledgeManageSettingsGroupRow.vue';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      edit: {
        label: false,
      },
      modals: {
        icon: false,
        delete: false,
      },
    };
  },
  name: 'knowledge-manage-settings-row',
  components: {
    KnowledgeManageSettingsRowHeader,
    KnowledgeManageSettingsRowAction,
    KnowledgeManageSettingsGroupRow,
    IconEditionModal,
    DangerDeleteModal,
  },
  props: {
    knowledgeDetails: {
      type: Object,
      required: true,
    },
    isFocusedForEdit: {
      type: Boolean,
      required: true,
    },
    hasAccess: {
      type: Boolean,
      required: true,
    },
    hasAccessToDefaultKnowledge: {
      type: Boolean,
      required: true,
    },
  },
  created() {
    if (this.isFocusedForEdit) {
      this.edit.label = true;
    }
  },
  computed: {
    label() {
      return this.knowledgeDetails.label;
    },
    icon() {
      return this.knowledgeDetails.icon;
    },
    isDefault() {
      return this.knowledgeDetails.isDefault;
    },
    isDeleted() {
      return this.knowledgeDetails.isDeleted;
    },
    isPublic() {
      return this.knowledgeDetails.publicData.isPublic;
    },
    id() {
      return this.knowledgeDetails.id;
    },
    focusedIcon() {
      const isIconDefined =
        this.icon &&
        (this.icon.iconType === 'fa' || this.icon.iconType === 'url');
      return isIconDefined
        ? this.knowledgeDetails.icon
        : { iconType: 'fa', value: 'folder', color: null };
    },
    userPermissions() {
      return this.hasAccess ? this.knowledgeDetails.userPermissions : {};
    },
    hasDefaultAsSupportedLanguage() {
      return this.knowledgeDetails.supportedLanguages.includes('default');
    },
    ...mapGetters(['companyAllowMultilingualKnowledgeSettings']),
  },

  methods: {
    updateIsDefault() {
      this.$emit('edit-knowledge-is-default', {
        id: this.knowledgeDetails.id,
      });
    },
    updateIsDeleted() {
      this.modals.delete = false;
      this.$emit('edit-knowledge-is-deleted', {
        id: this.knowledgeDetails.id,
      });
    },
    updateIcon(event) {
      this.$emit('edit-knowledge-icon', {
        id: this.knowledgeDetails.id,
        iconType: event.iconType,
        value: event.value,
        color: event.color,
      });
      this.modals.icon = false;
    },
    redirectToSettings(path) {
      this.$router.push(`/${path}-settings/${this.knowledgeDetails.id}`);
    },
  },
  watch: {
    isFocusedForEdit: {
      handler() {
        if (this.isFocusedForEdit) {
          this.edit.label = true;
        }
      },
      immediate: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.knowledge-manage-settings-row-header {
  border-bottom: 1px solid $grey-2-mayday;
  padding-bottom: 2px;
}

.actions {
  width: 100px;
}

.public-settings {
  color: $grey-7-mayday;
  &:hover {
    cursor: pointer;
    color: $blue-mayday;
  }
}

.public-settings-icon-wrapper {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
