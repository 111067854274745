<template>
  <div>
    <base-dropdown class="d-flex">
      <base-button-old
        slot="title"
        type="secondary"
        class="dropdown"
        id="dropdown-button"
      >
        <font-awesome-icon
          :icon="['far', 'ellipsis-h']"
          class="options_selection"
        />
      </base-button-old>
      <div>
        <!-- RENAME -->
        <not-allowed-tooltip
          :user-permissions="userPermissions"
          permission-key="canUpdateContent"
        >
          <template v-slot:main-content="{ hasPermissions }">
            <div
              class="dropdown-item"
              @click="hasPermissions ? $emit('edit-label') : ''"
            >
              <h5 class="text-overflow m-0">
                <font-awesome-icon
                  :icon="['fad', 'edit']"
                  class="option-icon"
                />
                <small class="ml-2">{{ $t('knowledge.actions.rename') }}</small>
              </h5>
            </div>
          </template>
        </not-allowed-tooltip>

        <!-- CLONE TO KB -->
        <not-allowed-tooltip
          v-if="!isParametric"
          :user-permissions="userPermissions"
          permission-key="canCreateContent"
        >
          <template v-slot:main-content="{ hasPermissions }">
            <div
              class="dropdown-item"
              @click="hasPermissions ? $emit('open-clone-to-target-modal') : ''"
            >
              <h5 class="text-overflow m-0">
                <font-awesome-icon
                  :icon="['fad', 'clone']"
                  class="option-icon"
                />
                <small class="ml-2">{{
                  $t('knowledge.actions.clone-in-other-kb')
                }}</small>
              </h5>
            </div>
          </template>
        </not-allowed-tooltip>

        <!-- DUPLICATE IN SAME LOCATION -->
        <!-- Hide option for parametric cases -->
        <not-allowed-tooltip
          v-if="!(isParametric && itemType === 'case')"
          :user-permissions="userPermissions"
          permission-key="canCreateContent"
        >
          <template v-slot:main-content="{ hasPermissions }">
            <div
              class="dropdown-item"
              @click="hasPermissions ? $emit('duplicate-in-same-location') : ''"
            >
              <h5 class="text-overflow m-0">
                <font-awesome-icon
                  :icon="['fad', 'object-group']"
                  class="option-icon"
                />
                <small class="ml-2">{{
                  $t('knowledge.actions.duplicate-in-same-location')
                }}</small>
              </h5>
            </div>
          </template>
        </not-allowed-tooltip>

        <!-- RESTORE -->
        <div
          v-if="itemType === 'archive'"
          class="dropdown-item"
          @click="$emit('restore')"
        >
          <h5 class="text-overflow m-0">
            <font-awesome-icon :icon="['fad', 'undo']" class="option-icon" />
            <small class="ml-2">{{ $t('knowledge.actions.restore') }}</small>
          </h5>
        </div>

        <!-- FEATURE FLAG PARAMETRIC -->
        <div
          v-if="itemType === 'content' && isParametric"
          class="dropdown-item"
          @click="$emit('open-content-view-parameter-modal')"
        >
          <h5 class="text-overflow m-0">
            <font-awesome-icon :icon="['fad', 'cog']" class="option-icon" />

            <small class="ml-2">{{
              $t('knowledge.search.content.edit')
            }}</small>
          </h5>
        </div>

        <hr class="my-2 bg-gray-3" />

        <!-- ARCHIVE -->
        <not-allowed-tooltip
          :user-permissions="userPermissions"
          permission-key="canDeleteContent"
        >
          <template v-slot:main-content="{ hasPermissions }">
            <div
              class="dropdown-item"
              :class="hasPermissions ? 'archive-item' : 'archive-disabled'"
              @click="hasPermissions ? $emit('delete') : ''"
            >
              <h5 class="text-overflow m-0">
                <font-awesome-icon
                  :icon="['fad', 'archive']"
                  class="option-icon text-red"
                />
                <small class="ml-2">{{ deleteLabel }}</small>
              </h5>
            </div>
          </template>
        </not-allowed-tooltip>

        <!-- SOFT-DELETE -->
        <not-allowed-tooltip
          :user-permissions="userPermissions"
          permission-key="canDeleteContent"
        >
          <template v-slot:main-content="{ hasPermissions }">
            <div
              class="dropdown-item"
              :class="hasPermissions ? 'delete-item' : 'soft-delete-disabled '"
              @click="
                hasPermissions ? $emit('delete', { softDeleted: true }) : ''
              "
            >
              <h5 class="text-overflow m-0">
                <font-awesome-icon :icon="deleteIcon" class="option-icon" />
                <small class="ml-2">{{
                  $t('knowledge.actions.soft-delete')
                }}</small>
              </h5>
            </div>
          </template>
        </not-allowed-tooltip>
      </div>
    </base-dropdown>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import NotAllowedTooltip from '@/components/Commons/NotAllowedTooltip';

export default {
  name: 'item-options',
  components: {
    NotAllowedTooltip,
  },
  props: {
    itemType: {
      type: String,
      required: true,
    },
    userPermissions: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    deleteLabel() {
      let label = '';
      switch (this.itemType) {
        case 'content':
          label = 'Archiver';
          break;
        case 'archive':
          label = '⚠️ Supprimer définitivement';
          break;
        default:
          label = this.$t('knowledge.actions.archive');
          break;
      }
      return label;
    },
    deleteIcon() {
      return this.itemType === 'archive'
        ? ['fad', 'archive']
        : ['fad', 'trash'];
    },
    ...mapGetters(['isParametric']),
  },
};
</script>

<style lang="scss" scoped>
.dropdown {
  padding: 0px;
}

#dropdown-button {
  visibility: hidden;
  border-width: 0px;
  box-shadow: none;
  background-color: transparent;
  margin: auto;
}

.dropdown-item {
  span {
    margin-left: 6px;
  }
  p {
    margin: 0px;
    padding-left: 10px;
    padding-right: 10px;
  }
  &:hover {
    background-color: rgba($blue-mayday, 0.05);
  }
  h5 {
    display: flex;
    align-items: center;
  }
}

.options_selection {
  max-width: 15px;
  color: $grey-6-mayday;
}

.item-row:hover {
  &:hover {
    #dropdown-button {
      visibility: visible;
    }
  }
}

.option-icon {
  color: #9aa4b6;
  max-width: 14px;
}

.archive-item:hover {
  background-color: rgba($yellow-mayday, 0.1);
}

.delete-item {
  background-color: rgba($red-5-mayday, 0.1);
  small,
  .option-icon {
    color: $red-5-mayday;
  }
  &:hover {
    background-color: $red-5-mayday;
    small,
    .option-icon {
      color: white;
    }
  }
}

.dropdown-item.soft-delete-disabled {
  background-color: $grey-1-mayday;
  & .option-icon {
    color: $red-5-mayday;
  }
  & small {
    color: $grey-5-mayday;
  }
}
.dropdown-item.archive-disabled {
  & small {
    color: $grey-5-mayday;
  }
  &:hover {
    background-color: transparent !important;
  }
}
:deep() #dropdown {
  padding-bottom: 0;
}
</style>
