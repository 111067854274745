<template>
  <div>
    <!-- BREADCRUMB -->
    <DiagnosticBuilderBreadCrumb
      :parents="stepParents"
      :step="step"
      @update-parents="$emit('update-parents', $event)"
      @focus-node="$emit('focus-node', $event)"
    />

    <!-- EDITOR CARD -->
    <DiagnosticBuilderEditorCard
      :content="content"
      :editable="editable"
      :appLanguage="appLanguage"
      :is-staged-content="isStagedContent"
      @update-content="$emit('update-content', $event)"
      @delayed-update-content="$emit('delayed-update-content', $event)"
      @unlink-node="$emit('unlink-node', $event)"
      @flush-update-content="$emit('flush-update-content')"
      @generate-summary="$emit('generate-summary')"
    />

    <!-- CHILDREN -->
    <DiagnosticBuilderChildren
      :node="step"
      :content="content"
      :root-id="rootId"
      :editable="editable"
      :options="options"
      @focus-node="$emit('focus-node', $event)"
      @update-content="$emit('update-content', $event)"
      @update-children-order="$emit('update-children-order', $event)"
      @add-child="$emit('add-child', $event)"
      @update-node="$emit('update-node', $event)"
      @relocate-child="$emit('relocate-child', $event)"
      @delete-child="$emit('delete-child', $event)"
    />

    <!-- TRIGGER EDITOR -->
    <ActionMainCard
      v-if="!isParametric && editMessage && !isStagedContent"
      class="mt-4"
      :content="content"
      :actions="content.actions"
      :template-id="templateId"
      :descendent-id="rootId === content.id ? null : content.id"
      @local-update-content="$emit('local-update-content', $event)"
    />

    <!-- WORKFLOW BUILDER -->
    <WorkflowMainCard
      v-if="hasWorkflowActivated && !isStagedContent"
      class="mt-4"
      :workflow-actions="content.workflowActions"
      :content="content"
      @local-update-content="$emit('local-update-content', $event)"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import DiagnosticBuilderBreadCrumb from './DiagnosticBuilderBreadCrumb';
import DiagnosticBuilderEditorCard from './DiagnosticBuilderEditorCard';
import DiagnosticBuilderChildren from './DiagnosticBuilderChildren/DiagnosticBuilderChildren';
import WorkflowMainCard from './WorkflowBuilder/WorkflowMainCard';
import ActionMainCard from './ActionBuilder/ActionMainCard';

const TRIGGER_ACTIONS_IDS = ['salesforce.caseUpdate'];

export default {
  name: 'diagnostic-builder-main',
  components: {
    DiagnosticBuilderBreadCrumb,
    DiagnosticBuilderEditorCard,
    DiagnosticBuilderChildren,
    WorkflowMainCard,
    ActionMainCard,
  },
  props: {
    node: Object,
    content: Object,
    parents: Array,
    editMessage: {
      type: Boolean,
      default: false,
    },
    rootId: String,
    editable: {
      type: Boolean,
      default: false,
    },
    options: Object,
    templateId: {
      type: String,
      required: false,
    },
    isStagedContent: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    actions() {
      if (!this.content.actions || !this.content.actions[0]) {
        return this.initActions();
      }
      const action = this.content.actions.find(
        (el) => !TRIGGER_ACTIONS_IDS.includes(el.id),
      );
      if (!action) {
        return this.initActions();
      }
      return action.payload;
    },
    triggerAction() {
      if (!this.content.actions && !this.content.actions.length) {
        return {};
      }
      const triggerAction = this.content.actions.find((el) =>
        TRIGGER_ACTIONS_IDS.includes(el.id),
      );
      if (!triggerAction) {
        return {};
      }
      return triggerAction;
    },
    hasWorkflowActivated() {
      return this.hasCompanyPreferenceWithValue('WORKFLOW_ACTIVATED');
    },
    // IF STAGED CONTENT
    step() {
      if (this.isStagedContent) {
        return {
          ...this.node,
          label: this.node.stagedData
            ? this.node.stagedData.label
            : this.node.label,
          body: this.node.stagedData
            ? this.node.stagedData.body
            : this.node.body,
          children: this.node.children.map((c) => ({
            ...c,
            label: c.stagedData ? c.stagedData.label : c.label,
            body: c.stagedData ? c.stagedData.body : c.body,
          })),
        };
      }

      return this.node;
    },
    stepParents() {
      if (this.isStagedContent) {
        return this.parents.map((p) => ({
          ...p,
          label: p.stagedData ? p.stagedData.label : p.label,
          body: p.stagedData ? p.stagedData.body : p.body,
          children: p.children
            .filter((ch) => ch.returnLanguage === this.editingLanguage)
            .map((c) => ({
              ...c,
              label: c.stagedData ? c.stagedData.label : c.label,
              body: c.stagedData ? c.stagedData.body : c.body,
            })),
        }));
      }

      return this.parents;
    },
    ...mapGetters(['isParametric', 'hasCompanyPreferenceWithValue']),
    ...mapGetters('knowledgeModule', ['editingLanguage']),
    ...mapState(['appLanguage']),
  },
  methods: {
    initActions() {
      return {
        macro_id: '',
        assignee_group_id: '',
        assignee_user_id: '',
        email_cc: '',
        status: '',
        subject: '',
        message: '',
      };
    },
  },
};
</script>

<style></style>
