<template>
  <BaseDropdownOptionsWrapper
    :class="{ invisible: hasNoOptionsEnabled }"
    :is-horizontal="true"
    :options="options"
    @select="handleDropdownSelect"
  ></BaseDropdownOptionsWrapper>
</template>

<script>
export default {
  name: 'knowledge-manage-settings-row-action',

  props: {
    displayIsDefault: {
      type: Boolean,
      default: true,
    },
    displayModifyIcon: {
      type: Boolean,
      required: true,
    },
    userPermissions: {
      type: Object,
      default: () => ({}),
    },
    hasAccessToDefaultKnowledge: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localDisplay: false,
    };
  },
  methods: {
    handleDropdownSelect(e) {
      const optionId = e.detail[0];
      if (!optionId) return;
      switch (optionId) {
        case 1:
          return this.editLabel();
        case 2:
          return this.updateIsDefault();
        case 3:
          return this.editIcon();
        case 4:
          return this.triggerDelete();
      }
    },
    updateIsDefault() {
      this.$emit('update-is-default');
    },
    editLabel() {
      this.$emit('edit-label');
    },
    editIcon() {
      this.$emit('edit-icon');
    },
    triggerDelete() {
      this.$emit('trigger-delete');
    },
    hasPermissions(permissionKey) {
      return Boolean(this.userPermissions[permissionKey]);
    },
  },
  computed: {
    options() {
      return [
        {
          key: 1,
          label: this.$t('settings.general.kb-manage.actions.rename'),
          icon: ['fal', 'pen'],
          disabled: !this.hasPermissions('canUpdateContent'),
        },
        ...(this.displayIsDefault
          ? [
              {
                key: 2,
                label: this.$t(
                  'settings.general.kb-manage.actions.set-as-default',
                ),
                icon: ['fal', 'map-pin'],
                disabled:
                  !this.hasPermissions('canUpdateContent') ||
                  !this.hasAccessToDefaultKnowledge,
              },
            ]
          : []),
        ...(this.displayModifyIcon
          ? [
              {
                key: 3,
                label: this.$t(
                  'settings.general.kb-manage.actions.modify-icon',
                ),
                icon: ['fal', 'icons'],
                disabled: !this.hasPermissions('canUpdateContent'),
              },
            ]
          : []),
        {
          key: 4,
          label: this.$t('settings.general.kb-manage.actions.delete'),
          icon: ['fal', 'trash'],
          type: 'danger',
          divider: true,
          disabled: !this.hasPermissions('canDeleteContent'),
        },
      ];
    },
    hasNoOptionsEnabled() {
      return this.options.every((op) => op.disabled);
    },
  },
};
</script>
<style lang="scss" scoped>
.no-access-div {
  width: 24px;
}
</style>
