<template>
  <div :class="`${baseClass}`">
    <div :class="`${baseClass}__header`">
      <div
        v-for="col in ['name', 'start', 'end']"
        :key="col"
        :class="`${baseClass}__header__column ${colStyle[col]} p-0 mb-0`"
      >
        <h4 :class="`${baseClass}__header__column__heading p-0 pl-1`">
          {{ $t(`${pre}.${col}`) }}
        </h4>
      </div>
      <div
        :class="`${baseClass}__header__column__heading col-1 p-0 mb-2`"
      ></div>
    </div>
    <div
      v-for="(el, index) in ipRanges"
      :key="el.id"
      :class="`${baseClass}__row`"
    >
      <div
        v-for="col in ['name', 'start', 'end']"
        :key="col"
        :class="`${baseClass}__row__column ${colStyle[col]} p-0 mb-2`"
      >
        <el-input
          :class="`${baseClass}__row__input ${baseClass}__row__input${
            validInput(index, col) ? '' : '-invalid'
          }`"
          :placeholder="$t(`${pre}.${col}-placeholder`)"
          v-model="ipRanges[index][col]"
        ></el-input>
      </div>
      <div :class="`${baseClass}__row__delete col-1`">
        <font-awesome-icon
          :class="`${baseClass}__row__delete__btn`"
          :icon="['fal', 'times']"
          @click="removeIpRange(index)"
        />
      </div>
    </div>
    <base-button
      :text="$t(`${pre}.add`)"
      variant="primary"
      size="md"
      @click="addIpRange()"
    ></base-button>
  </div>
</template>

<script>
import { randomString } from '@/components/stringUtils';
import isValidIpAddress from '@/utils/isValidIpAddress';

const baseClass = 'ip-restriction-settings-drawer__ip-ranges';
const i18nPre =
  'settings-layout.menu-data.authentication.ip-restriction.drawer';
export default {
  name: 'ip-restriction-settings-drawer-ip-ranges',
  props: {
    ipRangesProp: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ipRanges: {
      get() {
        return this.ipRangesProp;
      },
      set(value) {
        this.$emit('change', value);
      },
    },
    validIpRanges() {
      return (
        this.ipRanges &&
        this.ipRanges
          .flatMap((_el, idx) => {
            return ['name', 'start', 'end'].map((key) =>
              this.validInput(idx, key),
            );
          })
          .every((el) => el)
      );
    },
  },
  data() {
    return {
      baseClass,
      pre: i18nPre,
      colStyle: Object.freeze({ name: 'col-5', start: 'col-3', end: 'col-3' }),
    };
  },
  methods: {
    addIpRange() {
      this.ipRanges.push({
        id: randomString(8),
        name: '',
        start: '',
        end: '',
      });
    },
    removeIpRange(idx) {
      this.ipRanges.splice(idx, 1);
    },
    validInput(idx, col) {
      const input = this.ipRanges[idx][col];
      if (col === 'name') {
        return input && input.length > 0;
      }
      return isValidIpAddress(input);
    },
  },
};
</script>

<style lang="scss" scoped>
.ip-restriction-settings-drawer {
  width: 100%;
  &__ip-ranges {
    flex-direction: column;
    align-content: space-between;
    max-height: 75vh;
    overflow: auto;

    &__header {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      position: sticky;
      top: 0px;
      z-index: 1;
      background-color: white;
    }

    &__row {
      display: flex;
      flex-direction: row;
      align-items: flex-end;

      &__header {
        position: sticky;
        top: 0px;
        z-index: 1;
        background-color: white;
      }

      &__column {
      }
      &__input {
        padding: 0 8px 0 0;
        :deep() .el-input__inner {
          padding: 0 8px;
        }
        &-invalid {
          :deep() .el-input__inner {
            border: 1px solid $red-mayday;
          }
        }
      }
      &__delete {
        padding-bottom: 16px;
        &__btn {
          &:hover {
            cursor: pointer;
            color: $red-mayday;
          }
        }
      }
    }

    &__add {
      width: fit-content;
    }

    &__submission {
      position: absolute;
      bottom: 0;
      right: 0;
      padding: 12px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }
  }
}
</style>
