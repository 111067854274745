<template>
  <el-drawer
    :append-to-body="true"
    :show-close="false"
    :visible="show"
    :with-header="false"
    :before-close="handleClose"
    size="50%"
    v-on="$listeners"
  >
    <div v-loading="isLoading" class="hub-settings-drawer">
      <HubSettingsDrawerHeader :name="view.name" @update-name="updateName" />
      <div
        v-if="show && !displayWarning"
        class="hub-settings-drawer-content-wrapper"
      >
        <HubSettingsDrawerContent
          :filters="view.filters"
          :description="view.description"
          @update-description="updateDescription"
          @add-category="addCategory"
          @update-category="updateCategory"
          @remove-category="removeCategory"
          @update-filter="updateFilter"
          @update-list-filter="updateListFilter"
          @remove-filter="removeFilter"
        />
      </div>
      <div v-else-if="show && displayWarning" class="warning-wrapper">
        <div class="warning">
          <img
            alt="warning"
            src="~/assets/empty-states/document-deletion.svg"
          />
          <div class="warning-title">
            {{ $t('settings.general.collaborative-hub.drawer.warning.title') }}
          </div>
          <div>
            {{
              $t(
                'settings.general.collaborative-hub.drawer.warning.description',
              )
            }}
          </div>

          <base-button
            :text="
              $t('settings.general.collaborative-hub.drawer.warning.cancel')
            "
            variant="danger"
            size="md"
            class="mt-3"
            @click="handleClose"
          ></base-button>
          <base-button
            :text="
              $t('settings.general.collaborative-hub.drawer.warning.resume')
            "
            variant="tertiary"
            size="md"
            class="mt-1"
            @click="handleCancelClose"
          ></base-button>
        </div>
      </div>
      <div class="hub-settings-drawer-footer">
        <base-button
          :text="$t('generic.cancel')"
          variant="tertiary"
          size="md"
          class="mr-1"
          @click="handleClose"
        ></base-button>
        <base-button
          :text="$t(`generic.${this.view.id ? 'save' : 'create'}`)"
          variant="primary"
          size="md"
          @click="handleSave"
          :disabled="!viewSavable"
        ></base-button>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import HubSettingsDrawerHeader from 'views/Settings/GeneralSettings/HubSettings/HubSettingsDrawerHeader.vue';
import HubSettingsDrawerContent from 'views/Settings/GeneralSettings/HubSettings/HubSettingsDrawerContent.vue';
import qs from 'qs';
import { mapActions } from 'vuex';

export default {
  name: 'HubSettingsDrawer',
  components: { HubSettingsDrawerContent, HubSettingsDrawerHeader },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    viewId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      displayWarning: false,
      view: {
        name: '',
        filters: {},
        description: '',
      },
      originalView: {
        name: '',
        filters: {},
        description: '',
      },
      listCategories: ['type', 'task'],
    };
  },
  methods: {
    updateName(value) {
      if (this.view) this.view = { ...this.view, name: value };
    },
    updateDescription(value) {
      if (this.view) this.view = { ...this.view, description: value };
    },
    addCategory(key) {
      this.view = {
        ...this.view,
        filters: {
          ...this.view.filters,
          [key]: {
            operator: 'AND',
            ...(this.listCategories.includes(key) ? { values: [] } : {}),
          },
        },
      };
    },
    updateCategory({ key, operator }) {
      this.view = {
        ...this.view,
        filters: {
          ...this.view.filters,
          [key]: {
            ...this.view.filters[key],
            operator,
          },
        },
      };
    },
    removeCategory(key) {
      const filters = { ...this.view.filters };
      delete filters[key];
      this.view = {
        ...this.view,
        filters,
      };
    },
    updateListFilter(category, value) {
      let newValues = null;
      newValues = this.view.filters[category].values;
      if (newValues.includes(value)) {
        newValues = newValues.filter((v) => v !== value);
      } else {
        newValues.push(value);
      }
      this.view = {
        ...this.view,
        filters: {
          ...this.view.filters,
          [category]: {
            ...this.view.filters[category],
            values: newValues,
          },
        },
      };
    },
    updateFilter({ path, oldPath, values, operator }) {
      if (oldPath) {
        const oldKeys = oldPath.split('.');
        const oldLastKey = oldKeys.pop();
        let oldNestedObject = oldKeys.reduce(
          (obj, key) => obj && obj[key],
          this.view.filters,
        );

        this.$delete(oldNestedObject, oldLastKey);
      }
      const keys = path.split('.');
      const lastKey = keys.pop();
      let nestedObject = keys.reduce((obj, key) => {
        if (!obj[key]) this.$set(obj, key, {});
        return obj[key];
      }, this.view.filters);

      this.$set(nestedObject, lastKey, { values, operator });
      this.view = {
        ...this.view,
        filters: {
          ...this.view.filters,
        },
      };
    },
    removeFilter({ path }) {
      const keys = path.split('.');
      const lastKey = keys.pop();
      let nestedObject = keys.reduce(
        (obj, key) => obj && obj[key],
        this.view.filters,
      );

      this.$delete(nestedObject, lastKey);
      this.view = {
        ...this.view,
        filters: {
          ...this.view.filters,
        },
      };
    },
    handleClose() {
      const noModifications =
        JSON.stringify(this.view) === JSON.stringify(this.originalView);
      if (this.displayWarning || noModifications) {
        this.view = {
          name: '',
          filters: {},
          description: '',
        };
        this.originalView = { ...this.view };
        this.displayWarning = false;
        return this.$emit('close');
      }
      this.displayWarning = true;
    },
    handleCancelClose() {
      this.displayWarning = false;
    },
    async handleSave() {
      this.isLoading = true;
      const tempFilters = ['type', 'assignee', 'author'].reduce((acc, key) => {
        if (this.view.filters[key]) {
          acc[key === 'type' ? 'task' : key] = {
            $Op: this.view.filters[key].operator,
            ...(key === 'type'
              ? { type: { $in: this.view.filters.type.values.join(',') } }
              : Object.entries(this.view.filters[key])
                  .filter(([subKey]) => subKey !== 'operator')
                  .reduce((subAcc, [subKey, subValue]) => {
                    subAcc[subKey] = {
                      [subValue.operator]: subValue.values.join(','),
                    };
                    return subAcc;
                  }, {})),
          };
        }
        return acc;
      }, {});
      const method = this.view.id ? 'updateTaskView' : 'createTaskView';
      const result = await this[method]({
        id: this.view.id,
        name: this.view.name,
        description: this.view.description.length
          ? this.view.description
          : null,
        filters: qs.stringify(tempFilters, {
          arrayFormat: 'brackets',
          encode: false,
        }),
      });
      this.isLoading = false;
      if (!result) {
        return this.$message.error(
          this.$t('settings.general.collaborative-hub.drawer.messages.error'),
        );
      }
      this.view = {
        ...this.view,
        id: result.id,
      };
      this.originalView = { ...this.view };
      this.$message.success(
        this.$t('settings.general.collaborative-hub.drawer.messages.success'),
      );
      this.$emit('new-view');
    },
    ...mapActions('taskModule', [
      'createTaskView',
      'updateTaskView',
      'getTaskView',
    ]),
  },
  computed: {
    viewSavable() {
      const { filters, name, description } = this.view;

      const areFilterValuesCompleted = (filter) =>
        Object.entries(filter).every(
          ([key, { values }]) => key === 'operator' || values.length > 0,
        ) && Object.keys(filter).length > 1;

      const completedFilters =
        (!filters.type || filters.type.values.length > 0) &&
        (!filters.assignee || areFilterValuesCompleted(filters.assignee)) &&
        (!filters.author || areFilterValuesCompleted(filters.author));

      return (
        name.length > 0 &&
        name.length <= 70 &&
        (!description || description.length <= 200) &&
        Object.keys(filters).length > 0 &&
        completedFilters &&
        !this.isLoading
      );
    },
  },
  watch: {
    viewId: {
      async handler(value, oldValue) {
        if (value && value !== oldValue) {
          this.isLoading = true;
          const view = await this.getTaskView(value);
          const rawFilters = qs.parse(view.filters);
          const parsedFilters = {};

          Object.keys(rawFilters).forEach((key) => {
            if (this.listCategories.includes(key)) {
              Object.keys(rawFilters[key]).forEach((subKey) => {
                if (subKey !== '$Op') {
                  parsedFilters[subKey] = {
                    operator: rawFilters[key].$Op,
                    values:
                      rawFilters[key][subKey][
                        Object.keys(rawFilters[key][subKey])[0]
                      ].split(','),
                  };
                }
              });
            } else {
              const tempFilter = {
                operator: rawFilters[key].$Op,
              };
              Object.keys(rawFilters[key]).forEach((subKey) => {
                if (subKey !== '$Op') {
                  tempFilter[subKey] = {
                    operator: Object.keys(rawFilters[key][subKey])[0],
                    values:
                      rawFilters[key][subKey][
                        Object.keys(rawFilters[key][subKey])[0]
                      ].split(','),
                  };
                }
              });
              parsedFilters[key] = { ...tempFilter };
            }
          });

          this.view = {
            id: view.id,
            name: view.name,
            description: view.description || '',
            filters: parsedFilters,
          };
          this.originalView = { ...this.view };
          this.isLoading = false;
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.hub-settings-drawer {
  height: 100vh;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.hub-settings-drawer-content-wrapper {
  flex: 1;
  overflow-y: auto;
}

.warning-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  .warning {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 32px;
    border-radius: 12px;
    background-color: $grey-1-mayday;
    text-align: center;
    font-size: 14px;
    .warning-title {
      font-weight: bold;
      font-size: 16px;
    }

    img {
      height: 100px;
      width: auto;
    }
  }
}

.hub-settings-drawer-footer {
  display: flex;
  padding: 1rem;
  justify-content: flex-end;
  background-color: $grey-1-mayday;
}
</style>
