<template>
  <div class="key-step-option">
    <side-bar-item
      slot="reference"
      :description="tooltipDescription"
      :type="type"
      @click="buttonClick"
    >
      <template v-slot:icon="{ _class }">
        <font-awesome-icon
          slot="icon"
          :class="[_class, isKeyStep ? 'active' : '']"
          :icon="['fad', 'key']"
        />
      </template>
    </side-bar-item>
    <!-- <el-popover
      :title="$t('knowledge.diagnostic-builder.side-bar.keyStep.popover-title')"
      placement="right"
      id="display-group-popover"
    >
      <div>
        <base-button-old size="sm" @click="buttonClick">
          {{ isKeyStep
          ? $t('knowledge.diagnostic-builder.side-bar.keyStep.cta-remove')
          : $t('knowledge.diagnostic-builder.side-bar.keyStep.cta-add')
          }}
        </base-button-old>
      </div>
    </el-popover>-->
  </div>
</template>

<script>
import SideBarItem from './SideBarItem';

export default {
  name: 'key-step-option',
  components: {
    SideBarItem,
  },
  props: {
    type: {
      type: String,
      default: 'diagnostic',
    },
    isKeyStep: Boolean,
  },
  computed: {
    tooltipDescription() {
      return this.isKeyStep
        ? this.$t('knowledge.diagnostic-builder.side-bar.keyStep.cta-remove')
        : this.$t('knowledge.diagnostic-builder.side-bar.keyStep.cta-add');
    },
  },
  // data() {
  //   return {
  //     tooltipDescription: this.$t(
  //       'knowledge.diagnostic-builder.side-bar.keyStep.tooltip',
  //     ),
  //   };
  // },
  methods: {
    buttonClick() {
      this.$emit('click', { type: this.isKeyStep ? 'Step' : 'keyStep' });
    },
  },
};
</script>
