var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"new-knowledge-choose-type"},[_c('div',{staticClass:"main-title d-flex flex-column justify-content-center align-items-center mb-5",class:{ disappear: _vm.step > 1 }},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t('settings.new-knowledge.choose-type.title'))+" ")]),_c('div',{staticClass:"description"},[_vm._v(" "+_vm._s(_vm.$t('settings.new-knowledge.choose-type.description'))+" ")])]),_c('div',{staticClass:"option-cards"},[_c('option-card',{staticClass:"new-option-card new-option-card-left",class:[
        { disappear: _vm.choice === 'public' && _vm.step === 2 },
        { 'chosen-left': _vm.choice === 'private' && _vm.step === 2 },
      ],attrs:{"icon":{ value: 'lock', type: 'fal', color: '#677184' },"isChosen":_vm.choice === 'private',"title":_vm.$t('settings.new-knowledge.choose-type.private.title'),"description":_vm.$t('settings.new-knowledge.choose-type.private.description'),"warning":_vm.$t('settings.new-knowledge.choose-type.private.warning')},on:{"click":function($event){_vm.choice = 'private'}}},[_c('img',{staticClass:"picture",attrs:{"slot":"picture","src":require("assets/private-new-knowledge.png"),"alt":"private-knowledge"},slot:"picture"}),_c('el-radio',{class:{ disappear: _vm.step === 2 },attrs:{"slot":"radio","label":"private"},slot:"radio",model:{value:(_vm.choice),callback:function ($$v) {_vm.choice=$$v},expression:"choice"}})],1),_c('option-card',{staticClass:"new-option-card new-option-card-right",class:[
        { disappear: _vm.choice === 'private' && _vm.step === 2 },
        { 'chosen-right': _vm.choice === 'public' && _vm.step === 2 },
      ],attrs:{"icon":{ value: 'globe', type: 'fal', color: '#677184' },"isChosen":_vm.choice === 'public',"title":_vm.$t('settings.new-knowledge.choose-type.public.title'),"description":_vm.$t('settings.new-knowledge.choose-type.public.description'),"warning":_vm.$t('settings.new-knowledge.choose-type.public.warning'),"disabled":_vm.FEATURE_FLAG},on:{"click":function($event){_vm.choice = 'public'}}},[_c('img',{staticClass:"picture",attrs:{"slot":"picture","src":require("assets/public-new-knowledge.png"),"alt":"public-knowledge"},slot:"picture"}),_c('el-radio',{class:{ disappear: _vm.step === 2 },attrs:{"slot":"radio","label":"public"},slot:"radio",model:{value:(_vm.choice),callback:function ($$v) {_vm.choice=$$v},expression:"choice"}})],1),_c('new-knowledge-public',{staticClass:"hidden-config pb-4",class:{ 'appear-config': _vm.step === 2 && _vm.choice === 'public' },attrs:{"hostnameAlreadyExist":_vm.hostnameAlreadyExist,"validityLoading":_vm.validityLoading,"group-options":_vm.groupOptions},on:{"new-kb-models":function($event){return _vm.$emit('new-kb-models', $event)},"check-hostname":function($event){return _vm.$emit('check-hostname', $event)}}}),_c('new-knowledge-private',{staticClass:"hidden-config",class:{ 'appear-config': _vm.step === 2 && _vm.choice === 'private' },attrs:{"group-options":_vm.groupOptions},on:{"new-kb-models":function($event){return _vm.$emit('new-kb-models', $event)}}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }