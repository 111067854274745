var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('BaseDropBox',{attrs:{"icon":_vm.icon,"initCollapseStatus":!_vm.hasRestriction},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"d-flex align-items-center gap-2 w-100"},[_c('div',{staticClass:"d-flex flex-column w-100"},[_c('div',{staticClass:"text-sm font-bold"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('div',{staticClass:"text-sm"},[_c('font-awesome-icon',{class:['text-md', _vm.checkCircleColor],attrs:{"icon":['fal', 'check-circle']}}),_vm._v(" "+_vm._s(_vm.$t( `settings-layout.menu-data.plugins.advanced-settings.drawer.menu.access.${_vm.accessStatus}`, ))+" ")],1)])])]},proxy:true},{key:"under-collapse-icon",fn:function(){return [_c('el-switch',{on:{"change":_vm.handleToggle},model:{value:(_vm.hasRestriction),callback:function ($$v) {_vm.hasRestriction=$$v},expression:"hasRestriction"}})]},proxy:true},{key:"collapse-content",fn:function(){return [_c('div',{staticClass:"d-flex flex-column gap-2"},[_c('div',{staticClass:"text-base font-bold"},[_vm._v(" "+_vm._s(_vm.$t( 'settings-layout.menu-data.plugins.advanced-settings.drawer.menu.access.roles', ))+" ")]),_c('el-select',{attrs:{"placeholder":_vm.$t(
              'settings-layout.menu-data.plugins.advanced-settings.drawer.menu.access.placeholder',
            ),"size":"small","multiple":"","collapse-tags":""},model:{value:(_vm.selectedRoles),callback:function ($$v) {_vm.selectedRoles=$$v},expression:"selectedRoles"}},_vm._l((_vm.roles),function(role){return _c('el-option',{key:role.id,attrs:{"label":role.name,"value":role.id}})}),1),_c('div',{staticClass:"flex justify-end items-center mt-4"},[_c('base-button',{staticClass:"mr-1",attrs:{"size":"sm","variant":"tertiary","text":_vm.$t(
                'settings-layout.menu-data.plugins.advanced-settings.drawer.menu.access.cancel',
              )},on:{"click":_vm.cancel}}),_c('base-button',{attrs:{"size":"sm","variant":"primary","text":_vm.$t(
                'settings-layout.menu-data.plugins.advanced-settings.drawer.menu.access.save',
              ),"disabled":_vm.selectedRoles.length === 0},on:{"click":_vm.save}})],1)],1)]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }