<template>
  <div v-if="focusKnowledge" class="d-flex align-items-center">
    <base-dropdown class="cursor p-0" position="right">
      <!-- DROPDOWN ELLIPSIS -->
      <a
        slot="title"
        class="cursor"
        role="button"
        data-toggle="dropdown my-auto"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <div class="nav-link-icon d-flex align-items-center">
          <base-button
            icon="ellipsis-h"
            variant="tertiary"
            size="sm"
            icon-only
          />
        </div>
      </a>
      <!-- IMPORT OPTION -->
      <not-allowed-tooltip
        v-if="options.integrationImport"
        :user-permissions="userPermissions"
        permission-key="canUpdateContent"
      >
        <template v-slot:main-content="{ hasPermissions }">
          <div
            class="d-flex dropdown-item align-items-center"
            @click="hasPermissions ? $emit('open-integration-modal') : ''"
          >
            <div class="item-icon-wrapper">
              <font-awesome-icon
                class="mr-2 item-icon text-grey"
                :icon="['fad', 'download']"
              />
            </div>
            <span>{{ $t('knowledge.actions.import-article') }} </span>
          </div>
        </template>
      </not-allowed-tooltip>

      <!-- CONTENT SETTINGS -->
      <div
        v-if="options.contentSettings"
        class="d-flex dropdown-item align-items-center cursor"
        @click="$emit('open-content-settings', !options.isSettingsDrawerOpen)"
      >
        <div class="item-icon-wrapper">
          <font-awesome-icon
            class="mr-2 item-icon text-grey"
            :icon="['fas', 'cog']"
          />
        </div>
        <span class="mb-0">
          {{
            options.isSettingsDrawerOpen
              ? $t('knowledge.actions.content-settings.close')
              : $t('knowledge.actions.content-settings.open')
          }}
        </span>
      </div>

      <!-- COPY TO CLIPBOARD -->
      <div
        v-if="options.copyToClipboard"
        class="d-flex dropdown-item align-items-center cursor"
        @click="copyUrl()"
      >
        <div class="item-icon-wrapper">
          <font-awesome-icon
            class="mr-2 item-icon text-grey"
            :icon="['fad', 'clipboard']"
          />
        </div>
        <span class="mb-0">{{
          $t('knowledge.actions.copy-to-clipboard')
        }}</span>
      </div>

      <!-- CLONE OPTION -->
      <not-allowed-tooltip
        v-if="options.cloneCase"
        :user-permissions="userPermissions"
        permission-key="canCreateContent"
      >
        <template v-slot:main-content="{ hasPermissions }">
          <div
            class="d-flex dropdown-item align-items-center"
            @click="hasPermissions ? $emit('open-clone-modal') : ''"
          >
            <div class="item-icon-wrapper">
              <font-awesome-icon
                class="mr-2 item-icon text-grey"
                :icon="['fad', 'clone']"
              />
            </div>
            <span class="mb-0"
              >{{ $t('knowledge.actions.clone-in-other-kb') }}
            </span>
          </div>
        </template>
      </not-allowed-tooltip>

      <!-- ICONS -->
      <not-allowed-tooltip
        v-if="options.editIcons"
        :user-permissions="userPermissions"
        permission-key="canUpdateContent"
      >
        <template v-slot:main-content="{ hasPermissions }">
          <div
            class="d-flex dropdown-item align-items-center"
            @click="hasPermissions && $emit('update-icon')"
          >
            <div class="item-icon-wrapper">
              <font-awesome-icon
                :icon="['fad', 'image-polaroid']"
                class="mr-2 item-icon text-grey"
              />
            </div>
            <span class="mb-0">{{ $t('knowledge.actions.change-icon') }} </span>
          </div>
        </template>
      </not-allowed-tooltip>

      <!-- MINI ICON -->
      <not-allowed-tooltip
        v-if="options.editIcons"
        :user-permissions="userPermissions"
        permission-key="canUpdateContent"
      >
        <template v-slot:main-content="{ hasPermissions }">
          <div
            class="d-flex dropdown-item align-items-center"
            @click="hasPermissions && $emit('update-mini-icon')"
          >
            <div class="item-icon-wrapper">
              <font-awesome-icon
                :icon="['fad', 'image-polaroid']"
                class="mr-2 item-icon text-grey"
              />
            </div>
            <span class="mb-0"
              >{{ $t('knowledge.actions.change-mini-icon') }}
            </span>
          </div>
        </template>
      </not-allowed-tooltip>

      <!-- ARCHIVE CASE OPTION -->
      <not-allowed-tooltip
        v-if="options.deleteCase"
        :user-permissions="userPermissions"
        permission-key="canDeleteContent"
      >
        <template v-slot:main-content="{ hasPermissions }">
          <hr v-if="!isParametric" class="my-2 bg-gray-3" />
          <div
            class="d-flex delete-items align-items-center"
            :class="hasPermissions ? 'archive-item' : 'archive-disabled'"
            @click="hasPermissions ? $emit('open-delete-modal') : ''"
          >
            <div class="item-icon-wrapper">
              <font-awesome-icon
                class="mr-2 item-icon text-red"
                :icon="['fad', 'archive']"
              />
            </div>
            <span class="mb-0">{{ $t('knowledge.actions.delete') }}</span>
          </div>
        </template>
      </not-allowed-tooltip>

      <!-- SOFT DELETE CASE OPTION -->
      <not-allowed-tooltip
        v-if="options.deleteCase"
        :user-permissions="userPermissions"
        permission-key="canDeleteContent"
      >
        <template v-slot:main-content="{ hasPermissions }">
          <div
            class="d-flex delete-items align-items-center"
            :class="
              hasPermissions ? ' soft-delete-item' : 'soft-delete-disabled'
            "
            @click="
              hasPermissions
                ? $emit('open-delete-modal', { softDeleted: true })
                : ''
            "
          >
            <div class="item-icon-wrapper">
              <font-awesome-icon
                class="mr-2 item-icon"
                :icon="['fad', 'trash']"
              />
            </div>
            <span class="mb-0">{{ $t('knowledge.actions.soft-delete') }}</span>
          </div>
        </template>
      </not-allowed-tooltip>

      <!-- CONTENT VERSION OPTION -->
      <div
        class="d-flex dropdown-item align-items-center"
        @click="$emit('open-content-version')"
      >
        <div class="item-icon-wrapper">
          <font-awesome-icon
            class="mr-2 item-icon"
            :icon="['fad', 'history']"
          />
        </div>
        <span>{{ $t('knowledge.actions.display-versions') }}</span>
      </div>

      <!-- CREATE TEMPLATE OPTIONS -->
      <not-allowed-tooltip
        v-if="options.createTemplate"
        :user-permissions="userPermissions"
        permission-key="canCreateContent"
      >
        <template v-slot:main-content="{ hasPermissions }">
          <div
            class="d-flex dropdown-item align-items-center"
            @click="hasPermissions ? openCreateTemplate() : ''"
          >
            <div class="item-icon-wrapper">
              <font-awesome-icon
                class="mr-2 item-icon text-grey"
                :icon="['fad', 'file']"
              />
            </div>
            <span class="mb-0"
              >{{ $t('knowledge.actions.create-template') }}
            </span>
          </div>
        </template>
      </not-allowed-tooltip>

      <!-- CREATE STAGED VERSION -->
      <not-allowed-tooltip
        v-if="options.createStagedVersion"
        :user-permissions="userPermissions"
        permission-key="canUpdateContent"
      >
        <template v-slot:main-content="{ hasPermissions }">
          <div
            class="d-flex dropdown-item align-items-center"
            @click="hasPermissions ? $emit('create-staged-version') : ''"
          >
            <div class="item-icon-wrapper">
              <font-awesome-icon
                class="mr-2 item-icon text-grey"
                :icon="['fad', 'file-edit']"
              />
            </div>
            <span>{{ $t('knowledge.staged-version.create') }} </span>
          </div>
        </template>
      </not-allowed-tooltip>

      <!-- ARCHIVE CONTENT OPTION -->
      <not-allowed-tooltip
        v-if="options.archiveContent"
        :user-permissions="userPermissions"
        permission-key="canDeleteContent"
      >
        <template v-slot:main-content="{ hasPermissions }">
          <hr v-if="!isParametric" class="my-2 bg-gray-3" />
          <div
            class="d-flex delete-items"
            :class="hasPermissions ? 'archive-item' : 'archive-disabled'"
            @click="hasPermissions ? openDeleteContent() : ''"
          >
            <div class="item-icon-wrapper">
              <font-awesome-icon
                class="mr-2 item-icon text-red"
                :icon="['fad', 'archive']"
              />
            </div>
            <span>{{ $t('knowledge.actions.archive') }}</span>
          </div>
        </template>
      </not-allowed-tooltip>
      <!-- SOFT DELETE CONTENT OPTION-->
      <not-allowed-tooltip
        v-if="options.deleteContent || options.deleteStagedVersion"
        :user-permissions="userPermissions"
        permission-key="canDeleteContent"
      >
        <template v-slot:main-content="{ hasPermissions }">
          <div
            class="d-flex delete-items"
            :class="
              hasPermissions ? ' soft-delete-item' : 'soft-delete-disabled'
            "
            @click="handleDeleteContent(hasPermissions)"
          >
            <div class="item-icon-wrapper">
              <font-awesome-icon
                class="mr-2 item-icon"
                :icon="['fad', 'trash']"
              />
            </div>
            <span :class="{ 'w-49': options.deleteStagedVersion }">{{
              options.deleteStagedVersion
                ? $t('knowledge.actions.delete-staged-version')
                : $t('knowledge.actions.soft-delete')
            }}</span>
          </div>
        </template>
      </not-allowed-tooltip>
    </base-dropdown>
    <ModalEvents
      modal-name="DeleteContentModal"
      :uid="deleteModalUniqueKey"
      @choice="archiveContent"
    />
    <ModalEvents
      modal-name="SoftDeleteContentModal"
      :uid="deleteModalUniqueKey"
      @choice="softDeleteContent"
    />
    <ModalEvents
      modal-name="CreateTemplateModal"
      :uid="createTemplateUniqueKey"
      @choice="handleCreateTemplate"
    />
    <ModalEvents
      modal-name="DeleteStagedVersionModal"
      :uid="deleteStagedVersionModalUniqueKey"
      @choice="$emit('delete-staged-version')"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import NotAllowedTooltip from '@/components/Commons/NotAllowedTooltip';
import { getDeskTranslatedEntityUrl } from '@/utils/adminToDeskRouteMapping';

import ModalEvents from '@/components/Modals/ModalEvents';
export default {
  name: 'case-header-options',
  components: {
    ModalEvents,
    NotAllowedTooltip,
  },
  props: {
    options: {
      type: Object,
      default: () => ({
        contentSettings: false,
        copyToClipboard: false,
        deleteCase: false,
        cloneCase: false,
        deleteContent: false,
        versionContent: false,
        integrationImport: false,
        settings: false,
        createTemplate: false,
        createStagedVersion: false,
      }),
    },
    userPermissions: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    deleteModalUniqueKey() {
      return `${this.$options.name}-${this.focusKnowledge.id}`;
    },
    deleteStagedVersionModalUniqueKey() {
      return `${this.$options.name}-staged-${this.focusKnowledge.id}`;
    },
    createTemplateUniqueKey() {
      return `${this.$options.name}-template-${this.focusKnowledge.id}`;
    },
    ...mapGetters(['isParametric']),
    ...mapGetters('adminModule', ['companyHostname']),
    ...mapGetters('knowledgeModule', [
      'focusKnowledge',
      'focusContent',
      'focusKnowledgeIsPublic',
      'editingLanguage',
      'focusKnowledgeIsMultilinguale',
    ]),
  },
  methods: {
    async copyUrl() {
      try {
        const { query, hash, params } = this.$route;

        const url = getDeskTranslatedEntityUrl({
          entityId: params.stepId || params.contentId || params.caseId,
          entityType: params.caseId ? 'Case' : 'Content',
          lang: this.editingLanguage,
          hash,
          query,
          knowledgeId: this.focusKnowledge.id,
        });

        await navigator.clipboard.writeText(window.location.origin + url);

        this.$message({
          message: this.$t(
            'knowledge.actions.copy-to-clipboard-confirmation-message',
          ),
          type: 'info',
        });
      } catch (e) {
        this.$message({
          message: this.$t('knowledge.actions.copy-to-clipboard-error-message'),
          type: 'error',
        });
      }
    },
    handleDeleteContent(hasPermissions) {
      if (!hasPermissions) return;
      if (this.options.deleteContent)
        return this.openDeleteContent({ softDeleted: true });
      this.openDeleteStagedVersion();
    },
    async openDeleteContent({ softDeleted } = {}) {
      const contentId = this.focusContent.id;
      const relatedCount = await this.countRelatedContentReferences({
        contentId,
        knowledgeId: this.focusKnowledge.id,
      });
      const backlinks = await this.getContentBacklinks(contentId);
      const automations = await this.getNodeAutomations(contentId);
      const payload = {
        component: softDeleted
          ? 'SoftDeleteContentModal'
          : 'DeleteContentModal',
        uid: this.deleteModalUniqueKey,
        props: softDeleted
          ? {
              display: true,
              type: this.focusContent.__typename.toLowerCase(),
              toDeleteName: this.isCase
                ? 'SELECTED-COLLECTION'
                : 'SELECTED-CONTENT',
              label: this.focusContent.label,
            }
          : {
              display: true,
              id: contentId,
              backlinks,
              relatedCount,
              automationsCount: automations ? automations.length : 0,
              label: this.focusContent.label,
            },
      };
      this.openModal(payload);
    },
    openDeleteStagedVersion() {
      const payload = {
        component: 'DeleteStagedVersionModal',
        uid: this.deleteStagedVersionModalUniqueKey,
        props: {
          display: true,
        },
      };
      this.openModal(payload);
    },
    async openCreateTemplate() {
      const payload = {
        component: 'CreateTemplateModal',
        uid: this.createTemplateUniqueKey,
        props: { display: true, name: this.focusContent.label },
      };
      this.openModal(payload);
    },
    async handleCreateTemplate({ name, description }) {
      const template = {
        name,
        description,
        contentId: this.focusContent.id,
      };

      let loadingMessage = this.$message({
        duration: 0,
        dangerouslyUseHTMLString: true,
        iconClass: 'display: none',
        message: `<i class="fas fa-spinner fa-spin" style="margin-right: 1em;"></i> ${this.$t(
          'knowledge.templates.create.loading',
        )}`,
      });

      try {
        const res = await this.createTemplateByContentId(template);

        if (!res) throw new Error('TEMPLATE_FAILED');
        loadingMessage.close();
        this.$message({
          message: this.$t('knowledge.templates.create.succeed'),
          type: 'success',
        });
        this.cover = false;
      } catch (e) {
        loadingMessage.close();
        this.$message({
          message: this.$t('knowledge.templates.create.failed'),
          type: 'error',
        });
      }
    },
    archiveContent(choice) {
      if (choice) this.$emit('archive');
    },
    softDeleteContent(choice) {
      if (choice)
        return this.$emit('archive', {
          contentIds: [this.focusContent.id],
          softDeleted: true,
        });
    },
    ...mapActions('modalsModule', ['openModal']),
    ...mapActions('knowledgeModule', [
      'countRelatedContentReferences',
      'createTemplateByContentId',
      'getContentBacklinks',
      'getNodeAutomations',
    ]),
  },
};
</script>

<style lang="scss" scoped>
.nav-link-icon {
  padding: 4px;
  border-radius: 4px;
  color: $grey-7-mayday;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  &:hover {
    background-color: $grey-2-mayday;
  }
  .collection-dropdown-trigger-icon {
    font-size: 16px;
    width: 16px;
    height: 16px;
  }
}
.dropdown {
  border-radius: 2px;
  padding-left: 4px;
  padding-right: 4px;
  font-size: 12px;
  cursor: pointer;
}

.dropdown-item {
  color: $grey-8-mayday;
  display: flex;
  align-items: center;

  &:hover {
    color: $blue-mayday;
  }
}

.item-icon-wrapper {
  width: 12px !important;
  height: 12px !important;
  display: flex;
  align-items: center;
  justify-self: center;
  margin-right: 8px;
}

.item-icon {
  font-size: 12px !important;
}

.delete-items {
  padding: 8px 16px;
  font-size: 14px;
  display: flex;
  align-items: center;
}
.archive-item:hover {
  background-color: rgba($yellow-mayday, 0.1);
  color: black;
}

.soft-delete-item {
  padding: 8px 16px;
  font-size: 14px;
  background-color: rgba($red-5-mayday, 0.1);
  color: $red-5-mayday;

  &:hover {
    background-color: $red-5-mayday;
    color: white;
  }
}

.delete-items.archive-disabled {
  & span {
    color: $grey-5-mayday;
  }
  &:hover {
    background-color: transparent !important;
  }
}

.delete-items.soft-delete-disabled {
  background-color: $grey-1-mayday;
  color: $red-5-mayday;
  & span {
    color: $grey-5-mayday;
  }
}
:deep() #dropdown {
  padding-bottom: 0;
}

.w-49 {
  width: 196px;
}
</style>
