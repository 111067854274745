<template>
  <div class="mb-3">
    <div class="mb-1">
      <div class="element-title">
        {{ $t(title) }}
      </div>
      <div class="element-description" v-if="description">
        {{ $t(description) }}
      </div>
    </div>
    <div class="d-flex flex-row align-items-center">
      <el-input
        :placeholder="$t(placeholder)"
        :type="type"
        v-model="textValue"
        size="mini"
        class="text-input mr-2"
        :disabled="!isModifying"
        :style="`width: ${width}`"
        @keyup.native.enter="newValue"
        @keyup.native.esc="isModifying = false"
      ></el-input>
      <div v-if="editable">
        <div v-if="isModifying" class="flex flex-row">
          <base-button
            variant="primary"
            size="sm"
            class="mr-1"
            :text="
              $t('public-knowledge.configuration.items.domain.save-button')
            "
            @click="newValue"
            :disabled="isEmpty && isSame"
          ></base-button>
          <base-button
            variant="secondary"
            size="sm"
            :text="$t('generic.cancel')"
            @click="isModifying = false"
          ></base-button>
        </div>
        <base-button
          v-else
          variant="primary"
          size="sm"
          :text="
            $t('public-knowledge.configuration.items.domain.modify-button')
          "
          @click="isModifying = true"
        ></base-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'settings-input',
  props: {
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    width: {
      type: String,
      default: '40%',
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      textValue: this.value,
      isModifying: false,
    };
  },
  computed: {
    isEmpty() {
      return !!this.textValue;
    },
    isSame() {
      return this.textValue === this.value;
    },
  },
  methods: {
    newValue() {
      if (this.isEmpty && this.isSame) return;
      this.$emit('update', this.textValue);
      this.isModifying = false;
    },
    notify(done) {
      this.$message({
        message: this.$t(
          `${
            done
              ? 'public-knowledge.registered-confirmation-message'
              : 'public-knowledge.not-registered-confirmation-message'
          }`,
        ),
        type: `${done ? 'success' : 'fail'}`,
      });
    },
  },
  watch: {
    value(newVal) {
      this.textValue = newVal;
    },
    isModifying() {
      if (!this.isModifying) {
        this.textValue = this.value;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.element-title {
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  font-weight: bold;
}

.element-description {
  font-size: 12px;
  color: $grey-7-mayday;
}
.text-input {
  font-size: 12px;
  height: 24.2px;
}
:deep() .el-input.is-disabled .el-input__inner {
  color: $grey-7-mayday;
}
:deep() .el-input .el-input__inner {
  height: 100%;
}
</style>
