<template>
  <div class="role-header d-flex">
    <div class="holder">
      <div class="role-header-logo">
        <img class="role-icon" src="~/assets/role-icon.svg" />
      </div>
    </div>

    <div
      class="role-header-info d-flex flex-column justify-content-center py-4"
    >
      <div class="title-container" v-if="!isEditing">
        <p class="title mb-0" @click="triggerLabelInput()">{{ roleName }}</p>
        <base-button
          :icon-only="true"
          icon="pen"
          variant="tertiary"
          size="md"
          @click="triggerLabelInput()"
        ></base-button>
      </div>
      <div class="title-container" v-else>
        <input
          :placeholder="
            $t('settings.permissions.role-drawer.header.name-placeholder')
          "
          class="title title-input"
          ref="roleNameEdition"
          v-model="newRoleName"
          @blur="updateTitle()"
          @keyup.enter="$event.target.blur()"
        />
      </div>
      <p class="description mb-0">
        {{ description }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'roles-drawer-header',
  props: {
    roleName: {
      type: String,
      default: '',
    },
    edit: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.isEditing = this.edit;
    this.newRoleName = this.roleName;
  },
  data() {
    return {
      description: this.$t(
        'settings.permissions.role-drawer.header.description',
      ),
      newRoleName: '',
      isEditing: false,
    };
  },
  methods: {
    triggerLabelInput() {
      this.newRoleName = this.roleName;
      this.isEditing = true;
    },
    updateTitle() {
      if (!this.newRoleName) return;

      if (this.newRoleName !== this.roleName) {
        this.$emit('update-focused-role', {
          key: 'name',
          value: this.newRoleName,
        });
      }
      this.isEditing = false;
    },
  },
  watch: {
    edit() {
      this.isEditing = this.edit;
    },
    isEditing() {
      if (this.isEditing) {
        this.$nextTick(() => this.$refs.roleNameEdition.focus());
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.role-header {
  height: 130px;
  background-color: #f8fafe;
  border-bottom: 1px solid $grey-4-mayday;
  position: relative;
}

.holder {
  width: 250px;
  flex: 0 0 auto;
}

.role-header-info {
  margin-right: 30px;
  .title {
    color: black;
    font-size: 28px;
    font-weight: bold;
    &:hover {
      cursor: pointer;
    }
  }
  .description {
    color: $grey-8-mayday;
    font-size: 75%;
  }
}

.role-header-logo {
  position: absolute;
  background-color: white;
  border-radius: 8px;
  box-shadow: 15px 17px 40px #ebebeb;
  width: 10rem;
  height: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 25px;
  left: 30px;
  z-index: 1;

  .role-icon {
    object-fit: contain;
    width: 6.5rem;
    height: 6.5rem;
  }
}

.modify-icon {
  max-width: 20px;
  cursor: pointer;
  color: $grey-5-mayday;
  padding: 2px;
  &:hover {
    border-radius: 3px;
    background-color: #f2f5f9;
    color: $blue-mayday;
  }
}

.title-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  min-height: 40px;
}

.title-input {
  padding: 0px;
  outline: none;
  border: none;
  background-color: #f8fafe;
}
</style>
