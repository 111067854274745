<template>
  <div class="dropzone mb-3 dz-clickable dropzone-multiple">
    <div class="fallback">
      <div class="custom-file">
        <input
          type="file"
          class="custom-file-input"
          id="projectCoverUploads"
          :multiple="multiple"
        />
        <label class="custom-file-label" for="projectCoverUploads"
          >Choose file</label
        >
      </div>
    </div>
    <ul
      class="dz-preview dz-preview-multiple list-group list-group-lg list-group-flush"
      :class="previewClasses"
      ref="previewMultiple"
    >
      <li class="list-group-item px-0">
        <div class="row align-items-center">
          <div data-dz-thumbnail class="col-1">
            <font-awesome-icon :icon="icon" class="word-icon" />
          </div>
          <div class="col-9">
            <p class="mb-1" data-dz-name>...</p>
            <p class="small text-muted mb-0" data-dz-size>...</p>
          </div>
          <div class="col-1">
            <base-button-old
              data-dz-remove="true"
              type="secondary"
              outline
              size="sm"
              class="delete-btn"
            >
              <font-awesome-icon
                :icon="['fad', 'trash']"
                class="delete-upload"
              />
            </base-button-old>
          </div>
        </div>
      </li>
    </ul>
    <div class="dz-message row d-flex justify-content-center">
      <font-awesome-icon
        :icon="['fad', 'cloud-upload']"
        size="2x"
        class="col-12"
      />
      <small class="col-10 mt-2">{{
        $t('knowledge.article-builder.side-bar.imported-docx.modal.drop-zone')
      }}</small>
    </div>
  </div>
</template>
<script>
export default {
  name: 'dropzone-file-upload',
  props: {
    options: {
      type: Object,
      default: () => ({}),
    },
    value: [String, Object, Array],
    url: {
      type: String,
      default: 'http://',
    },
    multiple: Boolean,
    previewClasses: [String, Object, Array],
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  data() {
    return {
      currentFile: null,
      files: [],
      showList: false,
    };
  },
  computed: {
    showDropZone() {
      if (this.multiple === true) return true;
      if (this.files && this.files.length == 0) return true;
      return false;
    },
    icon() {
      if (!this.options || !this.options.acceptedFiles)
        return ['fad', 'file-word'];
      if (this.options.acceptedFiles.includes('application/pdf'))
        return ['fal', 'file-pdf'];
      return ['fad', 'file-word'];
    },
  },
  methods: {
    async initDropzone() {
      let Dropzone = await import('dropzone');
      Dropzone = Dropzone.default || Dropzone;
      Dropzone.autoDiscover = false;
      let self = this;
      let preview = this.$refs.previewMultiple;
      let finalOptions = {
        ...this.options,
        url: this.url,
        thumbnailWidth: null,
        thumbnailHeight: null,
        previewsContainer: preview,
        previewTemplate: preview.innerHTML,
        maxFiles: !this.multiple ? 1 : null,
        acceptedFiles: !this.options.acceptedFiles
          ? null
          : this.options.acceptedFiles,
        init: function () {
          this.on('addedfile', function (file) {
            if (!self.multiple && self.currentFile) {
              this.removeFile(self.currentFile);
            }
            self.currentFile = file;
          });
        },
      };
      this.dropzone = new Dropzone(this.$el, finalOptions);
      preview.innerHTML = '';
      let evtList = [
        'drop',
        'dragstart',
        'dragend',
        'dragenter',
        'dragover',
        'addedfile',
        'removedfile',
        'thumbnail',
        'error',
        'processing',
        'uploadprogress',
        'sending',
        'success',
        'complete',
        'canceled',
        'maxfilesreached',
        'maxfilesexceeded',
        'processingmultiple',
        'sendingmultiple',
        'successmultiple',
        'completemultiple',
        'canceledmultiple',
        'totaluploadprogress',
        'reset',
        'queuecomplete',
      ];
      evtList.forEach((evt) => {
        this.dropzone.on(evt, (data) => {
          this.$emit(evt, data);

          if (evt === 'addedfile') {
            if (
              this.files.findIndex((file) => data.name === file.name) === -1
            ) {
              this.files.push(data);
              this.$emit('change', this.files);
            } else {
              this.$message('file already in the waiting list');
            }
          } else if (evt === 'removedfile') {
            let index = this.files.findIndex(
              (f) => f.upload.uuid === data.upload.uuid,
            );
            if (index !== -1) {
              this.files.splice(index, 1);
            }
            this.$emit('change', this.files);
          }
        });
      });
    },
  },
  async mounted() {
    this.initDropzone();
  },
  watch: {
    showDropZone() {
      const dropzone = document.querySelector('div.dz-message');
      if (!this.showDropZone) {
        dropzone.style.setProperty('display', 'none', 'important');
      } else {
        dropzone.style.setProperty('display', 'flex');
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.delete-upload {
  color: $grey-5-mayday;
  max-width: 16px;
  &:hover {
    color: $red-mayday;
  }
}

.word-icon {
  max-width: 16px;
}
</style>
