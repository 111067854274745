<template>
  <not-allowed-tooltip
    :user-permissions="userPermissions"
    permission-key="canCreateContent"
    placement="top"
  >
    <template v-slot:main-content="{ hasPermissions }">
      <div class="new-case-button">
        <div v-show="edit" class="case-input px-4">
          <font-awesome-icon
            class="mr-2 case-input-icon"
            :icon="['fal', 'pen']"
          />
          <input
            class="new-case-input px-1"
            ref="newChildInput"
            placeholder="Nom du nouveau paramètre"
            v-model="label"
            @blur="createCase()"
            @keyup.enter="$event.target.blur()"
            @keyup.esc="abortEdition()"
          />
        </div>
        <div
          class="new-case-option px-4 mb-2 d-flex align-items-center"
          @click="hasPermissions ? editNewCase() : ''"
          v-if="!edit"
        >
          <font-awesome-icon
            class="mr-3 ml-1 add-icon"
            :icon="['fad', 'plus']"
          />
          Ajouter une collection
        </div>
      </div>
    </template>
  </not-allowed-tooltip>
</template>

<script>
import NotAllowedTooltip from '@/components/Commons/NotAllowedTooltip';
export default {
  name: 'new-case-button',
  components: {
    NotAllowedTooltip,
  },
  props: {
    parentId: String,
    userPermissions: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      edit: false,
      label: '',
    };
  },
  methods: {
    editNewCase() {
      this.label = '';
      this.edit = true;
      this.$nextTick(() => {
        this.$refs.newChildInput.focus();
      });
    },
    abortEdition() {
      this.label = '';
      this.edit = false;
    },
    createCase() {
      if (this.label !== '') {
        this.$emit('add-case', {
          parentId: this.parentId,
          label: this.label,
        });
        this.label = '';
        this.edit = false;
      } else {
        this.abortEdition();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.new-case-input {
  min-width: 300px;
  width: fit-content;
  border-radius: 2px;
  border: none;
  line-height: 24px;
  padding: 2px;
  color: $blue-mayday;
}

.case-input {
  display: flex;
  align-items: center;
}

.case-input-icon {
  min-width: 24px;
  min-height: 24px;
  padding: 6px;
  border-radius: 2px;
  color: $grey-5-mayday;
}

.new-case-option {
  padding: 4px 0px;
  &:hover {
    background-color: $grey-1-mayday;
    border-color: $blue-mayday;
    .add-icon {
      color: $blue-mayday;
    }
  }
}

.add-icon {
  max-width: 16px;
  color: $grey-5-mayday;
}
</style>
