import { render, staticRenderFns } from "./HubSettings.vue?vue&type=template&id=187417bf&scoped=true&"
import script from "./HubSettings.vue?vue&type=script&lang=js&"
export * from "./HubSettings.vue?vue&type=script&lang=js&"
import style0 from "./HubSettings.vue?vue&type=style&index=0&id=187417bf&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "187417bf",
  null
  
)

export default component.exports