<template>
  <div class="text-center">
    <div class="row">
      <card class="col-8 mx-auto mt-6">
        <h1 class="display-3 my-4">
          {{ $route.query.message }}
          <router-link to="/dashboard"
            >ou retourner à la page d'accueil</router-link
          >
        </h1>
        <img id="gif" src="~assets/404.gif" class="mb-4" />
      </card>
    </div>
  </div>
</template>

<script>
export default {
  name: 'invalid-token',
};
</script>

<style></style>
