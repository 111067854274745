<template>
  <div class="first-navigator-node mb-3">
    <!-- HEADER LABEL -->
    <header-label
      :label="label"
      :user-permissions="userPermissions"
      @focus="$emit('focus')"
      @add-child="$emit('add-child', $event)"
    >
      <div class="ml-auto">
        <TranslationDropdown
          v-if="focusKnowledgeIsMultilinguale"
          class="mx-2"
          :languages="focusKnowledgeLanguages"
          :current-language="editingLanguage"
          tight
          @change-language="switchEditingLanguage($event)"
        />
      </div>
    </header-label>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';

import HeaderLabel from './HeaderLabel';
import TranslationDropdown from '@/components/TranslationDropdown';

export default {
  name: 'navigator-header',
  components: {
    HeaderLabel,
    TranslationDropdown,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    userPermissions: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters('knowledgeModule', [
      'focusKnowledgeLanguages',
      'editingLanguage',
      'focusKnowledgeIsMultilinguale',
    ]),
  },
  methods: {
    headerLabelClick() {
      this.$emit('focus');
    },
    ...mapActions('knowledgeModule', ['switchEditingLanguage']),
  },
};
</script>
<style lang="scss" scoped>
.vertical-divider {
  max-height: 18px;
}

.node-item {
  padding-left: 5px;
}

.node-item .focus {
  z-index: 0;
}

/*Navigation*/

.message {
  color: #535353;
  font-size: 14px;
}

#options_selection {
  max-width: 15px;
  cursor: pointer;
}

.options_selection {
  max-width: 15px;
  cursor: pointer;
  color: #9aa4b6;
}

/* Dropdown menu*/

.dropdown {
  padding: 0px;
}

.dropdown-item:hover {
  background-color: rgba($blue-mayday, 0.05);
  cursor: pointer;
}
.dropdown-item :hover i {
  color: $blue-mayday;
}

.dropdown-item p {
  margin: 0px;
  padding-left: 10px;
  padding-right: 10px;
}

.dropdown-item span {
  margin-left: 6px;
}

.dropdown-button {
  border-width: 0px;
  box-shadow: none;
  background-color: transparent;
}

.pointer {
  cursor: pointer;
}
</style>
