<template>
  <div id="knowledge-restrictions" v-loading="!knowledgesLoaded">
    <p class="mb-5 mt-3">
      {{
        $t(
          'components.modals.add-company-group.set-group-name.access-restrictions',
        )
      }}
    </p>
    <div
      v-for="knowledge in fetchedKnowledges"
      :key="knowledge.id"
      class="row align-items-center mb-2"
    >
      <knowledge-restriction-card
        v-if="knowledgesLoaded"
        :knowledge="knowledge"
        :is-restricted="getIsRestricted(knowledge.id)"
        :restricted-nodes="getRestrictedNodes(knowledge.id)"
        :node-ids-to-expand="getNodeIdsToExpand(knowledge.id)"
        @update-knowledge-restriction="
          updateKnowledgeRestriction(knowledge.id, $event)
        "
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import KnowledgeRestrictionCard from './KnowledgeRestrictionCard';

export default {
  name: 'set-knowledge-restrictions',
  props: {
    currentKnowledges: {
      type: Array,
      default: () => {
        return [];
      },
    },
    groupId: {
      type: String,
      required: false,
    },
  },
  components: {
    KnowledgeRestrictionCard,
  },
  computed: {
    knowledgesLoaded() {
      return (
        (this.knowledgeRestrictions.length > 0 ||
          this.currentKnowledges.length === 0) &&
        !this.isLoadingAccessRestrictions
      );
    },
    ...mapGetters('adminModule', ['adminKnowledges']),
  },
  data() {
    return {
      isLoadingAccessRestrictions: false,
      knowledgeRestrictions: [],
      fetchedKnowledges: [],
      knowledgeIdToAccessRestrictionsData: {},
    };
  },
  async mounted() {
    if (!this.groupId) {
      this.fetchedKnowledges = this.adminKnowledges.map((knowledge) => ({
        ...knowledge,
        isRestricted: null,
        restrictedNodes: [],
        nodeIdsToExpand: [],
      }));
      return;
    }

    this.isLoadingAccessRestrictions = true;
    this.fetchedKnowledges =
      await this.$services.hierarchies.getAccessRestrictions(this.groupId);
    this.fetchedKnowledges.forEach((hierarchy) => {
      const { restrictedNodes, nodeIdsToExpand, ...knowledge } = hierarchy;
      this.knowledgeIdToAccessRestrictionsData[knowledge.id] = {
        restrictedNodes,
        nodeIdsToExpand,
        isRestricted: knowledge.isRestricted,
      };
      if (
        knowledge.isRestricted !== null &&
        knowledge.isRestricted !== undefined
      ) {
        this.knowledgeRestrictions = this.knowledgeRestrictions.concat({
          knowledgeId: knowledge.id,
          knowledgeValue: knowledge.value,
          knowledgeName: knowledge.label,
          isRestricted: knowledge.isRestricted,
          restrictedNodes: restrictedNodes,
        });
      }
    });
    this.isLoadingAccessRestrictions = false;
    this.$emit('update-restrictions', this.knowledgeRestrictions);
  },
  methods: {
    getIsRestricted(knowledgeId) {
      const restrictionsData =
        this.knowledgeIdToAccessRestrictionsData[knowledgeId];
      return restrictionsData ? restrictionsData.isRestricted : null;
    },
    getRestrictedNodes(knowledgeId) {
      const restrictionsData =
        this.knowledgeIdToAccessRestrictionsData[knowledgeId];
      return restrictionsData ? restrictionsData.restrictedNodes : [] || [];
    },
    getNodeIdsToExpand(knowledgeId) {
      const restrictionsData =
        this.knowledgeIdToAccessRestrictionsData[knowledgeId];

      return restrictionsData ? restrictionsData.nodeIdsToExpand : [] || [];
    },
    updateKnowledgeRestriction(knowledgeId, e) {
      this.knowledgeRestrictions = this.knowledgeRestrictions.filter(
        (o) => String(o.knowledgeId) !== String(knowledgeId),
      );
      if (e) {
        this.knowledgeRestrictions = this.knowledgeRestrictions.concat([e]);
      }
    },
  },
  watch: {
    knowledgeRestrictions() {
      this.$emit('update-restrictions', this.knowledgeRestrictions);
    },
  },
};
</script>

<style scoped>
.nav-item {
  border: 1px solid #f2f5f9;
  border-radius: 3px;
}

.vertical-divider {
  max-height: 18px;
}

.flag-icon {
  height: 18px;
  width: 18px;
}
</style>
