<template>
  <BaseModal
    :size="editionOptions.fa && !customWidth ? 'md' : 'sm'"
    button-size="md"
    :sidebar="false"
    :title="$t(title)"
    :description="$t(description)"
    :open="localDisplay"
    :disabled="disabled"
    :confirm-text="$t(confirmText)"
    :loading="loading"
    :custom-width="customWidth"
    :custom-height="customHeight"
    @confirm="userChoice()"
    @close="$emit('close')"
  >
    <div class="mt-3 relative">
      <el-tabs
        v-model="focusedTab"
        @tab-click="handleClick"
        :class="{ 'one-tab-display': hasOneTab }"
      >
        <el-tab-pane name="fa" v-if="editionOptions.fa">
          <span slot="label"
            ><font-awesome-icon class="icon-tab" :icon="['fal', 'icons']" />
            {{ $t('knowledge.modals.edit-icon.fa-label') }}
          </span>
          <IconEditionModalFaTab
            :focusedIconValue="focusedIcon.value"
            :focusedIconColor="focusedIcon.color"
            @update="focusedIcon.value = $event"
          />
          <div v-if="focusedIcon.value">
            <p class="mt-4 mb-3 font-weight-bold">
              {{ $t('knowledge.modals.edit-icon.choose-color') }}
            </p>
            <ColorPicker
              class="color-wrapper"
              :focusedColor="focusedIcon.color"
              @focus-color="focusedIcon.color = $event"
            />
          </div>
        </el-tab-pane>
        <el-tab-pane name="upload" v-if="editionOptions.upload">
          <span slot="label"
            ><font-awesome-icon
              class="icon-tab"
              :icon="['fal', 'cloud-upload']"
            />
            {{ $t('knowledge.modals.edit-icon.upload-label') }}
          </span>
          <DropzoneFileUpload
            :loading="loading"
            v-bind="{ aspectRatio: aspectRatio }"
            @update-attachments="updateAttachments"
            @validate-cropped-attachment="userChoice()"
          />
        </el-tab-pane>
        <el-tab-pane name="link" v-if="editionOptions.link">
          <span slot="label"
            ><font-awesome-icon class="icon-tab" :icon="['fal', 'link']" />
            {{ $t('knowledge.modals.edit-icon.url-label') }}
          </span>
          <IconEditionModalLinkTab
            ref="link"
            v-model="focusedIcon.value"
            @update="focusedIcon.value = $event"
          />
        </el-tab-pane>
      </el-tabs>
      <base-button
        v-if="!hasOneTab"
        :class="['position-absolute top-1 right-0']"
        icon="trash"
        variant="danger"
        :text="$t('knowledge.modals.edit-icon.delete')"
        @click.prevent="deleteIcon()"
      ></base-button>
    </div>
  </BaseModal>
</template>
<script>
import { mapGetters } from 'vuex';
import IconEditionModalFaTab from '@/components/Modals/IconEditionModal/IconEditionModalFaTab';
import IconEditionModalLinkTab from '@/components/Modals/IconEditionModal/IconEditionModalLinkTab';
import ColorPicker from '@/components/Modals/IconEditionModal/ColorPicker';
import DropzoneFileUpload from '@/components/DropzoneFileUpload';
import FileUploadMixin from '@/mixins/KnowledgeMixin/FileUploadMixin';

export default {
  name: 'icon-edition-modal',
  mixins: [FileUploadMixin],
  components: {
    IconEditionModalFaTab,
    IconEditionModalLinkTab,
    ColorPicker,
    DropzoneFileUpload,
  },
  props: {
    display: Boolean,
    aspectRatio: {
      type: Number,
      required: false,
    },
    currentIcon: {
      type: Object,
      default: () => {
        return {
          iconType: 'fa',
          value: '',
          color: '#d5dae3',
        };
      },
    },
    editionOptions: {
      type: Object,
      default: () => {
        return {
          emoji: false,
          fa: true,
          upload: true,
          link: true,
        };
      },
    },
    title: {
      type: String,
      default: 'knowledge.modals.edit-icon.title',
    },
    description: {
      type: String,
    },
    customWidth: String,
    customHeight: String,
  },
  computed: {
    confirmText() {
      if (this.focusedTab !== 'upload')
        return 'knowledge.modals.actions.validate';
      return null;
    },
    disabled() {
      return !(this.focusedIcon.value || this.attachments.length);
    },
    hasOneTab() {
      return (
        Object.values(this.editionOptions).filter((el) => el === true)
          .length === 1
      );
    },
    headerStyle() {
      return {
        backgroundColor: this.focusedIcon.color
          ? `${this.focusedIcon.color}0D`
          : '#f9fbff',
      };
    },
    iconColor() {
      return {
        color: this.focusedIcon.color ? this.focusedIcon.color : '#b8bfcd',
      };
    },
    iconStyle() {
      return this.focusedIcon.value === 'folder' ? 'fas' : 'fad';
    },
    ...mapGetters(['companyPrimaryColorPreference']),
  },
  data() {
    return {
      localDisplay: this.display,
      focusedIcon: { ...this.currentIcon },
      focusedTab: 'fa',
      attachments: [],
      loading: false,
    };
  },
  mounted() {
    if (!this.editionOptions.fa) this.focusedTab = 'upload';
  },
  methods: {
    userChoice() {
      if (this.focusedTab == 'fa')
        this.$emit('choice', { ...this.focusedIcon, iconType: 'fa' });
      else if (this.focusedTab == 'upload') this.uploadAttachment();
      else if (this.focusedTab == 'link' && this.$refs.link.urlValidation())
        this.$emit('choice', this.focusedIcon);
    },
    deleteIcon() {
      this.$emit('delete');
      this.$emit('close');
    },
    updateAttachments(attachments) {
      this.attachments = attachments;
    },
    async uploadAttachment() {
      if (this.attachments.length) {
        this.loading = true;
        await this.mxFileUpload([this.attachments[0]], (error, response) => {
          if (error) {
            const { message } = error;
            alert(message);
          } else {
            this.focusedIcon.value = response[0].url;
            this.$emit('choice', { ...this.focusedIcon, iconType: 'url' });
          }
          this.loading = false;
        });
      } else {
        this.$emit('close');
      }
    },
    handleClick() {
      if (this.focusedTab == 'fa') {
        this.focusedIcon = this.currentIcon;
      } else {
        this.focusedIcon = {
          iconType: 'url',
          value: '',
          color: null,
        };
      }
    },
  },
  watch: {
    localDisplay() {
      if (!this.localDisplay) this.$emit('close');
    },
  },
};
</script>
<style lang="scss" scoped>
.icon {
  width: 21px;
  height: 21px;
}
.tab-delete {
  height: 40px;
  font-size: 14px;
  line-height: 40px;
  color: $grey-5-mayday;
  cursor: pointer;
}
.blue-mayday {
  color: $blue-mayday;
}
.grey-5 {
  color: $grey-5-mayday;
}
.el-tabs__item span {
  color: $grey-5-mayday;
}
.el-tabs__item.is-active span {
  color: #409eff;
}

.one-tab-display {
  :deep() .el-tabs__header {
    display: none;
  }
}

.one-tab-display-delete {
  display: none;
}
</style>
