<template>
  <div class="cancel-container">
    <div class="title-container">
      <strong class="cancel-title">
        {{ $t('settings.permissions.role-drawer.cancel-confirm.title') }}
      </strong>
      <img class="empty-state-icon" src="~assets/role-cancel-confirm.svg" />
    </div>

    <div class="text-container">
      <span>
        {{ $t('settings.permissions.role-drawer.cancel-confirm.description') }}
      </span>
      <span>
        {{ $t('settings.permissions.role-drawer.cancel-confirm.warning') }}
      </span>
    </div>
    <div class="buttons-container">
      <base-button
        variant="secondary"
        size="md"
        class="mr-1"
        @click="$emit('cancel')"
        :text="$t('generic.cancel')"
      ></base-button>
      <base-button
        variant="danger"
        size="md"
        @click="$emit('confirm')"
        :text="$t('generic.confirm')"
      ></base-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'roles-drawer-cancel-confirm',
};
</script>

<style lang="scss" scoped>
.cancel-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  text-align: center;
  padding: 36px;
  border-radius: 8px;
  min-height: fit-content;
  min-width: fit-content;
  background-color: $grey-1-mayday;
}
.empty-state-icon {
  width: 150px;
}
.title-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.text-container {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  font-weight: bold;
  color: $grey-7-mayday;
}
.buttons-container {
  display: flex;
  gap: 12px;
}
</style>
