<template>
  <div class="custom-table">
    <!-- 
          ######################### TABLE OPTIONS #########################
    -->
    <div
      class="row justify-content-end my-4"
      v-if="options.bulkActions || options.search"
    >
      <!-- 
          #### BULK ACTIONS ####
      -->
      <div class="d-flex col" v-if="options.bulkActions && selection.length">
        <div class="col-auto">
          <base-button-old type="secondary" @click="clearSelection" outline>
            {{ $t('components.custom-table.actions.clear-selection') }}
            <i class="el-icon-circle-close el-icon--right"></i>
          </base-button-old>
        </div>
        <div class="col-auto">
          <template>
            <slot name="selection-edition" v-bind:selection="selection"></slot>
          </template>
        </div>
      </div>
      <!-- 
          #### SEARCH ####
      -->
      <div class="col-3 mr-4" v-if="options.search">
        <el-input
          :placeholder="
            $t('components.custom-table.actions.search-placeholder')
          "
          v-model="dataOptions.search"
        ></el-input>
      </div>
    </div>

    <!-- 
        ######################### MAIN TABLE #########################
    -->

    <el-table
      v-if="isReady"
      :data="data"
      :border="border"
      ref="mayday-table"
      class="mayday-table"
      :max-height="maxHeight"
      :default-sort="defaultSort"
      @sort-change="sortChange"
      @selection-change="selectionChange"
      @filter-change="filterChange"
    >
      <template v-slot:empty>
        <slot name="empty"></slot>
      </template>
      <el-table-column
        type="selection"
        width="55"
        v-if="options.bulkActions"
      ></el-table-column>
      <el-table-column type="expand" fixed v-if="options.expandableRows">
        <template slot-scope="scope">
          <slot name="expand" v-bind="scope.row"></slot>
        </template>
      </el-table-column>
      <el-table-column
        v-for="header in headers"
        :prop="getAppropriateColumnMeta(header)"
        :key="header.key"
        :column-key="header.key || getAppropriateColumnMeta(header)"
        :label="header.label"
        :sortable="header.sortable ? 'custom' : null"
        :width="header.width"
        :filters="header.filterable ? getFilters(header.key) : null"
        :fixed="header.fixed || false"
        :class-name="header.className || ''"
      >
        <template slot-scope="scope">
          <template v-if="header.template">
            <slot
              :name="header.prop"
              :row="scope.row"
              :column="scope.row[header.prop]"
              :index="scope.$index"
            ></slot>
          </template>
          <template v-else>{{ getColumnData(scope.row, header) }}</template>
        </template>
      </el-table-column>
    </el-table>
    <div
      class="d-flex align-items-center justify-content-between pb-4 mt-5"
      v-if="
        dataOptions.totalPageCount ||
        options.selectRowsCount ||
        options.showItemCount
      "
    >
      <!-- 
          ######################### WHITE SPACE #########################
      -->
      <div
        class="item-count"
        v-if="options.selectRowsCount || options.showItemCount"
      >
        <template v-if="options.showItemCount && !!data.length">
          {{
            $t('components.custom-table.item-count', {
              count_begin: itemCountBegin,
              count_end: itemCountEnd,
              total: dataCount,
            })
          }}
        </template>
      </div>
      <!-- 
          ######################### CENTERED PAGINATION #########################
      -->
      <div class="col" v-if="options.pagination">
        <div class="text-center">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="dataCount"
            :page-size="selectedRowsCount"
            :current-page.sync="dataOptions.currentPage"
            hide-on-single-page
          ></el-pagination>
        </div>
      </div>
      <!--    
          #### # OF ROW COUNT ####
      -->
      <div class="col-3 my-auto text-right" v-if="options.selectRowsCount">
        <el-select
          v-model="dataOptions.totalPageCount"
          placeholder="Select"
          size="sm"
        >
          <el-option
            v-for="item in rowsCount"
            :key="item"
            :label="item"
            :value="item"
          ></el-option>
        </el-select>
      </div>

      <!--    
          #### DOWNLOAD ####
      -->

      <div v-if="options.download" class="col-auto">
        <base-button
          icon="download"
          variant="tertiary"
          size="md"
          icon-only
          @click="download"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapGetters } = createNamespacedHelpers('dashboard');
import { getSafe } from './utils';

export default {
  name: 'lazy-custom-table',
  props: {
    // Element UI props
    border: {
      type: Boolean,
      default: false,
    },
    // Mayday table props
    data: {
      type: Array,
      default: () => {
        return [];
      },
    },
    dataCount: {
      type: Number,
      default: 0,
    },
    headers: Array,
    paginationTotal: Number,
    rowsCount: {
      type: Array,
      default: () => {
        return [5, 10, 20, 50];
      },
    },
    options: {
      type: Object,
      default: () => {
        return {
          search: false,
          download: false,
          selectRowsCount: false,
          showItemCount: false,
          bulkActions: false,
          pagination: true,
          expandableRows: false,
        };
      },
    },
    initDataOptions: {
      type: Object,
      default: () => {
        return {
          currentPage: 1,
          totalPageCount: 1,
          filters: [],
          sort: {},
          search: '',
        };
      },
    },
    maxHeight: {
      type: [String, Number],
      default: 500,
    },
  },
  data() {
    return {
      selectedRowsCount: this.rowsCount[0],
      tableData: [],
      selection: [],
      dataOptions: {
        currentPage: 1,
        totalPageCount: this.rowsCount[0],
        filters: [],
        sort: {},
        search: '',
      },
      defaultSort: {},
      isReady: false,
    };
  },
  created() {
    const { sort } = this.initDataOptions;
    if (
      sort != null &&
      sort.hasOwnProperty('prop') &&
      sort.hasOwnProperty('ascending')
    ) {
      const headerItem = this.headers.find(({ prop, key }) =>
        [key, prop].includes(sort.prop),
      );
      this.defaultSort.prop = headerItem
        ? this.getAppropriateColumnMeta(headerItem)
        : null;
      this.defaultSort.order = sort.ascending ? 'ascending' : 'descending';
    }
    this.dataOptions = this.initDataOptions;
    this.isReady = true;
  },
  computed: {
    ...mapGetters(['tableFileIsLoading']),
    totalPageInTable() {
      if (this.selectedRowsCount === 'All') return 1;
      return Math.floor(this.dataCount / this.selectedRowsCount) + 1;
    },
    itemCountEnd() {
      return (
        Math.min(
          this.selectedRowsCount * this.dataOptions.currentPage,
          this.dataCount,
        ) || this.selectedRowsCount
      );
    },
    itemCountBegin() {
      return (
        Math.max(
          this.selectedRowsCount * (this.dataOptions.currentPage - 1),
          1,
        ) || 1
      );
    },
  },
  methods: {
    getSafe,

    getAppropriateColumnMeta({ prop, path }) {
      return !path || !path.length ? prop : path;
    },

    getColumnData(row, header) {
      return this.getSafe(row, this.getAppropriateColumnMeta(header));
    },

    // SORT METHODS
    sortChange(e) {
      this.dataOptions.sort = {
        prop: e.column.columnKey || e.prop,
        ascending: e.order === 'ascending',
      };
      this.dataOptions.currentPage = 1;
    },
    // PAGINATION METHODS
    chunkArray(array, size) {
      const chunked_arr = [];
      let index = 0;
      while (index < array.length) {
        chunked_arr.push(array.slice(index, size + index));
        index += size;
      }
      return chunked_arr;
    },
    // SELECTION METHODS
    selectionChange(e) {
      this.selection = e;
    },
    clearSelection() {
      this.$refs['mayday-table'].clearSelection();
    },
    // FILTER METHODS
    getFilters(prop) {
      const filterOption = this.options.filters.find(
        (filter) => filter.key === prop,
      );
      return filterOption && Array.isArray(filterOption.values)
        ? filterOption.values
        : null;
    },
    filterChange(e) {
      this.dataOptions.currentPage = 1;

      const newFilter = {
        key: Object.keys(e)[0],
        values: e[Object.keys(e)[0]],
      };

      if (!newFilter.values.length) {
        this.dataOptions.filters = this.dataOptions.filters.filter(
          (filter) => filter.key !== newFilter.key,
        );
        return this.dataOptions.filters;
      }

      const isFilterKeyAlreadyExist = !!this.dataOptions.filters.filter(
        (filter) => filter.key === newFilter.key,
      ).length;

      if (isFilterKeyAlreadyExist) {
        this.dataOptions.filters = this.dataOptions.filters.map((filter) => {
          if (filter.key === newFilter.key && newFilter.values.length) {
            return {
              key: filter.key,
              values: newFilter.values,
            };
          }
          return filter;
        });
        return this.dataOptions.filters;
      } else {
        this.dataOptions.filters.push(newFilter);
        return this.dataOptions.filters;
      }
    },

    download() {
      if (!this.tableFileIsLoading) this.$emit('download');
    },
  },
  watch: {
    data() {
      this.tableData = this.data;
    },
    dataOptions: {
      handler() {
        this.$emit('update-data-options', this.dataOptions);
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.el-table__header-wrapper {
  position: fixed !important;
  height: 100px !important;
}

.mayday-table {
  :deep() table {
    width: 100% !important;
  }
}

.mayday-table {
  :deep() .cell {
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.mayday-table {
  :deep() .el-table-column--selection {
    padding-left: 15px;
  }
}

.mayday-table {
  :deep() .cell:active {
    white-space: unset;
    word-break: break-word;
    cursor: pointer;
  }
}

.item-count {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #525f7f;
}

.icon-button {
  color: $blue-mayday;
  border: none;
}
</style>
