var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BaseDropBox',{attrs:{"icon":"hand-pointer"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentPlugin.title),expression:"currentPlugin.title"}],staticClass:"border-0 text-base text-truncate",class:!!_vm.errors && _vm.errors.title ? 'borderless-input-error' : '',attrs:{"placeholder":_vm.$t(
          'settings-layout.menu-data.plugins.smart-actions.advanced-settings.title.placeholder',
        )},domProps:{"value":(_vm.currentPlugin.title)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.currentPlugin, "title", $event.target.value)},function($event){return _vm.updatePlugin('title', $event.target.value)}]}}),(!_vm.isDescriptionFocused)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentPlugin.description),expression:"currentPlugin.description"}],staticClass:"border-0 text-sm text-truncate",class:!!_vm.errors && _vm.errors.title ? 'borderless-input-error' : '',attrs:{"placeholder":_vm.$t(
          'settings-layout.menu-data.plugins.smart-actions.advanced-settings.description.placeholder',
        )},domProps:{"value":(_vm.currentPlugin.description)},on:{"click":function($event){return _vm.focusDescriptionTextarea()},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.currentPlugin, "description", $event.target.value)}}}):_c('div',{staticClass:"d-flex w-100 align-items-center gap-1"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentPlugin.description),expression:"currentPlugin.description"}],ref:"descriptionTextarea",staticClass:"border-0 text-sm w-100",class:{
          'text-truncate': !_vm.isDescriptionFocused,
          'borderless-input-error': !!_vm.errors && _vm.errors.description,
        },staticStyle:{"resize":"none"},attrs:{"rows":_vm.isDescriptionFocused ? undefined : 1,"maxlength":_vm.maxDescriptionLength,"placeholder":_vm.$t(
            'settings-layout.menu-data.plugins.smart-actions.advanced-settings.description.placeholder',
          )},domProps:{"value":(_vm.currentPlugin.description)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.currentPlugin, "description", $event.target.value)},function($event){return _vm.updatePlugin('description', $event.target.value)}],"blur":function($event){_vm.isDescriptionFocused = false}}}),_vm._v(" "),_c('div',{staticClass:"text-xs",class:{
          'text-red': _vm.descriptionLength === _vm.maxDescriptionLength,
        }},[_vm._v(" "+_vm._s(`(${_vm.descriptionLength}/${_vm.maxDescriptionLength})`)+" ")])])]},proxy:true},{key:"cta",fn:function(){return [_c('el-tooltip',{attrs:{"content":_vm.$t(
          'settings-layout.menu-data.plugins.smart-actions.advanced-settings.disable-delete-tooltip',
        ),"effect":"light","visible-arrow":false,"disabled":!_vm.disableDelete,"placement":"right-end"}},[_c('base-button',{attrs:{"variant":"tertiary","size":"sm","icon-only":true,"icon":"trash","disabled":_vm.disableDelete},on:{"click":function($event){return _vm.$emit('delete-plugin')}}})],1),_c('el-divider',{staticClass:"mr-3 ml-2",attrs:{"direction":"vertical"}})]},proxy:true},{key:"collapse-content",fn:function(){return [_c('div',[_c('div',{staticClass:"d-flex flex-column gap-2"},[_c('div',{staticClass:"d-flex gap-2 justify-content-between"},[_c('plugin-advanced-settings-option',{attrs:{"field":_vm.fields.label,"error":!!_vm.errors ? _vm.errors.label : undefined},on:{"update-plugin":function($event){return _vm.updatePlugin('label', $event)}}}),_c('plugin-advanced-settings-option',{attrs:{"field":_vm.fields.color,"error":!!_vm.errors ? _vm.errors.color : undefined},on:{"update-plugin":function($event){return _vm.updatePlugin('color', $event)}}})],1),_c('plugin-advanced-settings-option',{attrs:{"field":_vm.fields.subtype,"error":!!_vm.errors ? _vm.errors.subtype : undefined},on:{"update-plugin":function($event){return _vm.updateSubtype($event)}}}),_vm._l((_vm.additionnalProperties),function(property){return _c('plugin-advanced-settings-option',{key:`plugin-advanced-settings-additional-property-${property}`,attrs:{"field":_vm.fields[property],"error":!!_vm.errors ? _vm.errors[property] : undefined},on:{"update-plugin":function($event){return _vm.updatePlugin(property, $event)}}})})],2)])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }