<template>
  <div class="indicators">
    <div v-for="localType in localTypes" :key="localType.className">
      <not-allowed-tooltip
        :user-permissions="userPermissions"
        :permission-key="localType.userPermissionsKey"
      >
        <template v-slot:main-content="{ hasPermissions }">
          <el-popconfirm
            class="indicator-popconfirm"
            :confirm-button-text="localType.popconfirm.confirm"
            confirm-button-type="plain"
            :cancel-button-text="localType.popconfirm.cancel"
            icon="el-icon-info"
            icon-color="red"
            :title="localType.popconfirm.title"
            @confirm="localType['cta']()"
            :disabled="!hasPermissions"
          >
            <div
              slot="reference"
              :class="[localType.className, unclickable ? 'unclickable' : '']"
              class="px-1 indicator-popconfirm-reference"
            >
              <el-tooltip
                :content="localType.tooltip"
                :disabled="!hasPermissions"
              >
                <div
                  :style="localType.indicatorColor"
                  class="tags-for-node"
                ></div>
              </el-tooltip>
            </div>
          </el-popconfirm>
        </template>
      </not-allowed-tooltip>
    </div>
  </div>
</template>
<script>
import NotAllowedTooltip from '@/components/Commons/NotAllowedTooltip';

export default {
  name: 'common-node-status',
  components: {
    NotAllowedTooltip,
  },
  props: {
    isNode: Boolean,
    content: Object,
    unclickable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    localTypes() {
      const localTypes = [];
      if (this.content.isOutdated) {
        localTypes.push(this.versionContent['NOTIFICATION']);
      }
      if (this.content.published === false) {
        localTypes.push(this.versionContent['DRAFT']);
      }

      return localTypes;
    },
    userPermissions() {
      return this.content.userPermissions || {};
    },
  },
  data() {
    return {
      versionContent: {
        NOTIFICATION: {
          className: 'notification-indicator',
          popconfirm: {
            title: this.$t('knowledge.common-node-status.notification.title'),
            confirm: this.$t(
              'knowledge.common-node-status.notification.confirm',
            ),
            cancel: this.$t('knowledge.common-node-status.notification.cancel'),
          },
          icon: {
            iconType: 'fas',
            value: 'times-circle',
            color: '#fd0044',
          },
          indicatorColor: 'background-color: #FD0044;',
          tooltip: this.$t('knowledge.common-node-status.notification.tooltip'),
          cta: () => {
            this.$emit('update-content-verification', false);
          },
          userPermissionsKey: 'canVerifyContent',
        },
        DRAFT: {
          className: 'draft-indicator',
          popconfirm: {
            title: this.$t('knowledge.common-node-status.draft.title'),
            confirm: this.$t('knowledge.common-node-status.draft.confirm'),
            cancel: this.$t('knowledge.common-node-status.draft.cancel'),
          },
          icon: {
            iconType: 'fas',
            value: 'drafting-compass',
            color: '#7d89a0',
          },
          indicatorColor: 'background-color:#CED4D4;',
          tooltip: this.$t('knowledge.common-node-status.draft.tooltip'),
          cta: () => {
            this.$emit('update-content', { published: true });
          },
          userPermissionsKey: 'canUpdateContent',
        },
      },
    };
  },
};
</script>
<style lang="scss" scoped>
.feedback-indicator {
  background-color: $blue-mayday;
}

.el-popconfirm {
  :deep() p {
    font-size: 10px;
  }
}

.unclickable {
  cursor: auto;
}

.content-label-icon {
  width: 12px;
  color: var(--color);
  background-color: rgba(var(--color), 0.1);
  border-radius: 2px;
}

.tags-for-node {
  width: 8px;
  height: 8px;
  border-radius: 3px;
}
</style>
