import { render, staticRenderFns } from "./FiltersPopover.vue?vue&type=template&id=e61dabc6&scoped=true&"
import script from "./FiltersPopover.vue?vue&type=script&lang=js&"
export * from "./FiltersPopover.vue?vue&type=script&lang=js&"
import style0 from "./FiltersPopover.vue?vue&type=style&index=0&id=e61dabc6&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e61dabc6",
  null
  
)

export default component.exports