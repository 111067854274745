<template>
  <div class="d-flex justify-content-end">
    <!-- CANCEL -->
    <base-button-old @click="sendCloseEvent" type="secondary" size="sm">{{
      $t('knowledge.modals.notifications.cancel')
    }}</base-button-old>
    <!-- PREVIOUS -->
    <base-button-old
      v-if="currentStep > 0"
      @click="sendGoToPreviousStepEvent"
      type="primary"
      outline
      size="sm"
      >{{ $t('knowledge.modals.notifications.previous') }}</base-button-old
    >
    <!-- NEXT -->
    <base-button-old
      v-if="currentStep !== stepCount - 1"
      @click="sendGoToNextStep"
      type="primary"
      size="sm"
      :disabled="isNextActionDisabled"
      >{{ $t('knowledge.modals.notifications.next') }}</base-button-old
    >
    <!-- SUBMIT -->
    <base-button-old
      v-else
      @click="sendCreateContributionEvent"
      type="primary"
      size="sm"
      >{{ $t('knowledge.product.notification.send') }}</base-button-old
    >
  </div>
</template>

<script>
export default {
  name: 'admin-contribution-modal-actions',
  props: {
    stepCount: Number,
    currentStep: Number,
    isLabelInvalid: Boolean,
    isTypeInvalid: Boolean,
    isKnowledgeInvalid: Boolean,
    areParametersInvalid: Boolean,
    areAccessRestrictionsInvalid: Boolean,
  },
  methods: {
    sendCloseEvent() {
      this.$emit('close');
    },
    sendGoToPreviousStepEvent() {
      this.$emit('go-to-previous-step');
    },
    sendGoToNextStep() {
      this.$emit('go-to-next-step');
    },
    sendCreateContributionEvent() {
      this.$emit('create-contribution');
    },
  },
  computed: {
    isNextActionDisabled() {
      switch (this.currentStep) {
        case 0:
          return this.isLabelInvalid || this.isTypeInvalid;
        case 1:
          return this.isKnowledgeInvalid || this.areParametersInvalid;
        case 4:
          return this.areAccessRestrictionsInvalid;
        default:
          return false;
      }
    },
  },
};
</script>
