<template>
  <el-popover placement="top" class="my-auto" v-model="localDisplay">
    <div>
      <div class="dropdown-item px-0" style="display: inline-block">
        <h5 class="text-overflow m-0" @click="triggerEvent('edit-rule')">
          <font-awesome-icon :icon="['fas', 'pen']" class="medium-icon ml-2" />
          <span>{{ $t('automations.main.row.edit') }}</span>
        </h5>
      </div>
      <div class="dropdown-item px-0" style="display: inline-block">
        <h5 class="text-overflow m-0" @click="triggerEvent('delete-rule')">
          <font-awesome-icon
            :icon="['fas', 'trash']"
            class="medium-icon ml-2 text-danger"
          />
          <span>
            {{ $t('automations.main.row.delete') }}
          </span>
        </h5>
      </div>
    </div>
    <div class="col-12 side-option" slot="reference" id="add-group-popover">
      <font-awesome-icon :icon="['fal', 'ellipsis-h']" class="option-icon" />
    </div>
  </el-popover>
</template>
<script>
export default {
  name: 'rule-actions',
  data() {
    return {
      localDisplay: false,
    };
  },
  methods: {
    triggerEvent(evt) {
      this.localDisplay = false;
      this.$emit(evt);
    },
  },
};
</script>
<style scoped>
/* FA ICONS */

.option-icon {
  height: 20px;
  width: 30px;
  color: #b8bfcd;
}

.medium-icon {
  width: 16px;
  height: 15px;
  padding: 2px;
  color: #b8bfcd;
}

/* END FA ICONS */

.empty-placeholder {
  color: #b8bfcd;
}

.options_selection {
  max-width: 20px;
  cursor: pointer;
}

.center-element {
  justify-content: center;
  vertical-align: middle;
  display: flex;
}

.dropdown {
  padding: 0px;
  border-width: 0px;
  box-shadow: none;
  background-color: transparent;
  margin-right: 0px;
  margin: 5px;
}

.dropdown:hover {
  background-color: white;
}

.dropdown-item span {
  margin-left: 10px;
}

.dropdown-item:hover {
  cursor: pointer;
}

.col-1 {
  width: 400px;
}
th {
  font-weight: bold;
}

tr {
  align-items: baseline;
}

td {
  color: #525f7f;
  font-size: 13px !important;
}

.table-header {
  background-color: white;
  align-items: baseline;
  height: 40px;
  text-transform: uppercase;
  font-size: 0.65rem;
}

.header-col {
  color: #7d89a0;
}

.no-margin {
  margin-left: 0px !important;
  margin-right: 0px !important;
  margin-top: 10px;
  margin-top: 0px;
  margin-bottom: 0px;
}
</style>
