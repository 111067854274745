<template>
  <div class="preview-window">
    <base-input
      ref="url"
      :label="$t('knowledge.modals.edit-icon.url-input-label')"
      class="mb-0"
      :placeholder="$t('knowledge.modals.edit-icon.url-placeholder')"
      addon-left-icon="fa fa-link"
      v-model="iconUrl"
      @input="$emit('update', iconUrl)"
      :error="urlError"
    ></base-input>
    <div v-show="isValidUrl">
      <div class="mt-4 mb-2 preview-label">APERÇU</div>
      <img
        class="preview-image"
        :src="iconUrl"
        @load="isValidUrl = true"
        @error="isValidUrl = false"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    focusedIconValue: String,
  },
  data() {
    return {
      iconUrl: this.focusedIconValue,
      isValidUrl: false,
    };
  },
  computed: {
    urlError() {
      return this.isValidUrl || !this.iconUrl
        ? ''
        : this.$tc('knowledge.modals.edit-icon.url-error');
    },
  },
  methods: {
    urlValidation() {
      return this.isValidUrl;
    },
  },
};
</script>

<style lang="scss" scoped>
.preview-window {
  max-height: 40vh;
  overflow: auto;
}
.preview-label {
  font-size: 12px;
  color: $grey-5-mayday;
  font-weight: bold;
}
.preview-image {
  max-width: 280px;
  border: 0.5px solid #9aa4b6;
  border-radius: 4px;
}
</style>
