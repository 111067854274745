<template>
  <div class="export-container">
    <span class="type-name">{{
      $t(`new-dashboard.exports.${type}.title`)
    }}</span>
    <p class="type-description">
      {{ $t(`new-dashboard.exports.${type}.description`) }}
    </p>
    <div class="picker-container">
      <el-select
        v-model="selectedId"
        :placeholder="$t(`new-dashboard.exports.${type}.empty`)"
      >
        <el-option
          v-for="option in options"
          :key="option.id"
          :label="option.label"
          :value="option.id"
        >
        </el-option>
      </el-select>
    </div>
    <div v-if="selectedId">
      <base-button
        size="md"
        :text="$t('new-dashboard.exports.download')"
        icon="download"
        @click="handleDownload"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'AnalyticsPredefinedFiltersDownload',
  props: {
    type: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      selectedId:
        this.options && this.options.length ? this.options[0].id : null,
    };
  },
  computed: {
    selectedName() {
      const option = this.options.find(
        (option) => option.id === this.selectedId,
      );

      return option ? option.name : '';
    },
  },
  methods: {
    ...mapActions('kbExportModule', ['getExportsWithPredefinedFilters']),
    async handleDownload() {
      const file = await this.getExportsWithPredefinedFilters(this.selectedId);
      const filename = `exports-${this.type}-${new Date().toISOString()}.csv`;

      const blob = new Blob([file], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
};
</script>

<style lang="scss" scoped>
.export-container {
  border: solid lightgray 1px;
  border-radius: 5px;
  padding: 16px;
}

.type-name {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.02em;
  color: $grey-8-mayday;
  margin-bottom: unset;
}

.type-description {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 10px;
  letter-spacing: 0.02em;
  color: $grey-6-mayday;
}

.picker-container {
  width: 300px;
  margin-bottom: 16px;
}

.picker-container > div {
  width: 300px;
}

.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.title-wrapper {
  margin-top: 40px;
  margin-bottom: 40px;
}

.start-button {
  padding: 10px;
}
</style>
