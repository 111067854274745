<template>
  <div class="users-invitations-page">
    <!-- HEADER -->
    <div
      class="users-invitations-header d-flex align-items-center justify-content-between sticky-top"
    >
      <div class="d-flex flex-row align-items-center">
        <div class="header-icon-wrapper mr-3">
          <font-awesome-icon
            class="invitations-header-icon"
            :icon="['fal', 'envelope']"
          />
        </div>
        <span class="users-invitations-title">
          {{ $t('settings.permissions.user-invite-modal.title') }}
        </span>
      </div>
    </div>
    <div class="users-invitation-main">
      <div class="users-invitation-infos">
        <div class="title">
          {{ $t('settings.permissions.user-invite-modal.sub-title') }}
        </div>
        <div class="description">
          {{ $t('settings.permissions.user-invite-modal.description') }}
        </div>
      </div>
      <UsersInvitationsTable
        :users="toInviteUsers"
        :roles="roles"
        :groups="groups"
        :labelCategories="userLabelCategories"
        :duplicate-emails="duplicateEmails"
        @change="handleChange"
        @delete="handleDelete"
        @validate="isValid = $event"
        class="users-invitation-body"
      />

      <div class="mt-2">
        <base-button
          variant="primary"
          size="md"
          @click="addUser()"
          :text="$t('settings.permissions.user-invite-modal.add-button')"
        ></base-button>
      </div>
    </div>
    <div
      class="users-invitations-footer d-flex justify-content-end align-items-center sticky-bottom"
    >
      <div
        class="invitations-count"
        :class="{ 'text-error': isLimit || isDuplicate }"
      >
        <el-tooltip
          v-if="isLimit"
          :content="$t('settings.permissions.user-invite-modal.errors.limit')"
        >
          <font-awesome-icon :icon="['fas', 'info-circle']" />
        </el-tooltip>
        <el-tooltip
          v-if="isDuplicate"
          :content="
            $t('settings.permissions.user-invite-modal.errors.duplicate')
          "
        >
          <font-awesome-icon :icon="['fas', 'info-circle']" />
        </el-tooltip>
        {{
          $tc(
            'settings.permissions.user-invite-modal.invitations',
            invitations.length,
            { count: invitations.length },
          )
        }}
      </div>
      <base-button
        variant="secondary"
        size="md"
        class="mr-1"
        :text="$t('settings.permissions.user-invite-modal.cancel-button')"
        @click="handleCancel"
      ></base-button>
      <base-button
        variant="primary"
        size="md"
        :text="$t('settings.permissions.user-invite-modal.send-button')"
        :disabled="!isValid || isLimit || isDuplicate"
        @click="sendInvitations"
        :loading="loading"
      ></base-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { v4 } from 'uuid';
import UsersInvitationsTable from './UsersInvitationsTable';

export default {
  name: 'users-invitations',
  components: {
    UsersInvitationsTable,
  },
  computed: {
    invitations() {
      return this.toInviteUsers.flatMap((user) => {
        const emails = user.email.split(',');
        if (emails.length > 1) {
          const { groupIds, roleIds, labelIds } = user;
          const enrichedUsers = emails.map((email) => ({
            email: email.trim(),
            groupIds,
            roleIds,
            labelIds,
          }));
          return enrichedUsers;
        }
        return user;
      });
    },

    emails() {
      return this.invitations.map(({ email }) => email);
    },
    isLimit() {
      return this.invitations.length > this.limit;
    },
    duplicateEmails() {
      const duplicate = this.emails.filter(
        (item, index) => this.emails.indexOf(item) != index,
      );
      return [...new Set(duplicate)];
    },
    isDuplicate() {
      return !!this.duplicateEmails.length;
    },
    ...mapGetters('adminModule', [
      'roles',
      'groups',
      'userLabelCategories',
      'userInvitations',
    ]),
  },
  data() {
    return {
      toInviteUsers: [],
      isValid: false,
      loading: false,
      limit: 50,
    };
  },
  methods: {
    addUser() {
      this.toInviteUsers.push({
        tempId: v4(),
        email: '',
        groupIds: [],
        roleIds: [],
        labelIds: [],
      });
    },
    handleChange(payload) {
      const copy = [...this.toInviteUsers];
      const index = copy.findIndex((u) => u.tempId === payload.tempId);
      copy[index] = payload;
      this.toInviteUsers = copy;
    },
    handleDelete(id) {
      this.toInviteUsers = this.toInviteUsers.filter((u) => u.tempId !== id);
    },
    async sendInvitations() {
      this.loading = true;
      const company = await this.inviteUsers({ users: this.invitations });

      const res =
        company &&
        company.pendingEmployees &&
        company.pendingEmployees.results &&
        company.pendingEmployees.results.length;

      if (!res) {
        this.loading = false;

        return this.$message({
          message: this.$t('settings.permissions.user-invite-modal.error'),
          duration: 2000,
          type: 'error',
        });
      }

      this.setUserInvitations([]);
      this.loading = false;
      this.$message({
        message: this.$t('settings.permissions.user-invite-modal.success'),
        duration: 1000,
        type: 'success',
      });
      await new Promise((resolve) => setTimeout(resolve, 1000));
      return this.$router.push('/settings/users');
    },
    handleCancel() {
      return this.$router.push('/settings/users');
    },
    ...mapActions('adminModule', [
      'checkUserValidity',
      'inviteUsers',
      'setUserInvitations',
    ]),
  },
  watch: {
    toInviteUsers(newVal) {
      this.setUserInvitations(newVal);
    },
  },
};
</script>

<style lang="scss" scoped>
.users-invitations-title {
  font-size: 24px;
  font-weight: bold;
}

.users-invitations-header {
  background-color: white;
  padding: 16px;
  max-width: 100%;
  min-width: calc(100% - 60px);
  max-height: 64px;
  position: fixed;
  z-index: 10 !important;
}

.header-icon-wrapper {
  width: 36px;
  height: 36px;
  background-color: $blue-mayday;
  color: white;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.invitations-header-icon {
  width: 24px;
  height: 24px;
  font-size: 24px;
  color: white;
}

.users-invitations-footer {
  background-color: white;
  padding: 16px;
  max-width: 100%;
  min-width: calc(100% - 60px);
  max-height: 64px;
  position: fixed;
  bottom: 0;
  z-index: 10 !important;
}

.users-invitation-main {
  padding: 64px 0;
  margin: 0 68px;
  overflow-y: auto;
}

.users-invitation-infos {
  margin-top: 16px;
  max-width: 512px;
  .title {
    font-size: 20px;
    font-weight: bold;
  }
  .description {
    color: $grey-5-mayday;
    font-size: 12px;
  }
}

.users-invitation-body {
  margin-top: 16px;
}
.invitations-count {
  color: $grey-7-mayday;
  font-size: 14px;
  margin-right: 8px;
}
.text-error {
  color: $red-mayday;
}
</style>
