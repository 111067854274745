<template>
  <div class="d-flex flex-column">
    <div class="mb-2 element-description">
      {{
        this.pending
          ? $t('settings.permissions.users-waiting.description')
          : $t('settings.permissions.users-permissions.description')
      }}
    </div>
    <!-- USER CARD -->
    <div class="d-flex p-3 stat-user-card">
      <div class="d-flex flex-column justify-content-center">
        <div class="user-title">
          {{
            this.pending
              ? $tc('settings.permissions.users-waiting.title', employeesCount)
              : $tc(
                  'settings.permissions.users-permissions.user',
                  employeesCount,
                )
          }}
        </div>
        <div class="user-count" v-if="success">
          {{ this.pending ? pendingEmployeesCount : employeesCount }}
        </div>
        <div
          v-else
          class="d-flex justify-content-center mt-2"
          v-loading="employeesTableIsLoading || pendingEmployeesTableIsLoading"
        >
          <base-button
            :text="$t('settings.reload')"
            variant="primary"
            size="md"
            @click="getCompanyDetails"
          ></base-button>
        </div>
      </div>
      <div class="d-flex flex-row align-items-end">
        <router-link
          :to="
            this.pending
              ? '/settings/users/pending'
              : '/settings/users/administrate'
          "
          class="mr-2"
        >
          <base-button
            :text="$t('settings.permissions.users-permissions.administrate')"
            variant="secondary"
            size="sm"
            :disabled="
              employeesTableIsLoading || pendingEmployeesTableIsLoading
            "
          ></base-button>
        </router-link>
        <router-link to="/settings/users/invitations">
          <base-button
            :text="
              $t('settings.permissions.users-permissions.invite-users-button')
            "
            variant="primary"
            size="sm"
            :disabled="
              employeesTableIsLoading || pendingEmployeesTableIsLoading
            "
          ></base-button>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'users-settings',
  props: {
    generalSuccess: {
      type: Boolean,
      default: true,
    },
    pending: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    success() {
      return this.generalSuccess && this.localSuccess;
    },
    ...mapGetters('adminModule', [
      // EMPLOYEES
      'employeesCount',
      'employeesTableIsLoading',
      'pendingEmployeesCount',
      'pendingEmployeesTableIsLoading',
      // COMMONS
      'groups',
      'roles',
    ]),
  },
  data() {
    return {
      localSuccess: true,
    };
  },
  methods: {
    async getUsers() {
      this.localSuccess = this.pending
        ? await this.getPendingEmployeesDetails()
        : await this.getEmployeesDetails();
    },
    ...mapActions('adminModule', [
      'getEmployeesDetails',
      'getPendingEmployeesDetails',
    ]),
  },
};
</script>

<style lang="scss" scoped>
.administrate-button {
  border: 1px solid;
  border-radius: 4px;
  height: 28px;
  padding: 4px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 700;
  &:hover {
    color: white;
    background-color: $blue-mayday;
  }
}

.invite-button {
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-count {
  font-size: 40px;
  font-weight: 700;
  line-height: 35px;
  width: fit-content;
}

.user-title {
  color: $grey-6-mayday;
}

.element-description {
  font-size: 12px;
  color: $grey-7-mayday;
}

.stat-user-card {
  border: 1px solid $grey-3-mayday;
  border-radius: 4px;
  justify-content: space-between;
  transition: all 200ms ease;
  &:hover {
    box-shadow: 0px 15px 15px rgba(112, 144, 176, 0.1);
  }
}
</style>
