<template>
  <div class="analytics-contents-table-container">
    <header class="header">
      <AnalyticsTitle
        :title="$t('new-dashboard.header.title.analytics-contents')"
        :tooltip-content="
          $t(
            `new-dashboard.header.tooltip.analytics-contents.${
              viewsFilter ? 'with' : 'without'
            }-views`,
          )
        "
      >
        <template v-slot:title-suffix>
          <ContentFilterSelect
            class="views-filter-select"
            v-model="viewsFilterModel"
            :options="viewsOptions"
          />
        </template>
      </AnalyticsTitle>
      <RemoteSearch
        class="analytics-contents-table-search"
        :placeholder="$t('new-dashboard.header.search-placeholder')"
        :value="searchFilter"
        @change-additional="handleChange"
      />
    </header>
    <LazyCustomTable
      v-loading="isLoading"
      class="analytics-contents-table"
      :options="tableOptions"
      :headers="tableHeaders"
      :data="data"
      :data-count="totalCount"
      :init-data-options="dataOptions"
      :rows-count="[10]"
      :max-height="600"
      @update-data-options="handleUpdateDataOptions"
      @download="handleDownload"
    >
      <template v-slot:empty>
        <EmptyState
          v-if="isEmpty"
          :external-doc-url="externalDocUrl"
          :link-anchor-name="externalDocAnchor"
          @open-filters-drawer="() => $emit('open-filters-drawer')"
        />
      </template>
      <template v-slot:content="{ column: { type, title } }">
        <i :class="[generateContentIconClass(type), 'content-icon']"></i>
        <span>{{ title }}</span>
      </template>

      <template v-slot:parents="{ column: parents }">
        <font-awesome-icon
          :icon="['fal', 'folder']"
          class="collection-icon"
        ></font-awesome-icon>
        <el-popover
          v-if="parents && parents.length"
          trigger="hover"
          placement="top-end"
        >
          <div
            class="collection-title"
            v-for="parent in parents"
            :key="parent.id"
          >
            {{ parent.title }}
          </div>
          <template #reference>
            <span>
              {{ parents[0].title }}
              <span v-if="parents.length > 1">...</span>
            </span>
          </template>
        </el-popover>
        <span v-else class="collection-title deleted">
          {{ $t('new-dashboard.table.body.no-collection') }}</span
        >
      </template>

      <template v-slot:views="{ column: { count, trend }, row: { languages } }">
        <el-popover
          trigger="hover"
          placement="right"
          :disabled="shouldDisableLanguagesPopover(languages)"
        >
          <LanguagesCounts
            v-if="!shouldDisableLanguagesPopover(languages)"
            :languagesCounts="languages"
          />
          <template #reference>
            <div class="view-container">
              <font-awesome-icon
                :icon="['fal', 'arrow-right']"
                class="views-icon"
                :class="trend"
              />
              <span>{{ count }}</span>
            </div>
          </template>
        </el-popover>
      </template>

      <template v-slot:userReturns="{ column: count }">
        <template v-if="count > 0">{{ count }}</template>
        <template v-else>
          <span class="no-feedback">
            <font-awesome-icon
              :icon="['fal', 'megaphone']"
              class="no-feedback-icon"
            ></font-awesome-icon>
            <span>{{ $t('new-dashboard.table.body.no-feedbacks') }}</span>
          </span>
        </template>
      </template>

      <template v-slot:satisfactions="{ column: { count, up, rate } }">
        <template v-if="count > 0">
          <div
            :class="[
              'satisfaction-container',
              rate >= 50 ? 'liked' : 'disliked',
            ]"
          >
            <font-awesome-icon
              :icon="['fal', rate >= 50 ? 'thumbs-up' : 'thumbs-down']"
              class="satisfaction-icon"
            />
            {{ clamp(rate, 0, 100) || 0 }}%
            <el-tooltip :content="`${up}/${count}`" placement="top">
              <font-awesome-icon
                :icon="['fal', 'info-circle']"
                class="satisfaction-icon"
              />
            </el-tooltip>
          </div>
        </template>
        <template v-else>
          <span>{{ $t('new-dashboard.table.body.no-feedbacks') }}</span>
        </template>
      </template>

      <template v-slot:options="{ row }">
        <base-button
          icon="file-search"
          variant="tertiary"
          size="sm"
          icon-only
          @click="() => handleShowDetails(row)"
        />
      </template>
    </LazyCustomTable>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import LazyCustomTable from '@/components/Tables/LazyCustomTable';
import AnalyticsTitle from '../AnalyticsTitle';
import ContentFilterSelect from './ContentFilterSelect';
import LanguagesCounts from '../LanguagesCounts';
import RemoteSearch from '@/components/Filters/Filter/RemoteSearch';
import EmptyState from '../EmptyState';
import { EXTERNAL_DOC_ANCHORS } from '@/store/dashboard/constants';
import { clamp } from '../../../utils/utils';

export default {
  components: {
    AnalyticsTitle,
    ContentFilterSelect,
    RemoteSearch,
    LazyCustomTable,
    EmptyState,
    LanguagesCounts,
  },

  props: {
    viewsFilter: {
      type: Boolean,
      required: true,
    },
    searchFilter: {
      type: String,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    totalPages: {
      type: Number,
      required: true,
    },
    totalCount: {
      type: Number,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
    dataOptions: {
      type: Object,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    externalDocUrl: {
      type: String,
      default: () => '',
    },
    isEmpty: {
      type: Boolean,
      default: false,
    },
    download: {
      type: Function,
      required: true,
    },
  },
  mounted() {
    this.tableHeadersBase = [
      {
        prop: 'content',
        path: 'content.title',
        key: 'label',
        label: this.$t('new-dashboard.table.header.analytics-contents.content'),
        sortable: true,
        template: true,
        width: 450,
        fixed: true,
      },
      {
        prop: 'parents',
        key: 'collection',
        width: 200,
        label: this.$t(
          'new-dashboard.table.header.analytics-contents.collection',
        ),
        template: true,
        className: 'collections',
      },
      {
        prop: 'views',
        key: 'total_count',
        label: this.$t('new-dashboard.table.header.analytics-contents.views'),
        sortable: true,
        template: true,
      },
      // eslint-disable-next-line no-constant-condition
      ...(this.hasContributions
        ? [
            {
              prop: 'userReturns',
              key: 'user_return_count',
              label: this.$t(
                'new-dashboard.table.header.analytics-contents.user-returns',
              ),
              sortable: true,
              template: true,
            },
          ]
        : []),
      {
        prop: 'satisfactions',
        key: 'satisfaction_rate',
        label: this.$t(
          'new-dashboard.table.header.analytics-contents.satisfaction',
        ),
        sortable: true,
        template: true,
      },
    ];
  },

  data() {
    return {
      viewsOptions: [
        {
          label: this.$t(
            'new-dashboard.filter-type.analytics-contents.with-views',
          ),
          value: true,
        },
        {
          label: this.$t(
            'new-dashboard.filter-type.analytics-contents.without-views',
          ),
          value: false,
        },
      ],
      tableOptionsBase: {
        search: false,
        selectRowsCount: false,
        showItemCount: true,
        bulkActions: false,
        pagination: true,
        expandableRows: false,
      },
      tableHeadersBase: [],
      externalDocAnchor: EXTERNAL_DOC_ANCHORS.contentTable,
    };
  },

  computed: {
    ...mapGetters(['hasContributions', 'isParametric']),
    viewsFilterModel: {
      get() {
        return this.viewsFilter;
      },
      set(value) {
        this.$emit('update:viewsFilter', value);
        return value;
      },
    },
    tableOptions() {
      const { tableOptionsBase: base } = this;

      return Object.assign({}, base, {
        filters: [],
        download: !this.isParametric && !this.isEmpty,
      });
    },
    tableHeaders() {
      return this.viewsFilter
        ? [
            ...this.tableHeadersBase,
            {
              prop: 'options',
              label: this.$t(
                'new-dashboard.table.header.analytics-contents.options',
              ),
              template: true,
            },
          ]
        : this.tableHeadersBase;
    },
  },

  methods: {
    clamp,
    handleChange(event) {
      this.$emit('update:searchFilter', event.value);
    },
    shouldDisableLanguagesPopover(languages) {
      return !languages || !Object.keys(languages).length;
    },

    handleUpdateDataOptions(options) {
      this.$emit('update:dataOptions', options);
    },

    generateContentIconClass(type) {
      const iconType = type ? type.toLowerCase() : 'article';

      return `${iconType}-icon`;
    },

    handleShowDetails(row) {
      const {
        content: { id, type },
      } = row;
      const isDiagnostic = type === 'Diagnostic';
      this.$emit('show-details', { id, isDiagnostic });
    },

    handleDownload() {
      this.download('content');
    },

    computeDeletedClass(title) {
      return title == null || !title.length ? 'deleted' : '';
    },
  },
};
</script>

<style lang="scss" scoped>
.view-container {
  width: fit-content;
  padding-right: 8px;
}
.analytics-contents-table-container {
  .header {
    display: flex;
  }

  .views-filter-select {
    margin-left: 10px;

    :deep() .content-filter-select {
      .el-input > .el-input__inner {
        max-width: 160px;
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.02em;
        color: $grey-9-mayday;
      }

      .el-input .el-select__caret {
        color: $grey-9-mayday;
      }
    }
  }

  .analytics-contents-table-search {
    margin-left: auto;
    width: 320px;
    height: 40px;

    :deep() .el-input__prefix {
      padding-left: 5px;
      .search-icon {
        width: 20px;
        height: 20px;
      }
    }
  }

  .analytics-contents-table {
    margin-top: 20px;

    :deep() .el-table__body-wrapper {
      max-height: 40vh;
    }

    .satisfaction-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 2px 6px;
      border-radius: 4px;
      width: 86px;
      gap: 4px;

      .satisfaction-icon {
        width: 14px;
        height: 14px;
      }

      &.liked {
        background: rgba($green-5-mayday, 0.1);
        color: $green-5-mayday;
      }

      &.disliked {
        background: rgba($red-5-mayday, 0.1);
        color: $red-5-mayday;
      }
    }

    .cell {
      .content-icon,
      .collection-icon,
      .views-icon,
      .no-feedback-icon,
      .article-icon,
      .diagnostic-icon,
      .analyze-icon {
        margin-right: 10px;
        width: 16px;
        height: 16px;
        vertical-align: text-bottom;
      }

      .content-icon {
        display: inline-block;
      }
      .article-icon {
        background-image: url('~@/assets/article-icon-v2.1.svg');
        background-size: cover;
        background-position: center;
      }
      .diagnostic-icon {
        background-image: url('~@/assets/diag-icon-v2.1.svg');
        background-size: cover;
        background-position: center;
      }

      .analyze-icon {
        color: $grey-5-mayday;
        &:hover {
          color: $blue-mayday;
          cursor: pointer;
        }
      }

      .collection-title.deleted {
        color: $grey-5-mayday;
        font-style: italic;
      }

      .views-icon {
        &.flat {
          color: rgba($yellow-mayday, 0.8);
        }
        &.up {
          transform: rotate(-45deg);
          color: rgba($green-mayday, 0.8);
        }
        &.down {
          transform: rotate(45deg);
          color: rgba($red-mayday, 0.8);
        }
      }

      .no-feedback {
        color: $grey-5-mayday;
      }
    }
  }
  :deep() .collections > .cell {
    white-space: nowrap;
  }
}
</style>
