<template>
  <div class="diagnostic-builder-bread-crumb row ml-0 breadcrumb-items">
    <div
      class="mt-1"
      v-for="parent in bcParents"
      :key="parent.id"
      @click="appearParent(parent)"
    >
      <el-tooltip placement="bottom" class="group-tooltip" effect="light">
        <div
          slot="content"
          v-for="child in parent.children"
          :key="child.id"
          @click="appearParentChild(parent, child)"
        >
          <p v-if="child.id" class="children-previous-steps">
            <el-tooltip
              v-if="child.type === 'keyStep' && hasAgentRestrictions"
              :content="$t('knowledge.child.key-step.tooltip')"
              placement="top"
              class="group-tooltip"
            >
              <font-awesome-icon
                class="key-step-icon my-auto"
                :icon="['fad', 'key']"
                size="sm"
              ></font-awesome-icon>
            </el-tooltip>
            <small>{{ child.label }}</small>
          </p>
        </div>
        <base-button-old class="breadcrumb-step mr-0" type="secondary" outline>
          <el-tooltip
            v-if="parent.type === 'keyStep' && hasAgentRestrictions"
            :content="$t('knowledge.child.key-step.tooltip')"
            placement="top"
            class="group-tooltip"
          >
            <font-awesome-icon
              class="key-step-icon my-auto"
              :icon="['fad', 'key']"
              size="sm"
            />
          </el-tooltip>
          {{ parent.label }}
        </base-button-old>
      </el-tooltip>
      <font-awesome-icon
        class="breadcrumb-separator mx-1"
        :icon="['fas', 'circle']"
      />
    </div>
    <div class="mt-1">
      <base-button-old class="breadcrumb-last-step" type="primary">
        <el-tooltip
          v-if="step.type === 'keyStep' && hasAgentRestrictions"
          :content="$t('knowledge.child.key-step.tooltip')"
          placement="top"
          class="group-tooltip"
        >
          <font-awesome-icon
            class="key-step-icon my-auto"
            :icon="['fad', 'key']"
            size="sm"
          ></font-awesome-icon>
        </el-tooltip>
        {{ step.label }}
      </base-button-old>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import KnowledgeMixin from 'mixins/KnowledgeMixin/KnowledgeMixin';

export default {
  mixins: [KnowledgeMixin],
  name: 'diagnostic-builder-bread-crumb',
  props: {
    parents: Array,
    step: Object,
  },
  data() {
    return {
      displayModal: false,
    };
  },
  computed: {
    bcParents() {
      if (this.parents.length > 3) {
        return this.parents
          .slice(0, 2)
          .concat([
            {
              id: -1,
              label: '...',
              children: this.parents.slice(2, -1),
            },
          ])
          .concat(this.parents.slice(-1));
      }
      return this.parents;
    },
    ...mapState('kbStore', ['focusPath']),
    ...mapGetters({
      path: 'kbStore/buildPath',
      hasAgentRestrictions: 'hasAgentRestrictions',
    }),
  },
  methods: {
    appearParent(parent) {
      if (parent.id !== -1) {
        const parentIdx = this.parents.findIndex((p) => p.id === parent.id);
        const newPath = this.parents.slice(0, parentIdx + 1);
        this.$emit('update-parents', newPath);
      }
    },
    appearParentChild(parent, child) {
      if (child.redirection) {
        this.$emit('focus-node', child.id);
        return;
      }
      if (parent.id != -1) {
        const parentIdx = this.parents.findIndex((p) => p.id === parent.id);
        const newPath = this.parents.slice(0, parentIdx + 1).concat([child]);
        this.$emit('update-parents', newPath);
      } else {
        this.appearParent(child);
      }
    },
    ...mapActions('kbStore', ['updateFocusPath']),
  },
};
</script>
<style lang="scss" scoped>
.key-step-icon {
  margin-right: 10px;
}

.breadcrumb-step {
  height: 25px;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 12px;
  font-weight: 200;
  border-radius: 3px;
  color: $blue-mayday;
  border: 1px solid $blue-mayday;
  background-color: rgba($blue-mayday, 0.05);
}

.breadcrumb-last-step {
  height: 25px;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 12px;
  font-weight: 200;
  border-radius: 3px;
}

.breadcrumb-separator {
  max-width: 4px;
  color: $blue-mayday;
}

.magic-icon {
  max-width: 25px;
}

.children-previous-steps {
  min-width: 150px;
  margin-bottom: 5px;
  padding-left: 5px;
}

.children-previous-steps:hover {
  background-color: rgba($blue-mayday, 0.05);
  cursor: pointer;
}
</style>
