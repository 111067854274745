<template>
  <div :class="`${baseClass}`">
    <div class="row d-flex mt-5 justify-content-center">
      <div class="col-11 text-center">
        <div class="content-danger-text-container">
          <img
            class="deletion-warning-icon"
            src="~assets/empty-states/deletion-warning.svg"
          />
          <p class="mb-0 pt-1">
            {{ $t(`${pre}.delete.part-1`) }}
          </p>
          <p class="mt-4">
            <small>
              <span class="font-weight-bold"
                >{{ $t(`${pre}.headers.name`) }} :</span
              >
              {{ variable.name }}<br />
              <span class="font-weight-bold"
                >{{ $t(`${pre}.headers.path`) }} :</span
              >
              {{ variable.path }}
            </small>
          </p>
          <p class="font-weight-bold mt-4">
            {{ $t(`${pre}.delete.part-2`) }}
          </p>
        </div>
        <div class="d-flex row mt-4 justify-center">
          <base-button
            variant="secondary"
            size="md"
            class="mr-1"
            @click="$emit('cancel')"
            :text="$t('generic.cancel')"
          ></base-button>
          <base-button
            variant="danger"
            size="md"
            @click="handleDelete()"
            :loading="loading"
            :text="$t('generic.delete')"
          ></base-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const baseClass = 'context-variables-drawer__deletion';
const i18nPre = 'settings.card-settings-options.context-variables.drawer';

export default {
  name: 'ip-restriction-settings-drawer-deletion',
  props: {
    variable: {
      type: Object,
    },
  },
  data() {
    return {
      baseClass,
      pre: i18nPre,
      loading: false,
    };
  },
  methods: {
    handleDelete() {
      this.loading = true;
      this.$emit('delete');
    },
  },
};
</script>

<style lang="scss" scoped>
.context-variables-drawer__deletion {
  width: 100%;
}

.deletion-warning-icon {
  max-height: 60px;
  margin-top: -30px;
}
.content-danger-text-container {
  padding-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 4px;
  background-color: rgba($red-mayday, 0.05);
}
</style>
