<template>
  <div>
    <div class="header">
      <div class="header-title">
        {{ $t('settings-layout.menu-data.ai-settings.component-title') }}
      </div>
      <base-button
        v-if="hasAskActivated"
        variant="primary"
        size="sm"
        :text="$t('generic.configure')"
        href="/settings-v2/ai"
      ></base-button>
    </div>
    <div class="description">
      {{ $t('settings-layout.menu-data.ai-settings.description') }}
    </div>
    <div class="footer-wrapper">
      <div class="badge-wrapper">
        <font-awesome-icon
          class="badge-icon"
          :class="[
            hasAskActivated ? 'badge-icon__enable' : 'badge-icon__disable',
          ]"
          :icon="['fas', 'check-circle']"
        />
        <div class="badge-text">
          {{
            $t(
              `settings-layout.menu-data.ai-settings.${
                hasAskActivated ? 'enable' : 'disable'
              }`,
            )
          }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  name: 'configure-ai',
  computed: {
    hasAskActivated() {
      return this.hasCompanyPreferenceWithValue('BRAIN_ENABLE_ASK');
    },
    ...mapGetters(['hasCompanyPreferenceWithValue']),
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.description {
  font-size: 12px;
  color: $grey-7-mayday;
  margin-bottom: 8px;
}
.footer-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.badge-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
}
.badge-icon {
  font-size: 14px;
  height: 16px;
  width: 16px;
  &__enable {
    color: $green-5-mayday;
  }
  &__disable {
    color: $grey-5-mayday;
  }
}
.badge-text {
  font-size: 12px;
  display: flex;
  align-items: center;
  line-height: 16px;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.header-title {
  font-size: 16px;
  font-weight: bold;
  line-height: 19px;
}
</style>
