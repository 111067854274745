<template>
  <BaseModal
    button-size="md"
    custom-width="400px"
    custom-height="400px"
    :sidebar="false"
    :title="
      $t(
        'public-knowledge.appearances.items.code-custom.custom-css.modal.title',
      )
    "
    :description="
      $t(
        'public-knowledge.appearances.items.code-custom.custom-css.modal.description',
      )
    "
    :open="localDisplay"
    :confirm-text="
      $t('public-knowledge.appearances.items.code-custom.custom-css.confirm')
    "
    :disabled="fileUrl || !file"
    @confirm="uploadFile"
    @close="$emit('close')"
  >
    <ContentViewSettingsCallout
      v-if="callout"
      :trad="callout"
      icon="lightbulb"
      type="info"
    />
    <div v-if="templateUrl">
      <base-link
        class="mb-1"
        :text="
          $t(
            'public-knowledge.appearances.items.code-custom.custom-css.template',
          )
        "
        :href="templateUrl"
        target="_blank"
        variant="primary"
      ></base-link>
    </div>
    <el-form enctype="multipart/form-data" v-if="upload" v-loading="loading">
      <el-upload
        class="my-2 upload-demo"
        drag
        action=""
        :auto-upload="false"
        :show-file-list="false"
        :accept="accept"
        :on-change="setFiles"
      >
        <img src="~assets/empty-contents.svg" width="100px" />
        <div class="el-upload__text">
          {{ $t('knowledge.modals.contribution.drop-file') }}
          <em>{{ $t('knowledge.modals.contribution.upload') }}</em>
        </div>
      </el-upload>
    </el-form>
    <div
      v-else
      class="d-flex align-items-center justify-content-center mb-3 files-upload-modal-body"
    >
      <!-- SELECTED FILE -->
      <div v-if="!fileUrl" :class="{ error: error }">
        {{ file ? file.name : null }}
      </div>
      <div v-else>
        <base-link
          class="mb-1"
          :text="
            $t(
              'public-knowledge.appearances.items.code-custom.custom-css.download',
            )
          "
          :href="fileUrl"
          target="_blank"
          variant="primary"
        ></base-link>
      </div>

      <div class="ml-2 icon-wrapper" @click="deleteUrl">
        <font-awesome-icon :icon="['fas', 'trash']" />
      </div>
    </div>
  </BaseModal>
</template>

<script>
import ContentViewSettingsCallout from '@/components/Drawers/ContentViewSettingDrawer/ContentViewSettingsComponents/ContentViewSettingsCallout';
import { mapActions } from 'vuex';

const inputFileReader = async (file) => {
  return new Promise((res) => {
    let reader = new FileReader();
    reader.onload = function () {
      res(reader.result);
    };
    reader.readAsText(file);
  });
};

export default {
  name: 'upload-files-modal',
  components: {
    ContentViewSettingsCallout,
  },
  data() {
    return {
      file: null,
      localDisplay: this.display,
      upload: !this.url,
      fileUrl: this.url,
      loading: false,
      error: false,
    };
  },
  props: {
    display: {
      type: Boolean,
      default: false,
    },
    accept: {
      type: String,
      default: '*',
    },
    url: {
      type: String,
      default: '',
    },
    templateUrl: {
      type: String,
      default: '',
    },
    callout: {
      type: String,
      default: '',
    },
    fileName: {
      type: String,
      default: 'mayday-file',
    },
  },
  computed: {},
  methods: {
    handleConfirm() {
      if (this.currentStep === 1 || this.currentStep === 2)
        return (this.currentStep = this.currentStep + 1);
      else return this.userChoice(true);
    },

    async setFiles(fileData) {
      this.file = fileData.raw;
      this.upload = false;
    },
    async uploadFile() {
      if (!this.file) {
        this.upload = false;
        return;
      }
      this.loading = true;
      let readInputFile = await inputFileReader(this.file);

      const res = await this.uploadPublicFile({
        fileName: this.fileName,
        fileData: readInputFile,
      });

      if (res) {
        this.fileUrl = res;
        this.error = false;
        this.$emit('uploaded-file', res);
        return this.$emit('close');
      } else {
        this.error = true;
        this.$message({
          message: 'Ce fichier contient des erreurs',
          type: 'error',
        });
      }

      this.loading = false;
    },
    deleteUrl() {
      this.file = null;
      this.fileUrl = '';
      this.upload = true;
      this.error = false;
      this.$emit('uploaded-file', '');
    },
    ...mapActions('publicKnowledgeModule', ['uploadPublicFile']),
  },
  watch: {
    localDisplay() {
      if (!this.localDisplay) this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.upload-demo {
  :deep() .el-upload {
    width: 100%;
  }

  :deep() .el-upload-dragger {
    width: 100%;
    height: 150px;
    padding: 16px;
  }
}

.icon-wrapper {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $grey-7-mayday;
  font-size: 14px;
  &:hover {
    background-color: $grey-1-mayday;
    color: $red-mayday;
  }
}

.download-link {
  font-size: 14px;
  color: $blue-mayday;
  &:hover {
    text-decoration: underline;
  }
}

:deep() .el-divider--horizontal {
  margin: 8px 0;
}
.files-upload-modal-body {
  height: 40%;
}
</style>
