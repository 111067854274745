<template>
  <div class="navbar-submenu">
    <!-- SUBMENU TITLE -->
    <div class="navbar-submenu__title text-uppercase strong">
      {{ title }}
    </div>
    <!-- SUBMENU OPTIONS -->
    <div class="navbar-submenu__options-wrapper">
      <a
        :class="[
          'navbar-submenu__option',
          { 'is-focused': focusedOption === option.value },
          'align-items-center flex-row d-flex',
        ]"
        v-for="option in this.menuOptions"
        :key="option.id"
        :href="optionUrl(option)"
      >
        <IconWrapper
          class="title-icon-background pr-2"
          :icon="option.icon"
          :size="14"
        />
        {{ option.label }}
      </a>
    </div>
  </div>
</template>

<script>
import IconWrapper from '@/components/IconWrapper';

export default {
  name: 'NavbarSubMenu',
  components: {
    IconWrapper,
  },
  props: {
    title: {
      type: String,
    },
    menuOptions: {
      type: Array,
      default: () => [],
    },
    focusedOption: {
      type: String,
    },
    sortLabels: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    optionUrl(option) {
      return `/home/${option.id}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar-submenu {
  padding-bottom: 4px;
  &__title {
    padding: 14px;
    background-color: $grey-1-mayday;
    margin-bottom: 2px;
    font-weight: 700;
    color: black;
  }
  &__options-wrapper {
    max-height: 600px;
    overflow: scroll;
    overflow-x: hidden;
  }
  &__option {
    padding: 10px;
    cursor: pointer;
    margin: 2px 4px;
    border-radius: 2px;
    // font-weight: 600;
    // font-size: 16px;
    color: black;
    &:hover {
      color: $blue-mayday;
      background-color: $grey-1-mayday;
    }
  }
  .is-focused {
    color: $blue-mayday;
    font-weight: 600;
    background-color: $grey-1-mayday;
  }
}
</style>
