<template>
  <div>
    <ContentViewSettingsCascader
      :caseParameters="contentSettings.caseParameters"
      :knowledge="knowledge"
      @update-case="$emit('update-case', $event)"
    />

    <div v-if="isParametric">
      <ContentViewSettingsParameters
        :init-filters="contentSettings.parameters"
        :user-permissions="userPermissions"
        @update-product-filters="$emit('update-product-filters', $event)"
        @update-knowledge="$emit('update-knowledge', $event)"
        @open-modal="$emit('open-modal')"
      />
    </div>

    <ContentViewSettingsLabels
      v-else-if="!isStagedContent"
      :selected-labels="contentSettings.labels"
      :user-permissions="userPermissions"
      @update-labels="$emit('update-labels', $event)"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ContentViewSettingsLabels from '@/components/Drawers/ContentViewSettingDrawer/ContentViewSettingsComponents/ContentViewSettingsLabels';
import ContentViewSettingsParameters from '@/components/Drawers/ContentViewSettingDrawer/ContentViewSettingsComponents/ContentViewSettingsParameters';
import ContentViewSettingsCascader from '@/components/Drawers/ContentViewSettingDrawer/ContentViewSettingsComponents/ContentViewSettingsCascader';
export default {
  name: 'content-view-settings-position',
  components: {
    ContentViewSettingsLabels,
    ContentViewSettingsCascader,
    ContentViewSettingsParameters,
  },
  props: {
    contentSettings: {
      type: Object,
      required: true,
    },
    knowledge: {
      type: String,
      required: true,
    },
    userPermissions: {
      type: Object,
      default: () => ({}),
    },
    isStagedContent: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['isParametric']),
  },
};
</script>

<style></style>
